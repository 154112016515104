import { useState } from 'react';
import Datepicker from 'tailwind-datepicker-react';
import { ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import Select from 'react-select';
import SearchHeader from 'components/SearchHeader';
import ContactBox from 'components/ContactBox';
import { useSendEmail } from 'api/hooks/useSendEmail';
import Loading from 'components/Loading';
import { ToastStatus } from 'types/enum';
import { displayToast } from 'utils/utils';
import { NoPartnersStoryblok } from 'types/components-sb';

const options = {
  autoHide: true,
  todayBtn: false,
  clearBtn: false,
  minDate: new Date(),
  theme: {
    background: 'bg-white dark:bg-white',
    todayBtn: '',
    clearBtn: '',
    icons: '',
    text: 'text-darkBlue',
    disabledText: 'text-textLightGray',
    input: 'text-darkBlue w-full bg-white border-darkBlue py-4 pl-16 pr-5 focus:border-darkBlue focus-visible:outline-none text-p16 md:text-p20',
    inputIcon: 'text-darkBlue ml-4',
    selected: 'bg-primary',
  },
  icons: {
    prev: () => <ChevronLeftIcon className="w-5 h-5 text-darkBlue" />,
    next: () => <ChevronRightIcon className="w-5 h-5 text-darkBlue" />,
  },
  datepickerClassNames: 'relative top-2',
  defaultDate: new Date(),
  language: 'en',
};

interface NoPartnerProps {
  blok: NoPartnersStoryblok;
}

const NoPartners = ({ blok }: NoPartnerProps) => {
  const { sendEmail, mutationSendEmail } = useSendEmail({
    to: blok.emailTo ?? '',
    template: 'generic',
    subject: blok.emailSubject ?? '',
    content: [],
  });
  const postcode = localStorage.getItem('postcode') ?? '';
  const category = localStorage.getItem('category') ?? '';
  const [show, setShow] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<{ value: string; label: string } | null>(null);
  const [email, setEmail] = useState<string>('');

  const handleChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };
  const handleClose = (state: boolean) => {
    setShow(state);
  };
  const times = () => {
    const optionsList: { value: string; label: string }[] = [];
    [...Array(23)].forEach((_, index: number) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      [...Array(2)].forEach((_, minutesIndex: number) => {
        if (minutesIndex === 0) {
          optionsList.push({ value: `${index <= 9 ? `0${index}` : index}:00`, label: `${index <= 9 ? `0${index}` : index}:00` });
        } else {
          optionsList.push({ value: `${index <= 9 ? `0${index}` : index}:30`, label: `${index <= 9 ? `0${index}` : index}:30` });
        }
      });
    });

    return optionsList;
  };

  const onSendEmail = async () => {
    if (!selectedDate || !selectedTime || email === '') {
      displayToast(ToastStatus.ERROR, 'Please fill all the required fields.');
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      displayToast(ToastStatus.ERROR, 'Please enter a valid email address.');
      return;
    }
    if (blok.emailTo) {
      await sendEmail({
        variables: {
          input: {
            to: blok.emailTo,
            template: 'generic',
            subject: blok.emailSubject ?? 'Contact us',
            content: [
              {
                question: 'Service',
                answer: category,
              },
              {
                question: 'Postcode',
                answer: postcode,
              },
              {
                question: 'Email',
                answer: email,
              },
              {
                question: "What's your preferred date?",
                answer: selectedDate.toDateString(),
              },
              {
                question: "What's your preferred time?",
                answer: selectedTime.label,
              },
            ],
          },
        },
      });
      displayToast(
        ToastStatus.SUCCESS,
        blok.emailValidationMessage ? blok.emailValidationMessage : 'Thank you we have received your email and will come back to you as soon as possible.',
      );
      window.location.href = '/';
    }
  };

  if (mutationSendEmail.loading) {
    return <Loading />;
  }

  return (
    <div>
      <SearchHeader text="Your partner match" />
      <div className="px-4 md:px-[5%] lg:px-[10%] xl:px-[15%] mt-12">
        <div data-testid="no-partners-title" className="text-p28 md:text-h4 md:text-h3 xl:text-h2 font-bold text-darkBlue">
          {category} near {postcode}
        </div>
        <div className="bg-primary rounded-xl p-8 text-white text-p16 md:text-p18 lg:text-p20 mt-4">
          <span className="font-bold">We haven't got this service in your area at the moment</span>, but let us know the time and date you’d like your
          appointment and we’ll do our best to find you someone.
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start mt-[88px]">
          <div className="text-darkBlue font-bold text-p16 sm:text-p18 md:text-p20 lg:text-p22 xl:text-p24 w-full mb-4 md:mb-0">
            What's your preferred date?*
          </div>

          <div className="w-full text-darkBlue">
            <Datepicker options={options} onChange={handleChange} show={show} setShow={handleClose} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start mt-14">
          <div className="text-darkBlue font-bold text-p16 sm:text-p18 md:text-p20 lg:text-p22 xl:text-p24 w-full mb-4 md:mb-0">
            What's your preferred time?*
          </div>
          <div className="w-full">
            <Select
              options={times()}
              placeholder="Please select..."
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#000B77',
                },
              })}
              onChange={(newValue) => setSelectedTime(newValue)}
              value={selectedTime}
              isSearchable={false}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  padding: '10px 20px',
                  fontSize: window.innerWidth > 768 ? '20px' : '16px',
                  borderRadius: '8px',
                  hover: {
                    borderColor: '#FFF',
                  },
                  borderColor: '#000B77',
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: '#000B77',
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: '#000B77',
                }),
                dropdownIndicator: (baseStyles) => ({
                  ...baseStyles,
                  color: '#000B77',
                  marginLeft: window.innerWidth > 1536 ? '50px' : '10px',
                }),
                indicatorSeparator: (baseStyles) => ({
                  ...baseStyles,
                  display: 'none',
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: '#E6F4FE',
                  padding: '24px 24px 14px 24px',
                }),
                option: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: '#FFF',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#E6F4FE',
                  },
                  color: '#000B77',
                  fontWeight: 'bold',
                }),
              }}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start mt-14">
          <div className="text-darkBlue font-bold text-p16 sm:text-p18 md:text-p20 lg:text-p22 xl:text-p24 w-full mb-4 md:mb-0">What's your email?*</div>

          <div className="w-full">
            <input
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="rounded-xl text-darkBlue border border-darkBlue w-full px-5 py-3.5 text-p16 md:text-p20"
            />
          </div>
        </div>
        <div className="h-px w-full bg-darkBlue my-12" />
        <div className="flex justify-end mt-10">
          <button onClick={onSendEmail} className="bg-secondary px-6 py-3.5 rounded-xl w-full sm:w-fit cursor-pointer">
            <div className="flex items-center justify-center text-darkBlue">
              <div className="text-p20 md:text-p24 font-bold">Request appointment</div>
              <ArrowRightIcon className="h-7 w-7 ml-3 mt-1" />
            </div>
          </button>
        </div>
        <ContactBox />
      </div>
    </div>
  );
};

export default NoPartners;
