import { StoryblokComponent, useStoryblok } from '@storyblok/react';
import Header from 'components/Header';
import CookieConsent from 'components/CookieConsent';
import Footer from 'components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'components/Loading';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

// Adds messages only in a dev environment
loadDevMessages();
loadErrorMessages();
const App = () => {
  const slug = window.location.pathname === '/' ? 'home' : window.location.pathname.replace('/', '');
  const story = useStoryblok(slug, {
    version: 'draft',
    resolve_relations: 'global_reference.reference',
  });
  if (!story || !story.content) {
    return <Loading />;
  }
  return (
    <>
      <Header />
      <CookieConsent />
      <StoryblokComponent blok={story.content} />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default App;
