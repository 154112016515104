import React from 'react';
import { HeaderServiceStoryblok } from 'types/components-sb';

interface ServicesDropDownProps {
  services: HeaderServiceStoryblok[];
}

const ServicesDropDown = ({ services }: ServicesDropDownProps) => {
  return (
    <div className="w-full bg-white p-10 grid grid-cols-1 md:grid-cols-3 gap-7 shadow-serviceHeader mt-5 mb-[-16px]">
      {services.map((service: HeaderServiceStoryblok, index: number) => {
        return (
          <div
            className={
              index % 2 === 0
                ? 'p-7 bg-bgYellow15 rounded-xl text-center flex flex-col items-center justify-between'
                : 'p-7 bg-bgBlue rounded-xl text-center flex flex-col items-center justify-between'
            }
            key={index}
          >
            <div className="flex flex-col items-center">
              <div
                className={
                  index % 2 === 0
                    ? 'w-14 h-14 flex justify-center items-center bg-secondary rounded-full'
                    : 'w-14 h-14 flex justify-center items-center bg-primary rounded-full'
                }
              >
                <img src={service.icon?.filename} alt="service icon" className="w-10 h-10" />
              </div>
              <div className="text-p28 font-bold text-darkBlue mt-4">{service.title}</div>
              <div className="text-p18 text-darkBlue mt-4">{service.description}</div>
            </div>
            <a
              href={
                service.button && service.button[0].link?.linktype === 'url'
                  ? service.button && service.button[0].link?.cached_url
                  : `/${service.button && service.button[0].link?.cached_url}`
              }
              target={service.button && service.button[0].link?.linktype === 'url' ? '_blank' : undefined}
              rel={service.button && service.button[0].link?.linktype === 'url' ? 'noopener noreferrer' : undefined}
              className="bg-darkBlue text-white w-full px-4 py-3.5 rounded-xl mt-20"
            >
              {service.button && service.button[0].text}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default ServicesDropDown;
