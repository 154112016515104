import { gql, useMutation } from '@apollo/client';
import { GET_CUSTOMER } from './useGetCustomer';

export const DELETE_SUPPORTED_PERSON = gql`
  mutation DeleteSupportedPerson($input: DeleteSupportedPersonInput) {
    DeleteSupportedPerson(input: $input) {
      id
      message
      date
    }
  }
`;

const useDeleteSupportedPerson = (id: string) => {
  const jwt = localStorage.getItem('token');

  const [deleteSupportedPerson, mutationDeleteSupportedPerson] = useMutation(DELETE_SUPPORTED_PERSON, {
    context: {
      headers: {
        Authorization: jwt || null,
      },
    },
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        context: {
          headers: {
            Authorization: jwt,
          },
        },
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return { deleteSupportedPerson, mutationDeleteSupportedPerson };
};

export { useDeleteSupportedPerson };
