import { useState } from 'react';
import RadioButton from 'components/RadioButton';

interface PreQualifyingQuestionProps {
  question: string;
  answers: string[];
  descriptions: string[];
  productIds: string[];
  onChange: (id: string) => void;
}

const Product = ({ question, answers, descriptions, productIds, onChange }: PreQualifyingQuestionProps) => {
  const [changeState, setChangeState] = useState(false);

  const onAnswerChange = (id: string) => {
    onChange(id);
    setChangeState(!changeState);
  };

  return (
    <div className="flex flex-col lg:flex-row mt-3">
      <div className="text-darkBlue font-black lg:font-bold text-p16 md:text-p17 lg:text-p20 xl:text-p22 2xl:text-p24 lg:w-[40%]">{question}</div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:ml-4 lg:w-[60%] mt-4 lg:mt-1">
        {answers.map((answer: string, index: number) => {
          const isChecked = (document.getElementById(`radio-products-${index}`) as HTMLInputElement)?.checked;
          return (
            <RadioButton
              id="products"
              text={answer}
              description={descriptions && descriptions[index]}
              key={index}
              index={index}
              isChecked={isChecked}
              onChange={() => onAnswerChange(productIds[index])}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Product;
