import { useState } from 'react';
import { GET_POSTCODE_LOOKUP } from 'api';
import { Category, Customer } from '__generated__/graphql';
import { ToastStatus } from 'types/enum';
import { displayToast } from 'utils/utils';
import ReactSelect from 'components/Select';
import { SelectOption } from 'types/types';
import { useGetCategoriesByPostcode } from 'api/hooks/useGetCategoriesByPostcode';
import { ButtonStoryblok } from 'types/components-sb';

interface HomeSearchThriveProps {
  customer: Customer | undefined;
  outsideServices?: ButtonStoryblok[];
}

const HomeSearchThrive = ({ customer, outsideServices }: HomeSearchThriveProps) => {
  const [postCode, setPostCode] = useState<{ value: string; label: string }>({ value: '', label: '' });
  const { categories, loadingCategories } = useGetCategoriesByPostcode(postCode.value);
  const [service, setService] = useState<{ value: string; label: string; id?: string } | null>(null);
  const [newPostcode, setNewPostcode] = useState(false);
  const customerAddressOptions =
    (customer &&
      customer.address?.map((a) => ({
        value: a?.postcode ?? '',
        label: a?.postcode ?? '',
      }))) ??
    [];
  const supportedPeopleOptions =
    (customer &&
      customer.supportedPeople?.map((supportedPerson) => ({
        value: supportedPerson.address?.postcode ?? '',
        label: `${supportedPerson.address?.postcode} (${supportedPerson.firstName} ${supportedPerson.surname})`,
      }))) ??
    [];
  const options: SelectOption[] = categories?.map((category: Category) => {
    return { value: category.id ?? '', label: category.name ?? '' };
  });

  const checkServiceIsSelected = () => {
    if (service === null) {
      displayToast(ToastStatus.ERROR, 'You must select a service.');
      return false;
    }
    return true;
  };

  const onSearch = async () => {
    if (!checkServiceIsSelected()) {
      return;
    }
    if (service && service.id === 'outside') {
      window.location.href = `/${service.value}`;
    }
    if (postCode.value === '') {
      displayToast(ToastStatus.ERROR, 'You must enter a postcode.');
      return;
    }
    const res = await GET_POSTCODE_LOOKUP(postCode.value);
    if (res.status !== 200) {
      displayToast(ToastStatus.ERROR, res.error);
      return;
    }
    let address;
    if (postCode.label.includes('(')) {
      const supportedPerson = customer?.supportedPeople?.find((sp) => sp.address?.postcode === postCode.value);
      localStorage.setItem('supportedPerson', JSON.stringify(supportedPerson));
      address = supportedPerson?.address;
    } else {
      address = customer?.address?.find((a) => a.postcode === postCode.value) ?? {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postcode: postCode.value,
        region: '',
        addressNotes: '',
      };
      localStorage.removeItem('supportedPerson');
    }
    localStorage.setItem('address', JSON.stringify(address));
    localStorage.setItem('category', service?.label ?? '');
    localStorage.setItem('categoryId', service?.value ?? '');
    localStorage.setItem('postcode', res.result.postcode);
    window.location.href = `/search/${service?.label.toLowerCase().replaceAll(' ', '-')}`;
  };

  return (
    <div className="bg-darkBlue p-6 rounded-2xl mt-5 xl:mt-3 2xl:mt-5">
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="text-p28 text-white font-bold" data-testid="search-title">
          Search for a trusted service
        </div>
        {customer && (
          <div className="md:flex flex-row items-center gap-2 mt-1 hidden">
            <button
              aria-label="search for a new postcode"
              className={`${newPostcode ? 'bg-secondary' : 'bg-white'} border border-white rounded-md cursor-pointer w-5 h-5`}
              onClick={() => setNewPostcode(!newPostcode)}
              data-cy="search-new-postcode-button"
            />
            <div className="text-white text-p18 2xl:text-p20">Search with a new postcode</div>
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row md:items-center justify-between mt-5 gap-2">
        <div data-testid="postcode-select" className="w-full xl:w-[220px]">
          {customer && !newPostcode ? (
            <ReactSelect
              options={[...customerAddressOptions, ...supportedPeopleOptions]}
              onChange={setPostCode}
              isLoading={loadingCategories}
              placeholder="Postcode"
              testId="search-postcode-react-select"
            />
          ) : (
            <input
              data-testid="postcode-input"
              type="text"
              value={postCode.value}
              onChange={(event) => setPostCode({ value: event.target.value, label: event.target.value })}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 w-full"
              placeholder="Postcode"
              data-cy="search-postcode-input"
            />
          )}
        </div>
        <div data-cy="category-select" className="w-full 2xl:w-[350px] xl:w-[295px]">
          <ReactSelect
            options={[
              ...options,
              ...(outsideServices?.map((outsideService) => ({ value: outsideService.link?.cached_url, label: outsideService.text ?? '', id: 'outside' })) ??
                []),
            ]}
            disabled={!postCode.value || loadingCategories}
            onChange={setService}
            isLoading={loadingCategories}
            testId="search-service-react-select"
            placeholder="What service would you like?"
          />
        </div>
        {customer && (
          <div className="flex flex-row items-center gap-3 mt-1 md:hidden mb-3">
            <button
              aria-label="search for a new postcode"
              className={`${newPostcode ? 'bg-secondary' : 'bg-white'} border border-white rounded-md cursor-pointer w-5 h-5 shrink-0`}
              onClick={() => setNewPostcode(!newPostcode)}
            />
            <div className="text-white text-p18 2xl:text-p20">Search with a new postcode</div>
          </div>
        )}

        <button onClick={onSearch} className="bg-secondary px-6 py-3.5 rounded-xl md:w-fit cursor-pointer" data-cy="search-button">
          <div className="flex items-center justify-center text-textBlue text-p20">
            <div>Search</div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default HomeSearchThrive;
