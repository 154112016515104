/* eslint-disable react/no-danger */
import { renderRichText } from '@storyblok/react';
import { AboutBlokStoryblok } from 'types/components-sb';

interface AboutBlokProps {
  blok: AboutBlokStoryblok;
}

const AboutBlok = ({ blok }: AboutBlokProps) => {
  return (
    <div>
      <div className="mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20  mt-5 lg:mt-10 mb-10 relative bg-primary rounded-xl p-6 flex flex-col items-center xl:hidden">
        <img src={blok.image?.filename} alt="about" className="rounded-xl" />
        <div className="text-p28 sm:text-h4 md:text-h3 font-bold text-white text-center mt-6">{blok.title}</div>
        <div className="text-p16 sm:text-p18 md:text-p20 text-white mt-3 text-center" dangerouslySetInnerHTML={{ __html: renderRichText(blok.description) }} />
      </div>
      <div className="mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20  mt-12 mb-10 relative bg-bgBlue rounded-xl h-[481px] items-center z-10 px-[88px] hidden xl:flex">
        <img src="/assets/blueWave.png" alt="blue wave" className="rounded-xl absolute left-0 z-0" />
        <img src={blok.image?.filename} alt="wellbeing" className="rounded-xl z-10" />
        <div className="ml-16">
          <div className="text-h2 font-bold text-textBlue">{blok.title}</div>
          <div className="text-p20 text-textBlue xl:mb-32 2xl:mb-20 mt-4" dangerouslySetInnerHTML={{ __html: renderRichText(blok.description) }} />
        </div>
        <img src="/assets/shaded-logo.png" alt="shaded logo" className="absolute bottom-10 xl:right-5 2xl:right-10 z-0" />
      </div>
    </div>
  );
};

export default AboutBlok;
