import { useQuery, gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query GetCategories {
    GetCategories {
      id
      name
    }
  }
`;
const useGetCategories = () => {
  const {
    loading: loadingCategories,
    data: categories,
    error: errorCategories,
    refetch,
  } = useQuery(GET_CATEGORIES, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  });

  return {
    loadingCategories,
    categories,
    errorCategories,
    refetch,
  };
};

export { useGetCategories };
