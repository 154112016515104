const Phone = () => {
  return (
    <div className="bg-darkBlue rounded-[58px] pr-8 p-2 flex items-center justify-between mt-3 xl:mt-0">
      <img src="/assets/call_receiver.png" alt="call receiver" />
      <a href="tel:+4401235330832" className="text-p18 sm:text-p24 md:text-p28 font-bold text-white ml-3">
        01235 330832
      </a>
    </div>
  );
};

export default Phone;
