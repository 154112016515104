import { useQuery, gql } from '@apollo/client';

export const GET_PARTNER_REVIEWS = gql`
  query GetPartnerReviews($args: PartnerReviewListSearch) {
    GetPartnerReviews(args: $args) {
      id
      customerName
      customerLocation
      rating
      description
      partnerId
    }
  }
`;

const useGetPartnerReviews = (partnerId: string, skip: boolean) => {
  const {
    loading: loadingPartnerReviews,
    data: partnerReviews,
    error: errorPartnerReviews,
    refetch,
  } = useQuery(GET_PARTNER_REVIEWS, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      args: {
        partnerId,
      },
    },
    skip,
  });

  return {
    loadingPartnerReviews,
    partnerReviews,
    errorPartnerReviews,
    refetch,
  };
};

export { useGetPartnerReviews };
