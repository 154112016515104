/* eslint-disable react/no-danger */
import { renderRichText, storyblokEditable } from '@storyblok/react';
import React from 'react';
import { BecomingPartnerStoryblok } from 'types/components-sb';
import ValidationText from './components/ValidationText';

interface BecomingPartnerProps {
  blok: BecomingPartnerStoryblok;
}

const BecomingPartner = ({ blok }: BecomingPartnerProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <div className="flex flex-row items-stretch w-full bg-bgLightBlue ">
        <div className="flex flex-col justify-between items-center lg:items-start w-full lg:w-1/2 px-4 md:px-[5%] py-20">
          <div className="p-4 flex justify-center items-center bg-primary rounded-full">
            <img src="/assets/logo-white.png" alt="thrive logo" className="w-10 h-10" />
          </div>
          <div className="text-h4 md:text-h3 lg:text-h2 xl:text-h1 text-darkBlue mt-2 text-center lg:text-left">{blok.title}</div>
          <a
            href={blok.button[0].link?.cached_url}
            target={blok?.button[0].link?.target}
            rel={blok?.button[0].link?.target ? 'noopener noreferrer' : ''}
            className="bg-secondary text-white text-p20 md:text-p28 lg:text-h4 xl:text-h3 leading-display-md font-bold px-10 py-2 md:py-4 rounded-2xl mt-10 self-center"
          >
            {blok.button[0].text}
          </a>
        </div>
        <img src={blok.image.filename} alt="becoming partner" className="hidden lg:block w-1/2" />
      </div>
      <div className="mt-20 px-4 md:px-[5%]">
        <div
          className="text-darkBlue mt-20 text-p20 lg:text-p24 leading-lg font-medium text-center"
          dangerouslySetInnerHTML={{ __html: renderRichText(blok.description) }}
        />
        <div className="flex flex-row justify-center items-center mt-10">
          <div className="w-1/4 h-px bg-bgSeparator" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-10">{blok.advantages?.map((advantage) => <ValidationText text={advantage.text} />)}</div>
        <div className="bg-bgBlue pb-14 rounded-[20px]">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-14 pt-14 lg:pt-0 px-10 lg:px-0 gap-4 md:gap-10 lg:gap-0">
            {blok.reviews.map((review) => (
              <div className="relative">
                <img src={review.image.filename} alt={review.image.alt} className="w-full hidden lg:block" />
                <div className="bg-white p-4 rounded-[20px] lg:w-3/4 m-auto lg:mt-[-15%] z-10 relative">
                  <div className="text-darkBlue text-p20 md:text-p22 xl:text-p24">{review.text}</div>
                  <div className="text-darkBlue text-p20 md:text-p22 xl:text-p24 mt-4">{review.name}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-14 flex flex-col md:flex-row items-center justify-center gap-4 md:gap-6">
            <div className="p-4 flex justify-center items-center bg-secondary rounded-full">
              <img src="/assets/logo-white.png" alt="thrive logo" className="w-10 h-10" />
            </div>
            <div className="text-darkBlue text-h4 md:text-h3 lg:text-h2 xl:text-h1 leading-display-lg">{blok.howItWorksTitle}</div>
          </div>
          <div className="mt-14 grid grid-cols-1 lg:grid-cols-3 justify-between gap-10 mx-10">
            {blok.howItWorks?.map((h, index) => (
              <div className="flex flex-col items-center justify-center">
                <img src={h.icon?.filename} alt={h.icon?.alt} className="w-20 h-20" />
                <div className="flex flex-row items-center gap-4 mt-4">
                  <div className="w-12 h-12 flex justify-center items-center bg-secondary rounded-full  shrink-0">
                    <div className="text-white text-p28">{index + 1}</div>
                  </div>
                  <div className="text-darkBlue text-p28 font-bold">{h.title}</div>
                </div>
                <div className="text-darkBlue text-p20 md:text-p22 xl:text-p24 md:w-2/3 text-center mt-6">{h.dsecription}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="grid gris-cols-1 lg:grid-cols-2 lg:divide-x divide-bgSeparator justify-between items-center relative mt-14">
          <div className="lg:px-8">
            <div className="text-darkBlue lg:text-center text-p28 md:text-h4 lg:text-h3 xl:text-h2 font-bold">{blok.servicesTitle}</div>
            <div className="flex flex-col gap-4 mt-8 lg:mx-[30%]">{blok.services?.map((s) => <ValidationText text={s.text} />)}</div>
          </div>
          <div className="h-full lg:px-14 mt-14 lg:mt-0">
            <div className="text-darkBlue lg:text-center text-p28 md:text-h4 lg:text-h3 xl:text-h2 font-bold">{blok.safetyTitle}</div>
            <div className="text-regular lg:text-center text-p20 md:text-p22 xl:text-p24 font-medium mt-4">{blok.safetySubtitle}</div>

            <div className="flex flex-col gap-4 mt-8 lg:mx-[10%]">{blok.safety?.map((s) => <ValidationText text={s.text} />)}</div>
          </div>
        </div>
        <div className="text-darkBlue text-p28 md:text-h4 lg:text-h3 xl:text-h2 leading-display-md font-bold text-center mt-14">{blok.connectText}</div>
        <div className="flex justify-center">
          <a
            href={blok.button[0].link?.cached_url}
            target={blok?.button[0].link?.target}
            rel={blok?.button[0].link?.target ? 'noopener noreferrer' : ''}
            className="bg-darkBlue text-white text-p24 md:text-p28 lg:text-h4 xl:text-h3 leading-display-md font-bold px-10 py-2 md:py-4 rounded-2xl mt-14 self-center"
          >
            {blok.button[0].text}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BecomingPartner;
