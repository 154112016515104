import { gql, useMutation } from '@apollo/client';
import { RegisteredCustomerInput } from '__generated__/graphql';

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: RegisteredCustomerInput) {
    CreateCustomer(input: $input) {
      message
      date
    }
  }
`;

const useCreateCustomer = (query: RegisteredCustomerInput) => {
  const [createCustomer, mutationCreateCustomer] = useMutation(CREATE_CUSTOMER, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      query: { ...query },
    },
  });

  return { createCustomer, mutationCreateCustomer };
};

export { useCreateCustomer };
