import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from 'types/enum';
import type { RootState } from './store';

// Define a type for the slice state
export interface SearchState {
  category: Category | null;
  postcode: string;
}

// Define the initial state using that type
const initialState: SearchState = {
  category: null,
  postcode: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchCategory: (state, action: PayloadAction<Category>) => {
      state.category = action.payload;
    },
    setSearchPostCode: (state, action: PayloadAction<string>) => {
      state.postcode = action.payload;
    },
  },
});

export const { setSearchCategory, setSearchPostCode } = searchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getSearchCategory = (state: RootState) => state.search.category;
export const getSearchPostcode = (state: RootState) => state.search.postcode;

export default searchSlice.reducer;
