import { XMarkIcon } from '@heroicons/react/24/outline';
import { ClientAddress } from '__generated__/graphql';
import { GET_POSTCODE_LOOKUP } from 'api';
import AddressForm from 'components/AddressForm';
import { useState } from 'react';
import { ToastStatus } from 'types/enum';
import { displayToast } from 'utils/utils';

interface UpdateAddressProps {
  address?: ClientAddress;
  index: number;
  onClose: () => void;
  addNewAddress: (address: ClientAddress) => void;
  editAddress: (address: ClientAddress, index: number) => void;
}

const UpdateAddress = ({ address, index, onClose, addNewAddress, editAddress }: UpdateAddressProps) => {
  const [addressNotes, setAddressNotes] = useState<string>(address?.addressNotes ?? '');
  const [addressLine1, setAddressLine1] = useState<string>(address?.addressLine1 ?? '');
  const [addressLine2, setAddressLine2] = useState<string>(address?.addressLine2 ?? '');
  const [city, setCity] = useState<string>(address?.city ?? '');
  const [region, setRegion] = useState<string>(address?.region ?? '');
  const [postcode, setPostcode] = useState<string>(address?.postcode ?? '');

  const onSave = async () => {
    if (addressLine1 === '' || city === '' || region === '' || postcode === '') {
      displayToast(ToastStatus.ERROR, 'Please fill all the required fields in address.');
      return;
    }
    const res = await GET_POSTCODE_LOOKUP(postcode);
    if (res.status !== 200) {
      displayToast(ToastStatus.ERROR, res.error);
      return;
    }
    if (address) {
      editAddress(
        {
          addressLine1,
          addressLine2,
          city,
          postcode,
          region,
          addressNotes,
        },
        index,
      );
    } else {
      addNewAddress({
        addressLine1,
        addressLine2,
        city,
        postcode,
        region,
        addressNotes,
      });
    }
    onClose();
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full md:w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-screen md:max-h-[90vh] md:min-w-[580px]">
            <div className="flex flex-row justify-between p-6">
              <div className="text-darkBlue font-bold text-p24 md:text-p28">{address ? `Edit Address ${index + 1}` : 'Add a new address'} </div>
              <button className="text-darkBlue text-p24 font-bold" onClick={onClose}>
                <XMarkIcon className="w-10 h-10" />
              </button>
            </div>
            <div className="overflow-y-auto px-6">
              <AddressForm
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                postcode={postcode}
                city={city}
                region={region}
                addressNotes={addressNotes}
                setAddressLine1={setAddressLine1}
                setAddressLine2={setAddressLine2}
                setPostcode={setPostcode}
                setCity={setCity}
                setRegion={setRegion}
                setAddressNotes={setAddressNotes}
              />
            </div>

            <div className="flex flex-row justify-between items-center p-6">
              <button
                className="text-gray-500 cursor-pointer text-p20 font-bold"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-darkBlue px-4 py-2.5 rounded-xl cursor-pointer text-white text-p20 font-bold"
                onClick={onSave}
                data-cy="save-address-button"
              >
                {address ? 'Save' : 'Add'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
};

export default UpdateAddress;
