import { Booking } from '__generated__/graphql';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { BookingsStoryblok } from 'types/components-sb';
import BookingPreview from './BookingPreview';

interface BookingsListProps {
  pastBookings: Booking[];
  upcomingBookings: Booking[];
  blok: BookingsStoryblok;
}

const BookingsList = ({ pastBookings, upcomingBookings, blok }: BookingsListProps) => {
  return (
    <div>
      <div className="bg-bgYellow p-4 md:px-[5%] lg:px-[10%] xl:px-[15%] sm:py-6 flex items-center justify-between">
        <div className="flex items-center">
          <div className="bg-primary rounded-full w-12 h-12 sm:w-14 sm:h-14 flex items-center justify-center">
            <CalendarDaysIcon className="text-white h-6 w-6 sm:h-7 sm:w-7" />
          </div>
          <div className="text-darkBlue text-p20 sm:text-p24 lg:text-p28 ml-4" data-testid="category-search-title">
            {blok.title}
          </div>
        </div>
      </div>
      <div className="p-4 md:px-[5%] lg:px-[10%] xl:px-[15%]">
        <div className="p-7 bg-primary text-white my-10 rounded-xl text-center md:text-left">
          <div className="text-p20 md:text-p28 font-bold">{blok.welcomeTitle}</div>
          <div className="text-p16 md:text-p18 mt-4">{blok.welcomeDescription}</div>
        </div>
        {upcomingBookings.length > 0 || pastBookings.length > 0 ? (
          <>
            {upcomingBookings.length > 0 && (
              <>
                <div className="text-p28 md:text-h4 lg:text-h3 xl:text-h2 text-darkBlue font-bold">{blok.UpcomingText}</div>
                <div className="flex flex-col gap-4 mt-10">
                  {upcomingBookings &&
                    upcomingBookings.map((booking: Booking, index: number) => {
                      return <BookingPreview booking={booking} testId={`booking-upcoming-${index}`} />;
                    })}
                </div>
              </>
            )}
            {pastBookings.length > 0 && (
              <>
                <div className="text-p28 md:text-h4 lg:text-h3 xl:text-h2 text-darkBlue font-bold mt-10">{blok.PastText}</div>
                <div className="flex flex-col gap-4 mt-10">
                  {pastBookings &&
                    pastBookings.map((booking: Booking, index: number) => {
                      return <BookingPreview booking={booking} isPastBooking testId={`booking-past-${index}`} />;
                    })}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <div className="w-full text-darkBlue font-bold text-p28 text-center">You don't have any bookings</div>
            <a
              href="/"
              className="flex justify-center items-center mt-4 bg-secondary px-6 py-2.5 rounded-xl w-full sm:w-fit cursor-pointer text-darkBlue text-p20 font-bold"
            >
              Book now
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingsList;
