import RadioButton from 'components/RadioButton';
import { useState } from 'react';
import { ContactQuestionStoryblok } from 'types/components-sb';

interface ContactQuestionProps {
  blok: ContactQuestionStoryblok;
  onChange: (question: string, answer: string, isValid: boolean, error: string) => void;
}

const ContactQuestion = ({ blok, onChange }: ContactQuestionProps) => {
  const [value, setValue] = useState('');

  const onValueChange = (newValue: string) => {
    setValue(newValue);
    if (blok.question) {
      if (blok.isRequired && newValue === '') {
        onChange(blok.question, newValue, false, 'Please fill all the mandatory fields.');
      } else if (blok.isEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newValue)) {
        onChange(blok.question, newValue, false, 'Please enter a valid email address.');
      } else {
        onChange(blok.question, newValue, true, '');
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-start">
      <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full md:w-1/2 mb-4 md:mb-0">{blok.question}</div>
      {blok.isOptions ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full md:w-1/2">
          {blok.options?.map((option: string, index: number) => {
            const isChecked = (document.getElementById(`radio-${blok._uid}-${index}`) as HTMLInputElement)?.checked;
            return <RadioButton id={blok._uid} text={option} key={index} index={index} isChecked={isChecked} onChange={(v) => onValueChange(v)} />;
          })}
        </div>
      ) : blok.isTextArea ? (
        <textarea
          value={value}
          onChange={(event) => onValueChange(event.target.value)}
          className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full md:w-1/2 focus-visible:outline-primary"
        />
      ) : (
        <input
          type="text"
          value={value}
          onChange={(event) => onValueChange(event.target.value)}
          className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full md:w-1/2 focus-visible:outline-primary"
        />
      )}
    </div>
  );
};

export default ContactQuestion;
