import { useQuery, gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
  query GetCustomer($args: CustomerSearch) {
    GetCustomer(args: $args) {
      id
      firstName
      surname
      email
      phoneNumber
      address {
        addressLine1
        addressLine2
        city
        region
        postcode
        addressNotes
      }
      otherInformations
      supportedPeople {
        id
        firstName
        surname
        relationship
        address {
          addressLine1
          addressLine2
          city
          region
          postcode
          addressNotes
        }
      }
    }
  }
`;

const useGetCustomer = (id: string, skip: boolean, token?: string) => {
  const jwt = token ?? localStorage.getItem('token');
  const {
    loading: loadingCustomer,
    data,
    error: errorCustomer,
    refetch,
  } = useQuery(GET_CUSTOMER, {
    context: {
      headers: {
        Authorization: jwt,
      },
    },
    variables: {
      args: {
        id,
      },
    },
    skip,
  });

  return {
    loadingCustomer,
    customer: data?.GetCustomer,
    errorCustomer,
    refetch,
  };
};

export { useGetCustomer };
