import React from 'react';
import { LocalImageBlokStoryblok } from 'types/components-sb';

interface LocalImageBlokProps {
  blok: LocalImageBlokStoryblok;
}

const LocalImageBlok = ({ blok }: LocalImageBlokProps) => {
  return (
    <div className="bg-bgBlue p-7 rounded-xl flex flex-col justify-between">
      <div className="flex justify-center items-center">
        <img src={blok.image?.filename} alt={blok.image?.alt ?? ''} />
      </div>
      <div>
        <div className="text-darkBlue font-bold text-p20 mt-5 text-center md:text-left">{blok.description}</div>
        <div className="text-darkBlue font-bold text-p20 mt-5 text-center md:text-left">
          Contact: <a href={`tel:${blok.contact}`}>{blok.contact}</a>
        </div>
      </div>
    </div>
  );
};

export default LocalImageBlok;
