import { PartnerReview } from '__generated__/graphql';
import { useGetPartnerReviews } from 'api/hooks/useGetPartnerReviews';
import Loading from 'components/Loading';
import Review from 'components/Reviews/Review';
import React, { useEffect, useState } from 'react';

interface DisplayReviewsProps {
  title: string;
}

const DisplayReviews = ({ title }: DisplayReviewsProps) => {
  const { partnerReviews, loadingPartnerReviews } = useGetPartnerReviews('', false);
  const [reviews, setReviews] = useState<PartnerReview[]>([]);

  useEffect(() => {
    if (partnerReviews && partnerReviews.GetPartnerReviews) {
      setReviews(partnerReviews.GetPartnerReviews.slice(0, 2));
    }
  }, [partnerReviews]);

  return (
    <div className="flex flex-row mt-10 lg:mt-20 w-full">
      <div className="hidden md:block w-px bg-darkBlue" />
      {loadingPartnerReviews ? (
        <div className="md:ml-14">
          <Loading isComponent />
        </div>
      ) : (
        <div className="md:ml-14 w-full">
          <div className="text-h4 md:text-h3 lg:text-h2 text-darkBlue font-bold">{title}</div>
          <div className="grid grid-cols-1 gap-5 mt-7">
            {reviews.map((review: PartnerReview, index: number) => {
              return <Review review={review} isReviewBgBlue={index % 2 === 0} key={index} />;
            })}
          </div>
          {reviews.length === 2 && (
            <button
              className="bg-darkBlue text-white text-p20 font-bold px-4 py-3.5 rounded-xl mt-10"
              onClick={() => {
                setReviews(partnerReviews.GetPartnerReviews.slice(0, 10));
              }}
            >
              See more reviews
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DisplayReviews;
