import { CheckCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import RadioButton from 'components/RadioButton';
import { useState } from 'react';
import { BookStoryblok } from 'types/components-sb';
import { hasPasswordCapitalLetter, hasPasswordNumber, hasPasswordAtLeast8Chars } from 'utils/utils';
import AddressForm from './AddressForm';
import ReactSelect from './Select';

interface SignupFormProps {
  blok: BookStoryblok;
  isServiceForSomeoneElse?: boolean;
  supportedPersonFirstName?: string;
  supportedPersonLastName?: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerPhone: string;
  addressNotes: string;
  otherInformations: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  region: string;
  postcode: string;
  password: string;
  passwordConfirmation: string;
  setIsServiceForSomeoneElse?: (value: boolean) => void;
  setSupportedPersonFirstName?: (value: string) => void;
  setSupportedPersonLastName?: (value: string) => void;
  setSupportedPersonRelationship?: (value: string) => void;
  setCustomerFirstName: (value: string) => void;
  setCustomerLastName: (value: string) => void;
  setCustomerEmail: (value: string) => void;
  setCustomerPhone: (value: string) => void;
  setAddressNotes: (value: string) => void;
  setOtherInformations: (value: string) => void;
  setAddressLine1: (value: string) => void;
  setAddressLine2: (value: string) => void;
  setCity: (value: string) => void;
  setRegion: (value: string) => void;
  setPostcode: (value: string) => void;
  setPassword: (value: string) => void;
  setPasswordConfirmation: (value: string) => void;
  isSignedInNewAddress?: boolean;
  isPostcodeDisabled?: boolean;
}

const SignupForm = ({
  blok,
  isServiceForSomeoneElse,
  supportedPersonFirstName,
  supportedPersonLastName,
  customerFirstName,
  customerLastName,
  customerEmail,
  customerPhone,
  addressNotes,
  otherInformations,
  addressLine1,
  addressLine2,
  city,
  region,
  postcode,
  password,
  passwordConfirmation,
  setSupportedPersonFirstName,
  setSupportedPersonLastName,
  setSupportedPersonRelationship,
  setAddressNotes,
  setCustomerEmail,
  setCustomerFirstName,
  setCustomerLastName,
  setCustomerPhone,
  setOtherInformations,
  setIsServiceForSomeoneElse,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setRegion,
  setPostcode,
  setPassword,
  setPasswordConfirmation,
  isSignedInNewAddress = false,
  isPostcodeDisabled = false,
}: SignupFormProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);
  const supportedPersonRelationshipSelectOptions = blok.supportedPersonRelationshipOptions.map((o) => ({ value: o, label: o }));

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  function togglePasswordConfirmationVisibility() {
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
  }

  return (
    <div>
      {setIsServiceForSomeoneElse && (
        <div className="flex flex-col lg:flex-row mt-14">
          <div className="text-darkBlue font-bold text-p20 lg:text-p24 lg:w-[40%] mb-4 lg:mb-0">Who is this service for? *</div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4 lg:w-[60%] lg:ml-10">
            {['Me', 'Someone else'].map((answer: string, index: number) => {
              const isChecked = (document.getElementById(`radio-person-${index}`) as HTMLInputElement)?.checked;
              return (
                <RadioButton
                  id="person"
                  text={answer}
                  key={index}
                  index={index}
                  isChecked={isChecked}
                  onChange={() => {
                    if (answer === 'Me') {
                      setIsServiceForSomeoneElse(false);
                    } else {
                      setIsServiceForSomeoneElse(true);
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      {isServiceForSomeoneElse && setSupportedPersonFirstName && setSupportedPersonLastName && setSupportedPersonRelationship && (
        <div className="lg:ml-10">
          <div className="flex flex-col lg:flex-row mt-6">
            <div className="text-p20 lg:text-p22 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.supportedPersonFirstName}</div>
            <input
              type="text"
              value={supportedPersonFirstName}
              onChange={(event) => setSupportedPersonFirstName(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="register-someone-else-firtsname-input"
            />
          </div>
          <div className="flex flex-col lg:flex-row mt-6">
            <div className="text-p20 lg:text-p22 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.supportedPersonLastName}</div>
            <input
              type="text"
              value={supportedPersonLastName}
              onChange={(event) => setSupportedPersonLastName(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="register-someone-else-lastname-input"
            />
          </div>
          <div className="flex flex-col lg:flex-row mt-6">
            <div className="text-p20 lg:text-p22 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.supportedPersonRelationship}</div>
            <div className="lg:w-[60%] lg:ml-10">
              <ReactSelect
                options={supportedPersonRelationshipSelectOptions}
                onChange={(value) => setSupportedPersonRelationship(value.value)}
                testId="supported-person-relationship"
                placeholder="Choose an option"
                borderBlue
              />
            </div>
          </div>
        </div>
      )}
      {!isSignedInNewAddress && (
        <>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 lg:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.customerFirstName}</div>
            <input
              type="text"
              value={customerFirstName}
              onChange={(event) => setCustomerFirstName(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="register-firtsname-input"
            />
          </div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 lg:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.customerLastName}</div>
            <input
              type="text"
              value={customerLastName}
              onChange={(event) => setCustomerLastName(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="register-lastname-input"
            />
          </div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 lg:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.customerEmail}</div>
            <input
              type="email"
              value={customerEmail}
              onChange={(event) => setCustomerEmail(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
            />
          </div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 lg:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.customerPhone}</div>
            <input
              type="phone"
              value={customerPhone}
              onChange={(event) => setCustomerPhone(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
            />
          </div>
        </>
      )}
      <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
        <div className="text-p20 lg:text-p24 text-darkBlue font-bold w-full">{blok.address}</div>
      </div>
      <div className="lg:ml-10">
        <AddressForm
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          postcode={postcode}
          city={city}
          region={region}
          addressNotes={addressNotes}
          setAddressLine1={setAddressLine1}
          setAddressLine2={setAddressLine2}
          setPostcode={setPostcode}
          setCity={setCity}
          setRegion={setRegion}
          setAddressNotes={setAddressNotes}
          isPostcodeDisabled={isPostcodeDisabled}
        />
      </div>
      {!isSignedInNewAddress && (
        <>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 lg:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">{blok.otherInformations}</div>
            <input
              type="text"
              autoComplete="off"
              value={otherInformations}
              onChange={(event) => setOtherInformations(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="register-other-informations-input"
            />
          </div>
          <div className="h-px w-full bg-darkBlue my-12" />
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="w-full lg:w-[40%] mb-4 lg:mb-0">
              <div className="text-p20 lg:text-p24 text-darkBlue font-bold">Set your Thrive password</div>
              <div className="text-p16 lg:text-p18 text-darkBlue leading-5 mt-2">This will mean you can see this and future appointments when you log in</div>
            </div>
            <div className="w-full lg:w-[60%] lg:ml-10">
              <div className="relative">
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  name="new-password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary h-fit"
                  data-cy="register-password-input"
                />
                <button className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue" onClick={togglePasswordVisibility}>
                  {isPasswordVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
                </button>
              </div>
              <div className="flex flex-col lg:flex-row items-start text-darkBlue text-p18 font-bold mt-6">
                <div className="font-normal lg:font-bold">Your password must contain the following:</div>
                <div className="mt-3 lg:mt-0">
                  <div className="flex flex-row items-center lg:ml-2">
                    <CheckCircleIcon className={hasPasswordCapitalLetter(password) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                    <div className="ml-1 pt-0.5">At least one capital letter</div>
                  </div>
                  <div className="flex flex-row items-center lg:ml-2">
                    <CheckCircleIcon className={hasPasswordNumber(password) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                    <div className="ml-1 pt-0.5">At least one number</div>
                  </div>
                  <div className="flex flex-row items-center lg:ml-2">
                    <CheckCircleIcon className={hasPasswordAtLeast8Chars(password) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                    <div className="ml-1 pt-0.5">At least 8 characters</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 lg:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 lg:mb-0">Confirm your password</div>
            <div className="relative w-full lg:w-[60%] lg:ml-10">
              <input
                type={isPasswordConfirmationVisible ? 'text' : 'password'}
                name="new-confirmation-password"
                autoComplete="new-confirmation-password"
                value={passwordConfirmation}
                onChange={(event) => setPasswordConfirmation(event.target.value)}
                className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border focus-visible:outline-primary h-fit w-full"
                data-cy="register-password-confirmation-input"
              />
              <button className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue" onClick={togglePasswordConfirmationVisibility}>
                {isPasswordConfirmationVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignupForm;
