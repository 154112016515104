import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import React from 'react';
import { FaqStoryblok, FaqThemeStoryblok } from 'types/components-sb';

interface FaqProps {
  blok: FaqStoryblok;
}

const Faq = ({ blok }: FaqProps) => {
  return (
    <div>
      <div className="bg-secondary w-full px-4 py-7 md:px-[5%] lg:px-[10%] xl:px-[15%] flex flex-col sm:flex-row justify-between items-center">
        <QuestionMarkCircleIcon className="w-20 h-20 text-white" />
        <div className="text-h4 md:text-h3 lg:text-h2 xl:text-h1 text-darkBlue text-center sm:text-left">{blok.title}</div>
        <img src="/assets/logo-white.png" alt="logo white" className="w-24 hidden sm:block" />
      </div>
      <div className="p-4 md:px-[5%] lg:px-[10%] xl:px-[15%] mt-10">
        <div className="shadow-blue px-10 py-7 text-darkBlue text-p24 md:text-p28 lg:text-h4 xl:text-h3 rounded-xl font-bold">{blok.subtitle}</div>
        <div className="grid grid-cols-1 gap-6 mt-10">
          {blok.themes?.map((nestedBlok: FaqThemeStoryblok, index: number) => {
            return (
              <a href={`#${nestedBlok._uid}`} key={index} className="text-darkBlue text-p24 md:text-p28 underline font-bold underline-offset-8">
                {nestedBlok.title}
              </a>
            );
          })}
        </div>
        <div className="grid grid-cols-1 gap-8 lg:gap-12 mt-10">
          {blok.themes?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isBlue={index % 2 === 0} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
