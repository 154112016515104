import { useEffect, useState } from 'react';
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Auth, Hub } from 'aws-amplify';
import { ButtonVariantState } from 'types/enum';
import Login from 'components/SignIn';
import { ButtonStoryblok, HeaderServiceStoryblok, HeaderStoryblok } from 'types/components-sb';
import { getStoryblokApi } from '@storyblok/react';
import Button from '../Button';
import ServicesDropDown from './components/ServicesDropDown';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [showAccountDropDown, setShowAccountDropDown] = useState(false);
  const [showServicesDropDown, setShowServicesDropDown] = useState(false);
  const [showServicesMobile, setShowServicesMobile] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const [blok, setBlok] = useState<HeaderStoryblok>();
  const storyblokApi = getStoryblokApi();
  const [userName, setUserName] = useState<string>();

  useEffect(() => {
    const getHeaderSbData = async () => {
      const { data } = await storyblokApi.get('cdn/stories/header', { version: 'draft' });
      setBlok(data.story.content);
    };
    getHeaderSbData();
  }, [storyblokApi]);

  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.querySelector('#navbar-collapse')?.classList.toggle('hidden');
    document.querySelector('#navbar-collapse')?.classList.toggle('flex');
  };

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        if (user && user.username) {
          const session = await Auth.currentSession();
          const accessToken = session.getIdToken();
          const token = accessToken.getJwtToken();
          localStorage.setItem('token', token);
          setUserName(user.attributes.name);
          setIsSignedUp(true);
        }
      } catch {
        localStorage.removeItem('token');
        setIsSignedUp(false);
      }
    };
    Hub.listen('auth', checkUserAuth);
    checkUserAuth();
    return () => Hub.remove('auth', checkUserAuth);
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
      setShowAccountDropDown(false);
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <nav className="w-full z-20 bg-white py-4 sticky top-0" id="header">
      <div className={imageLoading ? 'hidden' : 'block'}>
        <div>
          <div className="flex justify-between items-center px-8">
            <a href="/" className="mb-2 mr-5">
              <img src={blok?.logo?.filename} alt="logo" onLoad={() => setImageLoading(false)} />
            </a>
            <div className="hidden xl:flex items-center gap-4">
              <Button variant={ButtonVariantState.Text} onClick={() => setShowServicesDropDown(!showServicesDropDown)}>
                <div className="flex items-center text-textBlue text-p20">
                  <div>{blok?.ourServices}</div>
                  {showServicesDropDown ? (
                    <ChevronUpIcon className="text-darkBlue w-5 h-5 ml-2 mt-1" />
                  ) : (
                    <ChevronDownIcon className="text-darkBlue w-5 h-5 ml-2 mt-1" />
                  )}
                </div>
              </Button>
              {blok?.navLinks?.map((link) => (
                <a href={`/${link.link?.cached_url}`} className="text-textBlue text-p20 group transition duration-300 w-max text-center">
                  {link.text}
                  <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
                </a>
              ))}
            </div>
            <div className="hidden xl:flex items-center gap-4">
              {isSignedUp && (
                <div className="text-textBlue text-p20 group w-max text-center" data-cy="logged-in-as">
                  {blok?.welcomeLoggedIn}
                  <span className="font-bold">{userName}</span>
                </div>
              )}

              {isSignedUp ? (
                <div className="inline-block relative h-10">
                  <button onClick={() => setShowAccountDropDown(!showAccountDropDown)} className="flex flex-row items-center" data-cy="account-dropdown">
                    <UserCircleIcon className="text-darkBlue w-10 h-10" />
                    {showAccountDropDown ? (
                      <ChevronUpIcon className="text-darkBlue w-5 h-5 ml-2 mt-1" />
                    ) : (
                      <ChevronDownIcon className="text-darkBlue w-5 h-5 ml-2 mt-1" />
                    )}
                  </button>
                  <div className={showAccountDropDown ? 'block absolute w-max right-0 text-darkBlue bg-white px-8 py-3 rounded-lg shadow-blue' : 'hidden'}>
                    {blok?.accountLinks?.map((nestedBlok: ButtonStoryblok, index: number) => {
                      return (
                        <div className="mt-2" key={index}>
                          <a
                            href={`/${nestedBlok?.link?.cached_url}`}
                            className="text-textBlue text-p20 group transition duration-300 w-max text-center"
                            data-cy={`account-link-${index}`}
                          >
                            {nestedBlok.text}
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
                          </a>
                        </div>
                      );
                    })}
                    <div className="mt-2">
                      <button onClick={signOut} className="text-textBlue text-p20 group transition duration-300 w-max text-center" data-cy="sign-out-button">
                        {blok?.signOut}
                        <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <Button variant={ButtonVariantState.Contained} onClick={() => setShowLogin(!showLogin)}>
                  <div className="flex items-center text-white text-p20">
                    <ArrowRightOnRectangleIcon className="text-white h-5 w-5 mt-1" />
                    <div className="ml-1">{blok?.signIn}</div>
                  </div>
                </Button>
              )}
            </div>
            <button className="hover:opacity-75 xl:hidden" id="navbar-toggle" onClick={openMenu}>
              {isMenuOpen ? <XMarkIcon className="text-textBlue h-10 w-10 mt-1" /> : <Bars3Icon className="text-textBlue h-10 w-10 mt-1" />}
            </button>
          </div>
          {isSignedUp && (
            <div className="xl:hidden text-textBlue text-p20 group w-full text-center" data-cy="logged-in-as-mobile">
              {blok?.welcomeLoggedIn}
              <span className="font-bold">{userName}</span>
            </div>
          )}
        </div>

        <div className="hidden flex-col xl:mt-0 shadow-serviceHeader py-4 px-8" id="navbar-collapse">
          <Button variant={ButtonVariantState.Text} onClick={() => setShowServicesMobile(!showServicesMobile)}>
            <div className="flex items-center text-textBlue text-p20">
              <div>{blok?.ourServices}</div>
              <ChevronDownIcon className=" ml-1 text-textBlue h-5 w-5 mt-1" />
            </div>
          </Button>
          {showServicesMobile && (
            <div className="ml-4">
              {blok?.services?.map((nestedBlok: HeaderServiceStoryblok, index: number) => {
                return (
                  <a
                    href={
                      nestedBlok?.button && nestedBlok?.button[0].link?.linktype === 'url'
                        ? nestedBlok?.button && nestedBlok?.button[0].link?.cached_url
                        : `/${nestedBlok?.button && nestedBlok?.button[0].link?.cached_url}`
                    }
                    className="flex items-center text-textBlue text-p20 mt-2"
                    key={index}
                  >
                    {nestedBlok.title}
                  </a>
                );
              })}
            </div>
          )}
          {blok?.navLinks?.map((link) => (
            <a href={`/${link.link?.cached_url}`} className="flex items-center text-textBlue text-p20 mt-2">
              {link.text}
            </a>
          ))}
          {isSignedUp ? (
            <div>
              {blok?.accountLinks?.map((nestedBlok: ButtonStoryblok, index: number) => {
                return (
                  <a href={`/${nestedBlok?.link?.cached_url}`} className="flex items-center text-textBlue text-p20 mt-2" key={index}>
                    {nestedBlok.text}
                  </a>
                );
              })}
              <button onClick={signOut} className="flex items-center text-darkBlue text-p20 mt-2">
                <ArrowLeftOnRectangleIcon className="text-darkBlue h-5 w-5 mt-1" />
                <div className="ml-1">{blok?.signOut}</div>
              </button>
            </div>
          ) : (
            <button onClick={() => setShowLogin(!showLogin)} className="bg-darkBlue px-3 py-2 rounded-xl w-fit mt-2" data-cy="sign-in-up-button">
              <div className="flex items-center text-white text-p20 w-fit">
                <ArrowRightOnRectangleIcon className="text-white h-5 w-5 mt-1" />
                <div className="ml-1">{blok?.signIn}</div>
              </div>
            </button>
          )}
        </div>
      </div>
      {showLogin && <Login onClose={() => setShowLogin(false)} />}
      {showServicesDropDown && <ServicesDropDown services={blok?.services ?? []} />}
    </nav>
  );
};

export default Header;
