/* eslint-disable react/no-danger */
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { renderRichText } from '@storyblok/react';
import React, { useState } from 'react';
import { FaqQuestionStoryblok } from 'types/components-sb';

interface FaqQuestionProps {
  blok: FaqQuestionStoryblok;
  isBlue: boolean;
}

const FaqQuestion = ({ blok, isBlue }: FaqQuestionProps) => {
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);
  return (
    <div className="bg-white p-7 shadow-faq rounded-xl">
      <div className="flex flex-row items-center justify-between">
        <div className="text-darkBlue text-p20 md:text-p22 mr-4">{blok.question}</div>
        <button
          className={
            isQuestionOpen && isBlue
              ? 'w-12 h-12 shadow-faq rounded-full flex justify-center items-center bg-darkBlue shrink-0'
              : isQuestionOpen && !isBlue
              ? 'w-12 h-12 shadow-faq rounded-full flex justify-center items-center bg-secondary shrink-0'
              : 'w-12 h-12 shadow-faq rounded-full flex justify-center items-center shrink-0'
          }
          onClick={() => setIsQuestionOpen(!isQuestionOpen)}
        >
          {isQuestionOpen ? (
            <ChevronDownIcon className="w-6 h-6 text-white" />
          ) : (
            <ChevronRightIcon className={isBlue ? 'w-6 h-6 text-darkBlue' : 'w-6 h-6 text-secondary'} />
          )}
        </button>
      </div>
      {isQuestionOpen && <div className="text-p16 md:text-p18 text-darkBlue mt-5" dangerouslySetInnerHTML={{ __html: renderRichText(blok.answer) }} />}
    </div>
  );
};

export default FaqQuestion;
