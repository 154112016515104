/* eslint-disable react/no-danger */
import { CheckIcon } from '@heroicons/react/24/outline';
import { renderRichText } from '@storyblok/react';
import React from 'react';
import { RichtextStoryblok } from 'types/components-sb';

interface ValidationTextProps {
  text: RichtextStoryblok;
}

const ValidationText = ({ text }: ValidationTextProps) => {
  return (
    <div className="flex flex-row items-center gap-3">
      <div className="p-2 bg-secondary text-white rounded-full">
        <CheckIcon className="w-5 h-5" />
      </div>
      <div className="text-black text-p20 md:text-p22 xl:text-p24 leading-xl" dangerouslySetInnerHTML={{ __html: renderRichText(text) }} />
    </div>
  );
};

export default ValidationText;
