import { useQuery, gql } from '@apollo/client';

export const GET_AVAILABILITY_SLOTS = gql`
  query GetAvailabilitySlots($args: AvailablitySlotListSearch) {
    GetAvailabilitySlots(args: $args) {
      startDateTime
      endDateTime
      isBooked
    }
  }
`;

const useGetAvailabilitySlots = (partnerId: string, productId: string, date: number) => {
  const {
    loading: loadingAvailabilitySlots,
    data: availabilitySlots,
    error: errorAvailabilitySlot,
    refetch,
  } = useQuery(GET_AVAILABILITY_SLOTS, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      args: {
        partnerId,
        productId,
        date,
      },
    },
  });

  return {
    loadingAvailabilitySlots,
    availabilitySlots,
    errorAvailabilitySlot,
    refetch,
  };
};

export { useGetAvailabilitySlots };
