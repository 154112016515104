import React from 'react';
import { CategoryStoryblok } from 'types/components-sb';

interface CategoryProps {
  blok: CategoryStoryblok;
  isBlue: boolean;
}

const Category = ({ blok, isBlue }: CategoryProps) => {
  return (
    <a href="/" className={blok.isHalfSize ? 'lg:col-span-3' : 'lg:col-span-2'}>
      <div className={isBlue ? 'bg-bgBlue rounded-xl p-6 col-auto' : 'bg-bgYellow15 rounded-xl p-6 col-auto'}>
        <div className="flex justify-center items-center">
          <img src={blok.image.filename} alt={blok.title ?? 'category'} className="rounded-xl" />
        </div>
        <div className="text-p20 md:text-p22 lg:text-p24 text-darkBlue font-bold mt-4 text-center">{blok.title}</div>
      </div>
    </a>
  );
};

export default Category;
