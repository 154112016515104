import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { ColoredBlokStoryblok } from 'types/components-sb';

interface ColoredBlokProps {
  blok: ColoredBlokStoryblok;
}

const ColoredBlok = ({ blok }: ColoredBlokProps) => {
  return (
    <div
      {...storyblokEditable(blok)}
      style={{ backgroundColor: blok.color ? blok.color : '#024959' }}
      className="rounded-lg p-4 text-white h-full flex justify-center flex-col"
    >
      {blok.content?.map((nestedBlok) => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}
    </div>
  );
};

export default ColoredBlok;
