import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import ContactBox from 'components/ContactBox';
import { CommunityServiceStoryblok } from 'types/components-sb';

interface CommunityServiceProps {
  blok: CommunityServiceStoryblok;
}

const CommunityService = ({ blok }: CommunityServiceProps) => {
  return (
    <div>
      <img src={blok.image?.filename} alt="community" className="w-full" />
      <div className="mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 ">
        <div className="text-h4 md:text-h3 lg:text-h2 xl:text-h1 text-darkBlue mt-10 text-center md:text-left">{blok.title}</div>
        <div className="grid grid-cols-1 gap-8 mt-10">
          {blok?.localServices?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isBlue={index % 2 === 0} />;
          })}
        </div>
        <div className="flex justify-center items-center my-10 md:my-20">
          <div className="w-[172px] h-px bg-darkBlue" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-10">
          {blok?.localInformations?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isBlue={index % 2 === 0} />;
          })}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-8">
        {blok?.about?.map((nestedBlok: SbBlokData, index: number) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
        })}
      </div>
      <div className="mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20">
        <div className="bg-primary rounded-xl p-10 justify-center items-center flex flex-col text-white ">
          <div className="text-p28 md:text-h4 lg:text-h3 xl:text-h2 font-bold text-center">{blok.howToJoinTitle}</div>
          <div className="text-p20 md:text-p22 lg:text-p24 font-bold mt-10 text-center">{blok.howToJoinSubtitle}</div>
          <button className="mt-10 bg-secondary text-darkBlue font-bold text-p22 flex justify-center items-center px-4 py-3.5 rounded-xl">
            {blok.howToJoinButton}
          </button>
        </div>
        <ContactBox />
      </div>
    </div>
  );
};

export default CommunityService;
