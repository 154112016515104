import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import ContactBox from 'components/ContactBox';
import PartnerSearch from 'components/PartnerSearch';
import { HowItWorksStoryblok } from 'types/components-sb';

interface HowItWorksProps {
  blok: HowItWorksStoryblok;
}

const HowItWorks = ({ blok }: HowItWorksProps) => {
  return (
    <div>
      <div className="px-4 py-7 md:px-[5%] flex flex-col justify-center items-center">
        {blok.logo?.filename && (
          <div className="rounded-full bg-primary w-16 lg:w-20 h-16 lg:h-20 flex justify-center items-center">
            <img src={blok.logo?.filename} alt="logo white" className="w-10 lg:w-12" />
          </div>
        )}
        <div className="text-darkBlue text-h4 md:text-h3 lg:text-h2 xl:text-h1 leading-display-lg text-center mt-4 lg:mt-7">{blok.title}</div>
        <div className="p-6 rounded-lg shadow-descriptionBox text-darkBlue text-p16 md:text-p17 lg:text-p20 xl:text-p22 2xl:text-p24 leading-7 text-center mt-4 lg:mt-7">
          {blok.description}
        </div>
      </div>
      <div className="grid grid-cols-1 mt-10">
        {blok.howItWorks?.map((nestedBlok: SbBlokData, index: number) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isBlue={index % 2 === 0} index={index} />;
        })}
      </div>
      <div className="grid grid-cols-1 gap-8 lg:gap-12 mt-10 px-4 pt-7 md:px-[5%]">
        {blok.faq?.map((nestedBlok: SbBlokData) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isBlue={false} />;
        })}
      </div>
      <div className="px-4 md:px-[5%]">
        <PartnerSearch />
        <ContactBox />
      </div>
      <div className="grid grid-cols-1 gap-8">
        {blok?.join?.map((nestedBlok: SbBlokData, index: number) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
        })}
      </div>
    </div>
  );
};

export default HowItWorks;
