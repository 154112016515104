import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { storyblokEditable } from '@storyblok/react';
import { useGetProducts } from 'api/hooks/useGetProducts';
import SearchHeader from 'components/SearchHeader';
import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import ContactBox from 'components/ContactBox';
import { CategorySearchStoryblok } from 'types/components-sb';
import { Product as ProductType } from '__generated__/graphql';
import Product from './components/Product';

interface CategorySearchProps {
  blok: CategorySearchStoryblok;
}

const CategorySearch = ({ blok }: CategorySearchProps) => {
  const categoryId = localStorage.getItem('categoryId');
  const postcode = localStorage.getItem('postcode');

  const { products, loadingProducts } = useGetProducts(categoryId ?? '');
  const [selectedProductId, setSelectedProductId] = useState<string>('');

  useEffect(() => {
    if (!categoryId || !postcode) {
      window.location.href = '/';
    }
  }, [categoryId, postcode]);

  useEffect(() => {
    if (products && products.GetProducts && products.GetProducts.length === 0) {
      window.location.href = '/no-partners';
    }
  }, [products]);

  if (loadingProducts) {
    return <Loading />;
  }

  const onSearch = () => {
    let productName = '';
    products?.GetProducts.forEach((product: ProductType) => {
      if (product.id === selectedProductId) {
        productName = product.name;
      }
    });
    localStorage.setItem('productId', selectedProductId);
    localStorage.setItem('productName', productName);
    window.location.href = `/partner?product=${selectedProductId}`;
  };

  return (
    <div {...storyblokEditable(blok)}>
      <SearchHeader text="Your search for" boldText={localStorage.getItem('category') ?? ''} />
      <div className="px-4 md:px-[5%] lg:px-[10%] xl:px-[15%] mt-7" data-cy="category-search-container">
        <div className="text-darkBlue font-bold leading-8 text-p28 md:text-h4 xl:text-h3 2xl:text-h2">Tell us a bit more about the service you require</div>
        <div className="text-darkBlue mt-4 leading-normal text-p16 md:text-p17 lg:text-p20">Please select the service you need.</div>
        <div className="grid grid-cols-1 gap-8 mt-16">
          <Product
            question="How would you describe the service you need"
            answers={products?.GetProducts?.map((product: ProductType) => product.name) ?? []}
            descriptions={products?.GetProducts?.map((product: ProductType) => product.description) ?? []}
            productIds={products?.GetProducts?.map((product: ProductType) => product.id) ?? []}
            onChange={(id: string) => setSelectedProductId(id)}
          />
        </div>
        <div className="h-px bg-darkBlue w-full hidden xl:block mt-14" />
        <div className="flex justify-end mt-10">
          <button onClick={onSearch} className="bg-secondary px-6 py-3.5 rounded-xl w-full sm:w-fit cursor-pointer" data-cy="search-show-results-button">
            <div className="flex items-center justify-center text-darkBlue">
              <div className="text-p20 md:text-p24 font-bold">Show my results</div>
              <ArrowRightIcon className="h-7 w-7 ml-3 mt-1" />
            </div>
          </button>
        </div>
        <ContactBox />
      </div>
    </div>
  );
};

export default CategorySearch;
