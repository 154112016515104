import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { ToastStatus } from 'types/enum';
import { hasPasswordCapitalLetter, hasPasswordNumber, hasPasswordAtLeast8Chars, displayToast } from 'utils/utils';

interface LoginProps {
  onClose: () => void;
  onCloseForgotPassword: () => void;
}

const ForgotPassword = ({ onClose, onCloseForgotPassword }: LoginProps) => {
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const [isForgotPasswordSubmit, setIsForgotPasswordSubmit] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

  const forgotPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setIsForgotPasswordSubmit(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayToast(ToastStatus.ERROR, error.message);
    }
  };

  const forgotPasswordSubmit = async () => {
    if (!/[A-Z]/.test(newPassword) || !/\d/.test(newPassword) || newPassword.length < 8) {
      displayToast(ToastStatus.ERROR, 'The password must have a capital letter, a number and be at least 8 characters.');
      return;
    }
    if (newPassword !== newPasswordConfirmation) {
      displayToast(ToastStatus.ERROR, 'The confirmation password is different than the password.');
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      onCloseForgotPassword();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayToast(ToastStatus.ERROR, error.message);
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <button className="text-darkBlue text-p24 font-bold" onClick={onCloseForgotPassword}>
          <ArrowLeftIcon className="w-8 h-8" />
        </button>
        <button className="text-darkBlue text-p24 font-bold" onClick={onClose}>
          <XMarkIcon className="w-8 h-8" />
        </button>
      </div>
      <div className="text-h4 font-bold text-center text-darkBlue mt-2">Reset your password</div>
      <div className="text-p22 text-center text-darkBlue">Please check your email for a confirmation code</div>
      {isForgotPasswordSubmit ? (
        <>
          <div className="mt-7">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full mb-4">Confirmation code</div>
            <input
              type="text"
              name="confirmationCode"
              autoComplete="disabled"
              value={code}
              onChange={(event) => setCode(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary h-fit"
            />
          </div>
          <div className="mt-4">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full mb-4">New password</div>
            <div className="relative w-full">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                name="new-password"
                autoComplete="new-password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border focus-visible:outline-primary h-fit w-full"
              />
              <button className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
              </button>
            </div>
          </div>
          <div className="flex flex-col items-start text-darkBlue text-p18 font-bold mt-4">
            <div className="font-bold">Your password must contain the following:</div>
            <div className="mt-2">
              <div className="flex flex-row items-center lg:ml-2">
                <CheckCircleIcon className={hasPasswordCapitalLetter(newPassword) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                <div className="ml-1 pt-0.5">At least one capital letter</div>
              </div>
              <div className="flex flex-row items-center lg:ml-2">
                <CheckCircleIcon className={hasPasswordNumber(newPassword) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                <div className="ml-1 pt-0.5">At least one number</div>
              </div>
              <div className="flex flex-row items-center lg:ml-2">
                <CheckCircleIcon className={hasPasswordAtLeast8Chars(newPassword) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                <div className="ml-1 pt-0.5">At least 8 characters</div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full mb-4">Confirm new password</div>
            <div className="relative w-full">
              <input
                type={isPasswordConfirmationVisible ? 'text' : 'password'}
                name="new-password"
                autoComplete="new-password"
                value={newPasswordConfirmation}
                onChange={(event) => setNewPasswordConfirmation(event.target.value)}
                className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary h-fit"
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue"
                onClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
              >
                {isPasswordConfirmationVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-7">
          <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full mb-4">Email</div>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary h-fit"
          />
        </div>
      )}
      <button
        className="bg-secondary px-6 py-2.5 rounded-xl w-full cursor-pointer text-darkBlue text-p24 font-bold mt-7"
        onClick={() => {
          if (isForgotPasswordSubmit) {
            forgotPasswordSubmit();
          } else {
            forgotPassword();
          }
        }}
      >
        {isForgotPasswordSubmit ? 'Submit' : 'Send code'}
      </button>
      {isForgotPasswordSubmit && (
        <div className="flex justify-center">
          <button className="text-darkBlue font-bold text-p20 mt-4" onClick={forgotPassword}>
            Resend code
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
