import { setContext } from '@apollo/client/link/context';
import { from, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

export const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

export const client = new ApolloClient({
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : ''),
  }),
});

export const GET_POSTCODE_LOOKUP = async (postcode: string) => {
  const res = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
  return res.json();
};
