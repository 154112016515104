import { gql, useMutation } from '@apollo/client';
import { GET_CUSTOMER } from './useGetCustomer';

export const CREATE_SUPPORTED_PERSON = gql`
  mutation CreateSupportedPerson($input: CreateSupportedPersonForCustomerInput) {
    CreateSupportedPerson(input: $input) {
      id
      message
      date
    }
  }
`;

const useCreateSupportedPerson = (id: string) => {
  const jwt = localStorage.getItem('token');

  const [createSupportedPerson, mutationCreateSupportedPerson] = useMutation(CREATE_SUPPORTED_PERSON, {
    context: {
      headers: {
        Authorization: jwt || null,
      },
    },
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        context: {
          headers: {
            Authorization: jwt,
          },
        },
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return { createSupportedPerson, mutationCreateSupportedPerson };
};

export { useCreateSupportedPerson };
