import { HomeIcon } from '@heroicons/react/24/outline';
import { HomeServicesStoryblok } from 'types/components-sb';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import DisplayReviews from 'components/DisplayReviews';
import PartnerSearch from 'components/PartnerSearch';

interface HomeServicesProps {
  blok: HomeServicesStoryblok;
}

const HomeServices = ({ blok }: HomeServicesProps) => {
  return (
    <div>
      <div className="flex flex-row items-center w-full bg-bgLightBlue ">
        <div className="flex flex-col justify-center items-center lg:items-start w-full h-full lg:w-1/2 p-10">
          <div className="w-14 h-14 flex justify-center items-center bg-primary rounded-full">
            <HomeIcon className="w-7 h-7 text-white" />
          </div>
          <div className="text-h4 md:text-h3 lg:text-h2 xl:text-h1 text-darkBlue mt-2 text-center lg:text-left">{blok.title}</div>
          <div className="text-p18 lg:text-p20 xl:text-p24 text-darkBlue mt-2 text-center lg:text-left">{blok.subtitle}</div>
        </div>
        <img src={blok.image.filename} alt="home services" className="hidden lg:block w-1/2" />
      </div>
      <div className="px-6 sm:px-10 mt-10 lg:mt-20">
        <div className="text-h4 md:text-h3 lg:text-h2 text-darkBlue font-bold">{blok.categoriesTitle}</div>
        <div className="text-p18 md:text-p20 text-darkBlue lg:w-[60%] mt-2">{blok.categoriesSubtitle}</div>
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-5 lg:gap-10 mt-10 lg:mt-14">
          {blok?.categories?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isBlue={index % 2 === 0} />;
          })}
        </div>
        <div className="text-h4 md:text-h3 lg:text-h2 text-darkBlue font-bold mt-10 lg:mt-20">{blok.howItWorksTitle}</div>
        <div className="grid xl:grid-cols-3 grid-cols-1 gap-8 mt-12">
          {blok?.howItWorks?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
          })}
        </div>
        <PartnerSearch />
        <DisplayReviews title={blok.reviewsTitle ?? 'Home Service Reviews'} />
        <div className="text-h4 md:text-h3 lg:text-h2 text-darkBlue font-bold mt-10 lg:mt-20">{blok.servicesPartnersTitle}</div>
        <div className="grid grid-cols-1 gap-8 mt-10 lg:mt-12">
          {blok?.servicesPartners?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
