import { PhoneArrowUpRightIcon } from '@heroicons/react/24/solid';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { useSendEmail } from 'api/hooks/useSendEmail';
import Loading from 'components/Loading';
import { useState } from 'react';
import { ContactStoryblok } from 'types/components-sb';
import { ContactQuestionType, ToastStatus } from 'types/enum';
import { QuestionType } from 'types/types';
import { displayToast } from 'utils/utils';

interface ContactProps {
  blok: ContactStoryblok;
}

const Contact = ({ blok }: ContactProps) => {
  const { sendEmail, mutationSendEmail } = useSendEmail({
    to: blok.emailTo ?? '',
    template: 'generic',
    subject: blok.emailSubject ?? '',
    content: [],
  });

  const [questions, setQuestions] = useState<QuestionType[]>(
    blok.questions?.map((q) => {
      return { question: q.question ?? '', answer: '' };
    }) ?? [],
  );
  const [areQuestionsValid, setAreQuestionsValid] = useState<{ isValid: boolean; type: ContactQuestionType; error: string }[]>(
    blok.questions?.map((q) => {
      return {
        isValid: !q.isRequired,
        type: q.isEmail ? ContactQuestionType.EMAIL : q.isOptions ? ContactQuestionType.RADIO : ContactQuestionType.TEXT,
        error: q.isRequired ? 'Please fill all the mandatory fields.' : '',
      };
    }) ?? [],
  );

  const onQuestionChange = (question: string, answer: string, isValid: boolean, error: string) => {
    const newQuestions = [...questions];
    const i = newQuestions.findIndex((q) => q.question === question);
    const newAreQuestionsValid = [...areQuestionsValid];

    newQuestions[i].answer = answer;
    newAreQuestionsValid[i].isValid = isValid;
    newAreQuestionsValid[i].error = error;

    setQuestions(newQuestions);
    setAreQuestionsValid(newAreQuestionsValid);
  };

  const onSendEmail = async () => {
    const isNotValid = areQuestionsValid.find((q) => !q.isValid);

    if (isNotValid) {
      displayToast(ToastStatus.ERROR, isNotValid.error);
      return;
    }
    if (blok.emailTo) {
      await sendEmail({
        variables: {
          input: {
            to: blok.emailTo,
            template: 'generic',
            subject: blok.emailSubject ?? 'Contact us',
            content: questions,
          },
        },
      });
      displayToast(
        ToastStatus.SUCCESS,
        blok.emailValidationMessage ? blok.emailValidationMessage : 'Thank you we have received your email and will come back to you as soon as possible.',
      );
    }
  };

  if (mutationSendEmail.loading) {
    return <Loading />;
  }

  return (
    <div {...storyblokEditable(blok)}>
      <div className="bg-bgYellow p-4 md:px-[5%] lg:px-[10%] xl:px-[15%] sm:py-6 flex items-center">
        <div className="bg-primary rounded-full w-12 h-12 sm:w-14 sm:h-14 flex items-center justify-center">
          <PhoneArrowUpRightIcon className="text-white h-6 w-6 sm:h-7 sm:w-7" />
        </div>
        <div className="text-darkBlue text-p20 sm:text-p24 lg:text-p28 ml-4">Contact us</div>
      </div>
      <div className="p-4 md:px-[5%] lg:px-[10%] xl:px-[15%]">
        <div className="text-center text-darkBlue bg-primary rounded-2xl flex flex-col items-center justify-center p-8 md:py-14 mt-6 md:mt-24">
          <img src="/assets/call_receiver_circled.png" alt="call receiver" />
          <div className="flex flex-col sm:flex-row text-white ">
            <div className="text-p20 md:text-p28">Call us on</div>
            <a href="tel:+4401235330832" className="text-p28 font-bold ml-2">
              01235 330832
            </a>
          </div>
          <div className="text-white text-p16 md:text-p18">{blok.openingTime}</div>
        </div>
        <div className="w-full h-px bg-darkBlue my-7 md:my-20" />
        <div className="text-h2 text-darkBlue font-bold">{blok.title}</div>
        <div className="grid grid-cols-1 gap-8 mt-8 md:mt-12">
          {blok.questions?.map((nestedBlok: SbBlokData) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} onChange={onQuestionChange} />;
          })}
        </div>
        <div className="hidden sm:block w-full h-px bg-darkBlue mt-12 mb-10" />
        <div className="flex justify-end">
          <button
            onClick={onSendEmail}
            className="bg-secondary px-6 py-3.5 rounded-xl w-full sm:w-fit cursor-pointer text-darkBlue text-p20 md:text-p24 font-bold mt-10 sm:mt-0"
          >
            Send email
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
