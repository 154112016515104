import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';
import { Auth } from 'aws-amplify';
import Loading from 'components/Loading';
import { useState } from 'react';
import { ToastStatus } from 'types/enum';
import { displayToast } from 'utils/utils';

interface SigninProps {
  setIsForgotPassword: (value: boolean) => void;
  onClose: () => void;
}

const Signin = ({ setIsForgotPassword, onClose }: SigninProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const signIn = async () => {
    setIsLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      const groups = user?.signInUserSession?.accessToken?.payload ? user?.signInUserSession?.accessToken?.payload['cognito:groups'] : [];
      if (!groups || groups.length === 0 || !groups.includes('customers')) {
        await Auth.signOut();
        throw new Error('This account exist but is not a customer. Please ask for help.');
      }

      if (user && user.username) {
        const session = await Auth.currentSession();
        const accessToken = session.getIdToken();
        const jwt = accessToken.getJwtToken();
        localStorage.setItem('token', jwt);
        localStorage.setItem('userId', user.username);
        onClose();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayToast(ToastStatus.ERROR, error.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="text-p24 lg:text-p28 xl:text-h4 font-bold text-center text-darkBlue mt-2" data-cy="used-thrive-before">
        Used Thrive before?
      </div>
      <div className="text-p18 md:text-p20 lg:text-p22 text-center text-darkBlue">If you already have a thrive account, login now</div>
      <div className="mt-7">
        <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full mb-4">Email</div>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary h-fit"
        />
      </div>
      <div className="mt-4">
        <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full mb-4">Password</div>
        <div className="relative w-full">
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border focus-visible:outline-primary h-fit w-full"
          />
          <button className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {isPasswordVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
          </button>
        </div>
      </div>
      <button className="text-darkBlue font-bold underline text-p15 mt-4 w-fit" onClick={() => setIsForgotPassword(true)}>
        Forgot password?
      </button>
      <button
        className="bg-secondary px-6 py-2.5 rounded-xl w-full cursor-pointer text-darkBlue text-p24 font-bold mt-7"
        onClick={signIn}
        disabled={isLoading}
        data-cy="sign-in-button"
      >
        {isLoading ? <Loading isComponent /> : 'Sign in'}
      </button>
    </div>
  );
};

export default Signin;
