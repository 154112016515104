/* eslint-disable react/no-danger */
import { renderRichText } from '@storyblok/react';
import React from 'react';
import { ServicesPartnerStoryblok } from 'types/components-sb';

interface ServicesPartnerProps {
  blok: ServicesPartnerStoryblok;
}

const ServicesPartner = ({ blok }: ServicesPartnerProps) => {
  return (
    <div className="p-4 md:p-8 lg:p-12 xl:p-20 flex flex-col lg:flex-row items-center bg-bgBlue rounded-xl relative">
      <img src="/assets/blueWave2.png" alt={blok.name} className="hidden lg:block absolute left-0 top-0 bottom-0 z-0 rounded-xl" />
      <img src={blok.image?.filename} alt={blok.name} className="rounded-xl z-10" />
      <div className="text-darkBlue lg:ml-16 z-10 mt-8 lg:mt-0">
        <div className="text-p28 font-bold text-center lg:text-left">{blok.name}</div>
        <div className="text-p28 text-center lg:text-left mt-4 lg:mt-0">{blok.location}</div>
        <div className="text-p20 mt-4 lg:mt-0" dangerouslySetInnerHTML={{ __html: renderRichText(blok.description) }} />
      </div>
    </div>
  );
};

export default ServicesPartner;
