import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Auth, Hub } from 'aws-amplify';
import Login from 'components/SignIn';
import { useEffect, useState } from 'react';
import { AccountStoryblok } from 'types/components-sb';
import { useGetCustomer } from 'api/hooks/useGetCustomer';
import Loading from 'components/Loading';
import ChangePassword from './components/ChangePassword';
import UpdateAccount from './components/UpdateAccount';

interface AccountProps {
  blok: AccountStoryblok;
}

const Account = ({ blok }: AccountProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem('userId') ?? '');
  const { customer, refetch, loadingCustomer } = useGetCustomer(userId, false);

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const session = await Auth.currentSession();
        const accessToken = session.getIdToken();
        const jwt = accessToken.getJwtToken();
        localStorage.setItem('token', jwt);
        if (authUser && authUser.username) {
          setUserId(authUser.username);
          setIsLoggedIn(true);
          refetch();
        }
      } catch {
        setIsLoggedIn(false);
        setShowLogin(true);
      }
    };
    Hub.listen('auth', checkUserAuth);
    checkUserAuth();
    return () => Hub.remove('auth', checkUserAuth);
  }, [refetch]);

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  if (loadingCustomer) {
    return <Loading />;
  }

  return (
    <div>
      {isLoggedIn && customer ? (
        <div>
          <div className="bg-bgYellow p-4 md:px-[5%] lg:px-[10%] xl:px-[15%] sm:py-6 flex items-center justify-between">
            <div className="flex items-center">
              <div className="bg-primary rounded-full w-12 h-12 sm:w-14 sm:h-14 flex items-center justify-center">
                <UserCircleIcon className="text-white h-6 w-6 sm:h-7 sm:w-7" />
              </div>
              <div className="text-darkBlue text-p20 sm:text-p24 lg:text-p28 ml-4" data-testid="category-search-title">
                {blok.title}
              </div>
            </div>
          </div>
          <div className="my-10 px-4 md:px-[5%] lg:px-[10%] xl:px-[15%]">
            <UpdateAccount detailsText={blok.details ?? ''} user={customer} />
            <div className="h-px w-full bg-darkBlue my-10" />
            <ChangePassword changePasswordText={blok.resetPassword ?? ''} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[100vh] w-full text-darkBlue font-bold text-p28 text-center">Please login to access this page</div>
      )}
      {showLogin && <Login onClose={() => setShowLogin(false)} />}
    </div>
  );
};

export default Account;
