import { UserGroupIcon } from '@heroicons/react/24/solid';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import ContactBox from 'components/ContactBox';
import { CommunityLinkStoryblok, CommunityServicesStoryblok } from 'types/components-sb';

interface CommunityServicesProps {
  blok: CommunityServicesStoryblok;
}

const CommunityServices = ({ blok }: CommunityServicesProps) => {
  return (
    <div>
      <div className="flex flex-row items-center w-full bg-bgLightBlue ">
        <div className="flex flex-col justify-center items-center lg:items-start w-full h-full lg:w-1/2 p-4 md:p-10">
          <div className="w-14 h-14 flex justify-center items-center bg-primary rounded-full">
            <UserGroupIcon className="w-7 h-7 text-white" />
          </div>
          <div className="text-h4 md:text-h3 lg:text-h2 xl:text-h1 text-darkBlue mt-2 text-center lg:text-left">{blok.title}</div>
          <div className="text-p18 lg:text-p20 lg:text-p24 text-darkBlue mt-2 text-center lg:text-left">{blok.subtitle}</div>
        </div>
        <img src={blok.image?.filename} alt="home services" className="hidden lg:block w-1/2" />
      </div>
      <div className="px-6 sm:px-10 mt-10 lg:mt-20">
        <div className="text-h4 md:text-h3 lg:text-h2 text-darkBlue font-bold mt-10 lg:mt-20">{blok.howItWorksTitle}</div>
        <div className="grid xl:grid-cols-3 grid-cols-1 gap-8 mt-12">
          {blok?.howItWorks?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
          })}
        </div>
        <div className="bg-bgBlue p-10 flex justify-left items-center mt-10 lg:mt-20 rounded-xl">
          <div className="text-darkBlue">
            <div className="text-h4 md:text-h3 lg:text-h2 xl:text-h1 text-center md:text-left">{blok.communitiesTitle}</div>
            <div className="text-p20 md:text-p24 lg:text-p28 text-center md:text-left mt-2">{blok.communitiesSubtitle}</div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 mt-12">
          {blok?.communityLinks?.map((link: CommunityLinkStoryblok, index: number) => {
            return (
              <div key={index} className="border-b border-bgSeparator ml-10 pb-10">
                <a href={`/${link.link?.cached_url}`} className="text-textBlue text-h3 group transition duration-300 block w-fit text-center">
                  {link.name}
                  <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
                </a>
              </div>
            );
          })}
        </div>
        <ContactBox />
      </div>
    </div>
  );
};

export default CommunityServices;
