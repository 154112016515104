import { useQuery, gql } from '@apollo/client';

export const GET_PARTNERS_BY_PROXIMITY = gql`
  query GetPartnersByProximity($args: PartnersByProximitySearch) {
    GetPartnersByProximity(args: $args)
  }
`;

const useGetPartnersByProximity = (postcode: string, productId: string) => {
  const {
    loading: loadingPartnersByProximity,
    data: partnersByProximity,
    error: errorPartnersByProximity,
    refetch,
  } = useQuery(GET_PARTNERS_BY_PROXIMITY, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      args: {
        postcode,
        productId,
      },
    },
  });

  return {
    loadingPartnersByProximity,
    partnersByProximity,
    errorPartnersByProximity,
    refetch,
  };
};

export { useGetPartnersByProximity };
