import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import ForgotPassword from './components/ForgotPassword';
import Signin from './components/Signin';
import Signup from './components/Signup';

interface LoginProps {
  onClose: () => void;
  fromBooking?: boolean;
}

const Login = ({ onClose, fromBooking }: LoginProps) => {
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-bgBlue outline-none focus:outline-none px-7 pb-10 pt-5 max-h-screen md:max-h-[90vh] overflow-y-auto">
            {isForgotPassword ? (
              <ForgotPassword onClose={onClose} onCloseForgotPassword={() => setIsForgotPassword(false)} />
            ) : (
              <>
                {!fromBooking && (
                  <div className="flex flex-row justify-end">
                    <button className="text-darkBlue text-p24 font-bold" onClick={onClose}>
                      <XMarkIcon className="w-10 h-10" />
                    </button>
                  </div>
                )}
                {isSignUp ? <Signup onClose={onClose} setIsSignUp={setIsSignUp} /> : <Signin setIsForgotPassword={setIsForgotPassword} onClose={onClose} />}
                {fromBooking && <div className="h-px w-full bg-darkBlue mt-7 mb-3" />}
                {!isSignUp && (
                  <button
                    className="bg-darkBlue px-6 py-2.5 rounded-xl w-full cursor-pointer text-white text-p24 font-bold mt-4"
                    onClick={() => {
                      if (fromBooking) {
                        onClose();
                      } else {
                        setIsSignUp(!isSignUp);
                      }
                    }}
                    data-cy="sign-up-form-button"
                  >
                    {fromBooking ? 'No, this is my first Thrive booking' : 'Register'}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
};

export default Login;
