import { XMarkIcon } from '@heroicons/react/24/outline';
import { SupportedPerson } from '__generated__/graphql';
import { GET_POSTCODE_LOOKUP } from 'api';
import AddressForm from 'components/AddressForm';
import ReactSelect from 'components/Select';
import { useState } from 'react';
import { ToastStatus } from 'types/enum';
import { SelectOption } from 'types/types';
import { displayToast } from 'utils/utils';

interface UpdateSupportedPersonProps {
  supportedPerson?: SupportedPerson;
  onClose: () => void;
  addSupportedPerson: (supportedPerson: SupportedPerson) => void;
  editSupportedPerson: (supportedPerson: SupportedPerson) => void;
}

const UpdateSupportedPerson = ({ supportedPerson, onClose, addSupportedPerson, editSupportedPerson }: UpdateSupportedPersonProps) => {
  const [firstName, setFirstName] = useState<string>(supportedPerson?.firstName ?? '');
  const [lastName, setLastName] = useState<string>(supportedPerson?.surname ?? '');
  const [relationship, setRelationship] = useState<SelectOption | undefined>(
    supportedPerson?.relationship ? { value: supportedPerson?.relationship, label: supportedPerson?.relationship } : undefined,
  );
  const [addressNotes, setAddressNotes] = useState<string>(supportedPerson?.address?.addressNotes ?? '');
  const [addressLine1, setAddressLine1] = useState<string>(supportedPerson?.address?.addressLine1 ?? '');
  const [addressLine2, setAddressLine2] = useState<string>(supportedPerson?.address?.addressLine2 ?? '');
  const [city, setCity] = useState<string>(supportedPerson?.address?.city ?? '');
  const [region, setRegion] = useState<string>(supportedPerson?.address?.region ?? '');
  const [postcode, setPostcode] = useState<string>(supportedPerson?.address?.postcode ?? '');
  const supportedPersonRelationshipSelectOptions = [
    { value: 'Daughter', label: 'Daughter' },
    { value: 'Son', label: 'Son' },
    { value: 'Friend', label: 'Friend' },
    { value: 'House manager', label: 'House manager' },
    { value: 'Wellbeing support worker', label: 'Wellbeing support worker' },
  ];

  const onSave = async () => {
    if (addressLine1 === '' || city === '' || region === '' || postcode === '' || firstName === '' || lastName === '') {
      displayToast(ToastStatus.ERROR, 'Please fill all the required fields in address.');
      return;
    }
    const res = await GET_POSTCODE_LOOKUP(postcode);
    if (res.status !== 200) {
      displayToast(ToastStatus.ERROR, res.error);
      return;
    }
    if (supportedPerson) {
      editSupportedPerson({
        id: supportedPerson.id,
        firstName,
        surname: lastName,
        relationship: relationship?.value,
        address: {
          addressLine1,
          addressLine2,
          city,
          postcode,
          region,
          addressNotes,
        },
      });
    } else {
      addSupportedPerson({
        firstName,
        surname: lastName,
        relationship: relationship?.value,
        address: {
          addressLine1,
          addressLine2,
          city,
          postcode,
          region,
          addressNotes,
        },
      });
    }
    onClose();
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full md:w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-screen md:max-h-[90vh] md:min-w-[580px]">
            <div className="flex flex-row justify-between p-6">
              <div className="text-darkBlue font-bold text-p24 md:text-p28">
                {supportedPerson ? `Edit ${supportedPerson.firstName} ${supportedPerson.surname}` : 'Add a new supported person'}{' '}
              </div>
              <button className="text-darkBlue text-p24 font-bold" onClick={onClose}>
                <XMarkIcon className="w-10 h-10" />
              </button>
            </div>
            <div className="overflow-y-auto px-6 pb-4">
              <div className="flex flex-col">
                <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Firstname *</div>
                <input
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
                  data-cy="supported-person-update-firstname"
                />
              </div>
              <div className="flex flex-col mt-5">
                <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Lastname *</div>
                <input
                  type="text"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
                  data-cy="supported-person-update-lastname"
                />
              </div>
              <div className="flex flex-col mt-5">
                <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Your relationship *</div>
                <ReactSelect
                  options={supportedPersonRelationshipSelectOptions}
                  onChange={setRelationship}
                  testId="supported-person-relationship"
                  placeholder="Choose an option"
                  selectedValue={relationship}
                  borderBlue
                />
              </div>
              <AddressForm
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                postcode={postcode}
                city={city}
                region={region}
                addressNotes={addressNotes}
                setAddressLine1={setAddressLine1}
                setAddressLine2={setAddressLine2}
                setPostcode={setPostcode}
                setCity={setCity}
                setRegion={setRegion}
                setAddressNotes={setAddressNotes}
              />
            </div>
            <div className="flex flex-row justify-between items-center p-6">
              <button
                className="text-gray-500 cursor-pointer text-p20 font-bold"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-darkBlue px-4 py-2.5 rounded-xl cursor-pointer text-white text-p20 font-bold"
                onClick={onSave}
                data-cy="save-supported-person-button"
              >
                {supportedPerson ? 'Save' : 'Add'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
};

export default UpdateSupportedPerson;
