import Select from 'react-select';
import { SelectOption } from 'types/types';

interface ReactSelectProps {
  options: SelectOption[];
  name?: string;
  onChange: (option: SelectOption, name?: string) => void;
  selectedValue?: SelectOption;
  disabled?: boolean;
  searchable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  testId?: string;
  borderBlue?: boolean;
}

const ReactSelect = ({
  options,
  onChange,
  name,
  isLoading = false,
  selectedValue,
  disabled = false,
  searchable = false,
  placeholder,
  testId = 'react-select',
  borderBlue = false,
}: ReactSelectProps) => {
  return (
    <Select
      options={options}
      name={name}
      placeholder={!searchable ? placeholder || 'Please select...' : ''}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#737E8C',
        },
      })}
      id={testId}
      classNamePrefix="react-select"
      isLoading={isLoading}
      // eslint-disable-next-line react/no-unstable-nested-components
      noOptionsMessage={() => <p> No Options available </p>}
      isDisabled={disabled}
      onChange={(option, metaData) => onChange({ value: option?.value ?? '', label: option?.label ?? '', id: option?.id }, metaData.name || '')}
      value={selectedValue}
      isSearchable={searchable}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          padding: '10px 10px',
          fontSize: '20px',
          borderRadius: '8px',
          borderColor: borderBlue ? '#000B77' : '	#cccccc',
          hover: {
            borderColor: '#FFF',
          },
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: '#000B77',
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: '#000B77',
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: '#000B77',
          marginLeft: '10px',
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#E6F4FE',
          padding: '24px 24px 14px 24px',
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#FFF',
          marginBottom: '10px',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: '#E6F4FE',
          },
          color: '#000B77',
          fontWeight: 'bold',
        }),
      }}
    />
  );
};

export default ReactSelect;
