import { getStoryblokApi } from '@storyblok/react';
import { ClientAddress, Customer, SupportedPerson } from '__generated__/graphql';
import { GET_POSTCODE_LOOKUP } from 'api';
import { useEditCustomer } from 'api/hooks/useEditCustomer';
import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import { BookStoryblok } from 'types/components-sb';
import { ToastStatus } from 'types/enum';
import { displayToast } from 'utils/utils';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useCreateSupportedPerson } from 'api/hooks/useCreateSupportedPerson';
import { useDeleteSupportedPerson } from 'api/hooks/useDeleteSupportedPerson';
import { useEditSupportedPerson } from 'api/hooks/useEditSupportedPerson';
import UpdateSupportedPerson from './UpdateSupportedPerson';
import UpdateAddress from './UpdateAddress';

interface UpdateAccountProps {
  detailsText: string;
  user: Customer;
}

const UpdateAccount = ({ detailsText, user }: UpdateAccountProps) => {
  const { editCustomer, mutationEditCustomer } = useEditCustomer(user.id);
  const { createSupportedPerson, mutationCreateSupportedPerson } = useCreateSupportedPerson(user.id);
  const { editSupportedPerson, mutationEditSupportedPerson } = useEditSupportedPerson(user.id);
  const { deleteSupportedPerson, mutationDeleteSupportedPerson } = useDeleteSupportedPerson(user.id);

  const [firstName, setFirstName] = useState<string>(user.firstName);
  const [lastName, setLastName] = useState<string>(user.surname);
  const [phone, setPhone] = useState<string>(user.phoneNumber);
  const [otherInformations, setOtherInformations] = useState<string>(user.otherInformations ?? '');
  const addresses = user?.address ?? [];
  const supportedPeople = user?.supportedPeople ?? [];
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<ClientAddress>();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [isChangeSupportedPerson, setIsChangeSupportedPerson] = useState(false);
  const [selectedSupportedPerson, setSelectedSupportedPerson] = useState<SupportedPerson>();

  const [blok, setBlok] = useState<BookStoryblok>();
  const storyblokApi = getStoryblokApi();

  useEffect(() => {
    const getSignUpSBData = async () => {
      const { data } = await storyblokApi.get('cdn/stories/book', { version: 'draft' });
      setBlok(data.story.content);
    };
    getSignUpSBData();
  }, [storyblokApi]);

  const isEditValid = async () => {
    if (firstName === '' || lastName === '' || phone === '') {
      displayToast(ToastStatus.ERROR, 'Please fill all the required fields.');
      return false;
    }
    let isAddressValid = true;
    addresses.every(async (address) => {
      if (address.addressLine1 === '' || address.city === '' || address.region === '' || address.postcode === '') {
        displayToast(ToastStatus.ERROR, 'Please fill all the required fields in address.');

        isAddressValid = false;
        return false;
      }
      const res = await GET_POSTCODE_LOOKUP(address.postcode);
      if (res.status !== 200) {
        displayToast(ToastStatus.ERROR, res.error);
        isAddressValid = false;
        return false;
      }
      return true;
    });
    if (!isAddressValid) {
      return false;
    }
    return true;
  };

  const updateAccount = async () => {
    const isValid = await isEditValid();
    if (!isValid) {
      return;
    }
    const input = {
      id: user.id,
      firstName,
      surname: lastName,
      phoneNumber: phone,
      address: addresses.map((a) => ({
        addressLine1: a.addressLine1,
        addressLine2: a.addressLine2,
        addressNotes: a.addressNotes,
        city: a.city,
        postcode: a.postcode,
        region: a.region,
      })),
      otherInformations,
    };
    const res = await editCustomer({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    displayToast(ToastStatus.SUCCESS, 'Your account has been updated successfully.');
  };

  const addNewAddress = async (address: ClientAddress) => {
    const input = {
      id: user.id,
      address: [
        ...addresses.map((a) => ({
          addressLine1: a.addressLine1,
          addressLine2: a.addressLine2,
          addressNotes: a.addressNotes,
          city: a.city,
          postcode: a.postcode,
          region: a.region,
        })),
        address,
      ],
    };
    const res = await editCustomer({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    displayToast(ToastStatus.SUCCESS, 'Address created successfully.');
  };

  const editAddress = async (address: ClientAddress, index: number) => {
    const newAddresses = [...addresses];
    newAddresses[index] = address;
    const input = {
      id: user.id,
      address: newAddresses.map((a) => ({
        addressLine1: a.addressLine1,
        addressLine2: a.addressLine2,
        addressNotes: a.addressNotes,
        city: a.city,
        postcode: a.postcode,
        region: a.region,
      })),
    };
    const res = await editCustomer({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    displayToast(ToastStatus.SUCCESS, 'Address updated successfully.');
  };

  const deleteAddress = async (index: number) => {
    const newAddresses = [...addresses];
    newAddresses.splice(index, 1);
    const input = {
      id: user.id,
      address: newAddresses.map((a) => ({
        addressLine1: a.addressLine1,
        addressLine2: a.addressLine2,
        addressNotes: a.addressNotes,
        city: a.city,
        postcode: a.postcode,
        region: a.region,
      })),
    };
    const res = await editCustomer({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    displayToast(ToastStatus.SUCCESS, 'Address deleted successfully.');
  };

  const onAddSupportedPerson = async (supportedPerson: SupportedPerson) => {
    const input = {
      customerId: user.id,
      firstName: supportedPerson.firstName,
      surname: supportedPerson.surname,
      relationship: supportedPerson.relationship,
      address: supportedPerson.address,
    };
    const res = await createSupportedPerson({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    displayToast(ToastStatus.SUCCESS, 'Supported Person created successfully.');
  };

  const onUpdateSupportedPerson = async (supportedPerson: SupportedPerson) => {
    const input = {
      id: supportedPerson.id,
      customerId: user.id,
      firstName: supportedPerson.firstName,
      surname: supportedPerson.surname,
      relationship: supportedPerson.relationship,
      address: supportedPerson.address,
    };
    const res = await editSupportedPerson({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    displayToast(ToastStatus.SUCCESS, 'Supported Person updated successfully.');
  };

  const onDeleteSupportedPerson = async (id: string) => {
    const input = {
      id,
      customerId: user.id,
    };
    const res = await deleteSupportedPerson({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    displayToast(ToastStatus.SUCCESS, 'Supported Person deleted successfully.');
  };

  if (mutationCreateSupportedPerson.loading || mutationEditSupportedPerson.loading || mutationDeleteSupportedPerson.loading || mutationEditCustomer.loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="text-p28 lg:text-h2 text-darkBlue font-bold">Your details</div>
      <div className="text-p18 md:text-p20 text-darkBlue mt-3">{detailsText}</div>
      {blok && (
        <div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 md:mb-0">{blok.customerFirstName}</div>
            <input
              type="text"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="account-update-firstname"
            />
          </div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 md:mb-0">{blok.customerLastName}</div>
            <input
              type="text"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="account-update-lastname"
            />
          </div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 md:mb-0">{blok.customerPhone}</div>
            <input
              type="phone"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="account-update-phone"
            />
          </div>
          <div className="flex flex-row items-center gap-4 justify-between mt-7 lg:mt-[56px]">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold">{blok.address}</div>
            <button
              type="button"
              aria-label="add-address"
              className="bg-darkBlue rounded-xl cursor-pointer p-3"
              onClick={() => setIsChangeAddress(true)}
              data-cy="add-address-button"
            >
              <PlusIcon className="w-6 h-6 text-white" />
            </button>
          </div>
          <div className="mt-10">
            {addresses.map((address, index) => (
              <div className="bg-bgBlue p-7 rounded-xl flex flex-col sm:flex-row sm:items-center justify-between gap-4 mt-5" key={index}>
                <div className="text-darkBlue text-p18 md:text-p20">
                  <div className="text-p22 md:text-p24 font-semibold mb-3">Address {index + 1}</div>
                  <div data-cy={`address-${index}-line-1`}>{address.addressLine1}</div>
                  <div data-cy={`address-${index}-line-2`}>{address.addressLine2}</div>
                  <div data-cy={`address-${index}-city`}>{address.city}</div>
                  <div data-cy={`address-${index}-region`}>{address.region}</div>
                  <div data-cy={`address-${index}-postcode`}>{address.postcode}</div>
                  <div data-cy={`address-${index}-notes`}>{address.addressNotes}</div>
                </div>
                <div className="flex flex-col justify-between items-end self-stretch">
                  {addresses.length > 1 && (
                    <button
                      className="bg-error p-3 rounded-xl cursor-pointer text-white w-fit"
                      onClick={() => deleteAddress(index)}
                      data-cy={`delete-address-${index}-button`}
                    >
                      <TrashIcon className="w-5 h-5 text-white" />
                    </button>
                  )}
                  <button
                    className="bg-darkBlue px-4 py-2.5 rounded-xl cursor-pointer text-white text-p20 font-bold"
                    onClick={() => {
                      setIsChangeAddress(true);
                      setSelectedAddress(address);
                      setSelectedAddressIndex(index);
                    }}
                    data-cy={`change-address-${index}-button`}
                  >
                    Change
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col lg:flex-row mt-7 lg:mt-[56px]">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 md:mb-0">{blok.otherInformations}</div>
            <input
              type="text"
              value={otherInformations}
              onChange={(event) => setOtherInformations(event.target.value)}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full lg:w-[60%] focus-visible:outline-primary lg:ml-10 h-fit"
              data-cy="account-update-other-informations"
            />
          </div>
          <div className="flex flex-row gap-4 items-center justify-between mt-7 lg:mt-[56px]">
            <div className="text-p20 md:text-p24 text-darkBlue font-bold">Supported people</div>
            <button
              type="button"
              aria-label="add-supported-person"
              className="bg-darkBlue rounded-xl cursor-pointer p-3"
              onClick={() => setIsChangeSupportedPerson(true)}
              data-cy="add-supported-person-button"
            >
              <PlusIcon className="w-6 h-6 text-white" />
            </button>
          </div>
          <div className="mt-10">
            {supportedPeople.map((supportedPerson, index) => (
              <div className="bg-bgBlue p-7 rounded-xl flex flex-col sm:flex-row sm:items-center justify-between gap-4 mt-5" key={index}>
                <div className="text-darkBlue text-p18 md:text-p20">
                  <div className="text-p22 md:text-p24 font-semibold mb-3" data-cy={`supported-person-${index}-name`}>
                    {supportedPerson.firstName} {supportedPerson.surname}
                  </div>
                  <div data-cy={`supported-person-${index}-address-line-1`}>{supportedPerson?.address?.addressLine1}</div>
                  <div data-cy={`supported-person-${index}-address-line-2`}>{supportedPerson?.address?.addressLine2}</div>
                  <div data-cy={`supported-person-${index}-address-city`}>{supportedPerson?.address?.city}</div>
                  <div data-cy={`supported-person-${index}-address-region`}>{supportedPerson?.address?.region}</div>
                  <div data-cy={`supported-person-${index}-address-postcode`}>{supportedPerson?.address?.postcode}</div>
                  <div data-cy={`supported-person-${index}-address-notes`}>{supportedPerson?.address?.addressNotes}</div>
                </div>
                <div className="flex flex-col justify-between items-end self-stretch">
                  <button
                    className="bg-error p-3 rounded-xl cursor-pointer text-white w-fit"
                    onClick={() => onDeleteSupportedPerson(supportedPerson.id ?? '')}
                    data-cy={`delete-supported-person-${index}-button`}
                  >
                    <TrashIcon className="w-5 h-5 text-white" />
                  </button>
                  <button
                    className="bg-darkBlue px-4 py-2.5 rounded-xl cursor-pointer text-white text-p20 font-bold"
                    onClick={() => {
                      setIsChangeSupportedPerson(true);
                      setSelectedSupportedPerson(supportedPerson);
                    }}
                    data-cy={`change-supported-person-${index}-button`}
                  >
                    Change
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end mt-7 ">
            <button
              className="bg-secondary px-6 py-2.5 rounded-xl w-full sm:w-fit cursor-pointer text-darkBlue text-p20 font-bold"
              onClick={updateAccount}
              disabled={mutationEditCustomer.loading}
              data-cy="account-update-save"
            >
              {mutationEditCustomer.loading ? <Loading isComponent /> : 'Save'}
            </button>
          </div>
        </div>
      )}
      {isChangeAddress && (
        <UpdateAddress
          address={selectedAddress}
          index={selectedAddressIndex}
          onClose={() => {
            setSelectedAddress(undefined);
            setSelectedAddressIndex(0);
            setIsChangeAddress(false);
          }}
          addNewAddress={addNewAddress}
          editAddress={editAddress}
        />
      )}
      {isChangeSupportedPerson && (
        <UpdateSupportedPerson
          supportedPerson={selectedSupportedPerson}
          onClose={() => {
            setSelectedSupportedPerson(undefined);
            setIsChangeSupportedPerson(false);
          }}
          addSupportedPerson={onAddSupportedPerson}
          editSupportedPerson={onUpdateSupportedPerson}
        />
      )}
    </div>
  );
};

export default UpdateAccount;
