import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { Booking } from '__generated__/graphql';
import { format } from 'date-fns';
import { useState } from 'react';
import Avatar from 'components/Avatar';
import BookingModal from './BookingModal';

interface BookingPreviewProps {
  booking: Booking;
  isPastBooking?: boolean;
  testId?: string;
}

const BookingPreview = ({ booking, isPastBooking, testId }: BookingPreviewProps) => {
  const [showBooking, setShowBooking] = useState(false);
  return (
    <>
      <div className="flex flex-col md:flex-row md:items-center border border-darkBlue rounded-xl p-4 md:p-6 justify-between" data-cy={testId}>
        <div className="flex flex-row justify-start items-center mb-4 md:mb-0">
          <div className="w-[64px] h-[64px] lg:w-[100px] lg:h-[100px] rounded-full p-0.5 border-2 border-secondary shrink-0">
            <Avatar image={booking.partner?.image?.url ?? ''} />
          </div>
          <div className="text-darkBlue ml-3 md:ml-5">
            <div className="text-p18 sm:text-p20 md:text-p22 lg:text-p24 xl:text-p28">
              <span className="font-bold">{booking.partner?.firstName}</span> from {booking.partner?.businessName}
            </div>
            {!booking.amITheSupportedPerson && booking.supportedPerson && (
              <div className="text-p16 md:text-p18 lg:text-p20">
                For {booking.supportedPerson?.firstName} {booking.supportedPerson?.surname}
              </div>
            )}
            <div className="text-p16 md:text-p18 lg:text-p20">{booking.product?.name}</div>
            <div className="flex flex-row items-center mt-4">
              <CalendarDaysIcon className="w-6 h-6 text-darkBlue" />
              <div className="text-p16 lg:text-p18 ml-2">{format(booking.startDateTime, 'do MMMM yyyy')}</div>
            </div>
          </div>
        </div>
        <button
          className={
            isPastBooking
              ? 'bg-secondary text-darkBlue text-p20 md:text-p24 font-bold px-6 py-3.5 rounded-xl w-full md:w-fit cursor-pointer'
              : 'bg-darkBlue text-white text-p20 md:text-p24 font-bold px-6 py-3.5 rounded-xl w-full md:w-fit cursor-pointer'
          }
          onClick={() => {
            if (isPastBooking) {
              if (!booking.amITheSupportedPerson && booking.supportedPerson) {
                localStorage.setItem('supportedPerson', JSON.stringify(booking.supportedPerson));
              } else {
                localStorage.removeItem('supportedPerson');
              }
              localStorage.setItem('address', JSON.stringify(booking.address));
              localStorage.setItem('productName', booking.product?.name ?? '');
              localStorage.setItem('productId', booking.product?.id ?? '');
              localStorage.setItem('category', booking.category?.name ?? '');
              localStorage.setItem('categoryId', booking.product?.categoryId ?? '');
              localStorage.setItem('postcode', booking.address?.postcode ?? '');
              window.location.href = `/partner?product=${booking.product?.id}&id=${booking.partner?.id}`;
            } else {
              setShowBooking(true);
            }
          }}
        >
          {isPastBooking ? 'Book again' : 'View booking'}
        </button>
      </div>
      {showBooking && <BookingModal booking={booking} onClose={() => setShowBooking(false)} />}
    </>
  );
};

export default BookingPreview;
