import { getStoryblokApi } from '@storyblok/react';
import { GET_POSTCODE_LOOKUP } from 'api';
import { useCreateCustomer } from 'api/hooks/useCreateCustomer';
import { Auth } from 'aws-amplify';
import Loading from 'components/Loading';
import SignupForm from 'components/SignupForm';
import React, { useEffect, useState } from 'react';
import { BookStoryblok } from 'types/components-sb';
import { ToastStatus } from 'types/enum';
import { displayToast } from 'utils/utils';

interface SignupProps {
  onClose: () => void;
  setIsSignUp: (value: boolean) => void;
}

const Signup = ({ onClose, setIsSignUp }: SignupProps) => {
  const { createCustomer, mutationCreateCustomer } = useCreateCustomer({
    email: '',
    firstName: '',
    surname: '',
    phoneNumber: '',
    password: '',
  });
  const [customerFirstName, setCustomerFirstName] = useState<string>('');
  const [customerLastName, setCustomerLastName] = useState<string>('');
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const [addressNotes, setAddressNotes] = useState<string>('');
  const [otherInformations, setOtherInformations] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [blok, setBlok] = useState<BookStoryblok>();
  const [isSignedUp, setIsSignedUp] = useState(false);

  const storyblokApi = getStoryblokApi();

  useEffect(() => {
    const getSignUpSBData = async () => {
      const { data } = await storyblokApi.get('cdn/stories/book', { version: 'draft' });
      setBlok(data.story.content);
    };
    getSignUpSBData();
  }, [storyblokApi]);

  const isSignUpValid = async () => {
    if (
      customerFirstName === '' ||
      customerLastName === '' ||
      customerEmail === '' ||
      customerPhone === '' ||
      addressLine1 === '' ||
      city === '' ||
      region === '' ||
      postcode === ''
    ) {
      displayToast(ToastStatus.ERROR, 'Please fill all the required fields.');
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customerEmail)) {
      displayToast(ToastStatus.ERROR, 'Please enter a valid email address.');
      return false;
    }
    const res = await GET_POSTCODE_LOOKUP(postcode);
    if (res.status !== 200) {
      displayToast(ToastStatus.ERROR, res.error);
      return false;
    }
    if (!/[A-Z]/.test(password) || !/\d/.test(password) || password.length < 8) {
      displayToast(ToastStatus.ERROR, 'The password must have a capital letter, a number and be at least 8 characters.');
      return false;
    }
    if (password !== passwordConfirmation) {
      displayToast(ToastStatus.ERROR, 'The confirmation password is different than the password.');
      return false;
    }
    return true;
  };

  const signUp = async () => {
    const isValid = await isSignUpValid();
    if (!isValid) {
      return;
    }

    const input = {
      firstName: customerFirstName,
      surname: customerLastName,
      email: customerEmail,
      password,
      phoneNumber: customerPhone,
      address: {
        addressLine1,
        addressLine2,
        city,
        region,
        postcode,
        addressNotes,
      },
      otherInformations,
    };
    const res = await createCustomer({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    const user = await Auth.signIn(customerEmail, password);
    if (user && user.username) {
      const session = await Auth.currentSession();
      const accessToken = session.getIdToken();
      const jwt = accessToken.getJwtToken();
      localStorage.setItem('token', jwt);
      localStorage.setItem('userId', user.username);
      setIsSignedUp(true);
    }
  };

  return (
    <div>
      {isSignedUp ? (
        <>
          <div className="text-p24 lg:text-p28 xl:text-h4 font-bold text-center text-darkBlue mt-2" data-cy="welcome-text">
            Welcome to Thrive!
          </div>
          <div className="text-p18 md:text-p20 lg:text-p22 text-center text-darkBlue">Thank you for registering for a Thrive account. </div>
          <button
            className="bg-secondary px-6 py-2.5 rounded-xl w-full cursor-pointer text-darkBlue text-p24 font-bold mt-7"
            onClick={onClose}
            data-cy="make-first-booking-button"
          >
            Make your first booking
          </button>
        </>
      ) : (
        <>
          <div className="text-p24 lg:text-p28 xl:text-h4 font-bold text-center text-darkBlue mt-2">Never used Thrive before?</div>
          <div className="text-p18 md:text-p20 lg:text-p22 text-center text-darkBlue">If you don't have a thrive account, register now</div>
          <div className="text-p18 md:text-p20 lg:text-p22 text-center text-darkBlue mt-4">
            If you already have a thrive account,{' '}
            <button className="text-darkBlue font-bold underline w-fit" onClick={() => setIsSignUp(false)}>
              Sign in
            </button>
          </div>

          {blok && (
            <SignupForm
              blok={blok}
              customerFirstName={customerFirstName}
              customerLastName={customerLastName}
              customerEmail={customerEmail}
              customerPhone={customerPhone}
              addressNotes={addressNotes}
              otherInformations={otherInformations}
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              city={city}
              region={region}
              postcode={postcode}
              password={password}
              passwordConfirmation={passwordConfirmation}
              setCustomerFirstName={setCustomerFirstName}
              setCustomerLastName={setCustomerLastName}
              setCustomerEmail={setCustomerEmail}
              setCustomerPhone={setCustomerPhone}
              setAddressNotes={setAddressNotes}
              setOtherInformations={setOtherInformations}
              setAddressLine1={setAddressLine1}
              setAddressLine2={setAddressLine2}
              setCity={setCity}
              setRegion={setRegion}
              setPostcode={setPostcode}
              setPassword={setPassword}
              setPasswordConfirmation={setPasswordConfirmation}
            />
          )}
          <div className="flex justify-end">
            <button
              className="bg-secondary px-6 py-2.5 rounded-xl sm:w-fit w-full cursor-pointer text-darkBlue text-p24 font-bold mt-7"
              onClick={signUp}
              disabled={mutationCreateCustomer.loading}
              data-cy="register-button"
            >
              {mutationCreateCustomer.loading ? <Loading isComponent /> : 'Register'}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Signup;
