import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import SearchHeader from 'components/SearchHeader';
import { useEffect, useState } from 'react';
import { PreQualifyingQuestionType } from 'types/types';
import ContactBox from 'components/ContactBox';
import { CategoryQuestionsStoryblok } from 'types/components-sb';

interface CategoryQuestionsProps {
  blok: CategoryQuestionsStoryblok;
}

const CategoryQuestions = ({ blok }: CategoryQuestionsProps) => {
  const categoryId = localStorage.getItem('categoryId');
  const postcode = localStorage.getItem('postcode');
  const category = localStorage.getItem('category');
  const productId = localStorage.getItem('productId');
  const partner = localStorage.getItem('partner');
  const startTime = localStorage.getItem('availabilitySlotStartDateTime');
  const endTime = localStorage.getItem('availabilitySlotEndDateTime');

  const [questions, setQuestions] = useState<PreQualifyingQuestionType[]>([]);

  useEffect(() => {
    if (!categoryId || !category || !productId || !partner || !startTime || !endTime || !postcode) {
      window.location.href = '/';
    }
  }, [category, categoryId, endTime, partner, postcode, productId, startTime]);

  const onQuestionChange = (question: string, value: string, isMultiple: boolean) => {
    if (questions.find((q: PreQualifyingQuestionType) => q.question === question)) {
      const newQuestions = [...questions];
      const i = newQuestions.findIndex((q: PreQualifyingQuestionType) => q.question === question);
      if (isMultiple) {
        if (newQuestions[i].answers.includes(value)) {
          newQuestions[i].answers = newQuestions[i].answers.filter((a) => a !== value);
        } else {
          newQuestions[i].answers.push(value);
        }
      } else {
        newQuestions[i].answers = [value];
      }
      setQuestions(newQuestions);
    } else {
      setQuestions([...questions, { question, answers: [value] }]);
    }
  };

  const onBookClick = () => {
    localStorage.setItem('questions', JSON.stringify(questions));
    window.location.href = '/book';
  };

  return (
    <div {...storyblokEditable(blok)}>
      <SearchHeader text="Your search for" boldText={category ?? ''} />
      <div className="px-4 md:px-[5%] lg:px-[10%] xl:px-[15%] mt-7" data-cy="category-questions-container">
        <div className="text-darkBlue font-bold leading-8 text-p28 md:text-h4 xl:text-h3 2xl:text-h2">Tell us a bit more about the service you require</div>
        <div className="text-darkBlue mt-4 leading-normal text-p16 md:text-p17 lg:text-p20">Please answer the questions below.</div>
        <div className="grid grid-cols-1 gap-8 mt-16">
          {blok.preQualifyingQuestions?.map((nestedBlok: SbBlokData) => {
            return (
              <StoryblokComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
                onChange={(_: string, question: string, value: string, isMultiple: boolean) => onQuestionChange(question, value, isMultiple)}
              />
            );
          })}
        </div>
        <div className="h-px bg-darkBlue w-full hidden xl:block mt-14" />
        <div className="flex justify-end mt-10">
          <button
            onClick={onBookClick}
            className="bg-secondary px-6 py-3.5 rounded-xl w-full sm:w-fit cursor-pointer"
            data-cy="category-questions-book-appointment-button"
          >
            <div className="flex items-center justify-center text-darkBlue">
              <div className="text-p20 md:text-p24 font-bold">Book appointment</div>
              <ArrowRightIcon className="h-7 w-7 ml-3 mt-1" />
            </div>
          </button>
        </div>
        <ContactBox />
      </div>
    </div>
  );
};

export default CategoryQuestions;
