const config = {
  aws_appsync_region: 'eu-west-2',
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: process.env.REACT_APP_USERPOOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOL_CLIENT,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_API_URL,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default config;
