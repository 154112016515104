import React from 'react';
import { HowWeWorkStoryblok } from 'types/components-sb';

interface HowWeWorkProps {
  blok: HowWeWorkStoryblok;
  isReverse: boolean;
}

const HowWeWork = ({ blok, isReverse }: HowWeWorkProps) => {
  return (
    <div className={`flex lg:gap-20 xl:gap-30 2xl:gap-40 ${isReverse ? 'flex-row-reverse' : 'flex-row'} items-center`}>
      <div className={`hidden lg:flex ${isReverse ? 'justify-start' : 'justify-end'} w-1/2`}>
        <img
          src={blok.image?.filename}
          alt={blok.image?.alt}
          className={`${isReverse ? 'rounded-tr-[300px] rounded-bl-[100px]' : 'rounded-bl-[300px] rounded-tr-[100px]'}`}
        />
      </div>
      <div className={`lg:w-1/2 flex flex-col items-center ${isReverse ? 'lg:items-end' : 'lg:items-start'}`}>
        <img src={blok.logo?.filename} alt={blok.logo?.alt} className="" />
        <div className={`text-darkBlue mt-7 text-center ${isReverse ? 'lg:text-right' : 'lg:text-left'}`}>
          <div className="text-h4 lg:text-h3 xl:text-h2">{blok.title}</div>
          <div className="text-p18 md:text-p20 mt-2">{blok.description}</div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
