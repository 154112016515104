import { toast } from 'react-toastify';
import { ToastStatus } from 'types/enum';

export const isEven = (value: number): boolean => {
  return value % 2 === 0;
};

export const displayToast = (status: ToastStatus, message: string) => {
  if (status === ToastStatus.ERROR) {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  } else {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
};

export const hasPasswordCapitalLetter = (password: string) => {
  if (/[A-Z]/.test(password)) {
    return true;
  }
  return false;
};

export const hasPasswordNumber = (password: string) => {
  if (/\d/.test(password)) {
    return true;
  }
  return false;
};

export const hasPasswordAtLeast8Chars = (password: string) => {
  if (password.length >= 8) {
    return true;
  }
  return false;
};

export const getGridColsSize = (size: number) => {
  switch (size) {
    case 1:
      return 'grid-cols-1';
    case 2:
      return 'grid-cols-1 lg:grid-cols-2';
    case 3:
      return 'grid-cols-1 lg:grid-cols-3';
    case 4:
      return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4';
    case 5:
      return 'grid-cols-1 lg:grid-cols-5';
    case 6:
      return 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6';
    default:
      return 'lg:grid-cols-3';
  }
};

export const getGridGap = (size: number) => {
  switch (size) {
    case 0:
      return '';
    case 1:
      return 'gap-1';
    case 2:
      return 'gap-2';
    case 3:
      return 'gap-3';
    case 4:
      return 'gap-4';
    case 5:
      return 'gap-5';
    case 6:
      return 'gap-3 lg:gap-6';
    case 7:
      return 'gap-3 lg:gap-7';
    case 8:
      return 'gap-4 lg:gap-8';
    case 9:
      return 'gap-4 lg:gap-9';
    case 10:
      return 'gap-5 lg:gap-10';
    default:
      return 'gap-5';
  }
};

export const getFlexSize = (size: number) => {
  switch (size) {
    case 1:
      return 'w-full';
    case 2:
      return 'w-full lg:w-1/2';
    case 3:
      return 'w-full lg:w-1/3';
    case 4:
      return 'w-full lg:w-1/4';
    default:
      return 'w-full';
  }
};
