import { getStoryblokApi } from '@storyblok/react';
import { useEffect, useState } from 'react';
import { ButtonStoryblok, FooterStoryblok, SocialMediaStoryblok } from 'types/components-sb';

const Footer = () => {
  const [blok, setBlok] = useState<FooterStoryblok>();
  const storyblokApi = getStoryblokApi();

  useEffect(() => {
    const getHeaderSbData = async () => {
      const { data } = await storyblokApi.get('cdn/stories/footer', { version: 'draft' });
      setBlok(data.story.content);
    };
    getHeaderSbData();
  }, [storyblokApi]);

  return (
    <div className="flex flex-col xl:flex-row items-center xl:items-stretch xl:justify-between mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20  mt-[40px] xl:mt-[176px] mb-[120px] gap-[56px] xl:gap-[76px]">
      <div className="h-px w-full bg-darkBlue xl:hidden" />
      <div className="flex flex-col xl:justify-between">
        <div>
          <img src={blok?.logo?.filename} alt="thrive logo" />
          <div className="text-p20 text-textBlue mt-10 hidden xl:block">{blok?.address}</div>
        </div>
        <div className="flex-row gap-2 text-p20 text-textBlue hidden xl:flex">
          <div>{blok?.credits}</div>
          <div>|</div>
          {blok?.privacyPolicy && (
            <a
              href={blok.privacyPolicy[0]?.link?.cached_url}
              className="text-textBlue text-p20 group transition duration-300 w-max text-center justify-self-center xl:justify-self-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              {blok.privacyPolicy[0].text}
              <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
            </a>
          )}
        </div>
      </div>
      <div className="w-px bg-darkBlue mt-20 hidden xl:block" />
      <div>
        <div className="text-textBlue text-p24 font-bold mt-3 text-center xl:text-left">{blok?.navigationTitle}</div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-4 xl:gap-y-2 gap-x-10 mt-4 xl:mt-10 w-max">
          {blok?.navigation?.map((nestedBlok: ButtonStoryblok, index: number) => {
            return (
              <a
                href={nestedBlok.link?.linktype === 'url' ? nestedBlok?.link?.cached_url : `/${nestedBlok?.link?.cached_url}`}
                className="text-textBlue text-p20 group transition duration-300 w-max text-center justify-self-center xl:justify-self-auto"
                key={index}
              >
                {nestedBlok.text}
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
              </a>
            );
          })}
        </div>
      </div>
      <div className="w-px bg-darkBlue mt-20 hidden xl:block" />
      <div className="flex flex-col justify-between">
        <div className="flex flex-col items-center xl:items-start gap-y-4 xl:gap-y-2">
          <div className="text-textBlue text-p24 font-bold mt-3 xl:mb-6 text-center xl:text-left">{blok?.contact}</div>
          <a href={`tel:+44${blok?.phone}`} className="text-textBlue text-p20 group transition duration-300 w-max text-center">
            {blok?.phone}
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
          </a>
          <a href={`mailto:${blok?.email}`} className="text-textBlue text-p20 group transition duration-300 w-max text-center">
            {blok?.email}
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
          </a>
        </div>
        <div className="flex justify-center xl:justify-start mt-4 gap-4">
          {blok?.socialMedias?.map((nestedBlok: SocialMediaStoryblok, index: number) => {
            return (
              <a href={nestedBlok?.url?.cached_url} target="_blank" rel="noopener noreferrer" key={index}>
                <img src={nestedBlok?.icon?.filename} alt="social media logo" />
              </a>
            );
          })}
        </div>
      </div>
      <div className="text-p20 text-textBlue xl:hidden text-center xl:text-left">{blok?.address}</div>
      <div className="text-p20 text-textBlue xl:hidden text-center xl:text-left">{blok?.credits}</div>
      {blok?.privacyPolicy && (
        <a
          href={blok.privacyPolicy[0]?.link?.cached_url}
          className="text-textBlue text-p20 group transition duration-300 w-max text-center justify-self-center xl:hidden"
          target="_blank"
          rel="noopener noreferrer"
        >
          {blok.privacyPolicy[0].text}
          <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-secondary" />
        </a>
      )}
    </div>
  );
};

export default Footer;
