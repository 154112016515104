import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import { ButtonStoryblok } from 'types/components-sb';

interface ButtonProps {
  blok: ButtonStoryblok;
}

const Button = ({ blok }: ButtonProps) => {
  return (
    <a
      href={blok.link?.cached_url ?? ''}
      target={blok?.link?.target}
      rel={blok?.link?.target ? 'noopener noreferrer' : ''}
      style={{
        border: blok.mobileDisplay && window.innerWidth < 1024 ? 'none' : blok.border,
        backgroundColor: blok.mobileDisplay && window.innerWidth < 1024 ? 'transparent' : blok.backgroundColor,
        color: blok.mobileDisplay && window.innerWidth < 1024 ? '#145F6F' : blok.textColor,
      }}
      className={`${blok.padding ? 'px-5 py-2' : ''} rounded-lg ${blok.size} ${blok.font} ${blok.underline ? 'underline' : ''}`}
      {...storyblokEditable(blok)}
      suppressHydrationWarning={true}
      id={blok.ctaId}
    >
      {blok.text}
    </a>
  );
};

export default Button;
