import { HowItWorksStepStoryblok } from 'types/components-sb';

interface HowItWorksStepProps {
  blok: HowItWorksStepStoryblok;
  isBlue: boolean;
  index: number;
}

const HowItWorksStep = ({ blok, isBlue, index }: HowItWorksStepProps) => {
  return (
    <div
      className={`px-4 py-7 md:px-[5%] ${
        isBlue ? 'bg-bgLightBlue lg:flex-row' : 'bg-white lg:flex-row-reverse'
      } flex flex-col items-center gap-4 justify-between`}
    >
      <div className={`${isBlue ? 'lg:mr-10' : 'lg:ml-10'} lg:w-1/2 flex flex-col justify-center items-center lg:justify-start lg:items-start`}>
        <div className={`${isBlue ? 'bg-secondary' : 'bg-primary'} text-white rounded-full w-16 h-16 flex justify-center items-center text-h3 text-bold`}>
          {index + 1}
        </div>
        <div className="text-darkBlue mt-7 text-center lg:text-left">
          <div className="text-h4 lg:text-h3 xl:text-h2">{blok.title}</div>
          <div className="text-p18 md:text-p20">{blok.description}</div>
        </div>
      </div>
      <div className="flex items-center gap-4 shrink-0 mt-4 lg:mt-0">
        <img src={blok.images[0].filename} alt={blok.images[0].alt} className="rounded-lg" />
      </div>
      {blok.images.length > 1 && (
        <div className="hidden md:flex flex-row lg:flex-col gap-4 shrink-0">
          {blok.images.length > 1 && <img src={blok.images[1].filename} alt={blok.images[1].alt} className="rounded-lg" />}
          {blok.images.length > 2 && <img src={blok.images[2].filename} alt={blok.images[2].alt} className="rounded-lg" />}
        </div>
      )}
    </div>
  );
};

export default HowItWorksStep;
