import Phone from './Phone';

const ContactBox = () => {
  return (
    <div className="text-center text-darkBlue bg-bgBlue rounded-2xl flex flex-col items-center justify-center p-8 md:py-14 mt-24 w-full">
      <div className="font-bold text-p24 md:text-p28 lg:text-h4 xl:text-h3">Not sure what you need?</div>
      <div className="text-p18 md:text-p20 mb-8">Chat through with our bookings team:</div>
      <Phone />
    </div>
  );
};

export default ContactBox;
