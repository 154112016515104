import { useQuery, gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query GetProducts($args: ProductListSearch) {
    GetProducts(args: $args) {
      id
      categoryId
      name
      description
    }
  }
`;

const useGetProducts = (categoryId: string) => {
  const {
    loading: loadingProducts,
    data: products,
    error: errorProducts,
    refetch,
  } = useQuery(GET_PRODUCTS, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      args: {
        categoryId,
      },
    },
  });

  return {
    loadingProducts,
    products,
    errorProducts,
    refetch,
  };
};

export { useGetProducts };
