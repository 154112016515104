/* eslint-disable react/no-danger */
import { CheckIcon, CalendarDaysIcon, ClockIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { renderRichText } from '@storyblok/react';
import Avatar from 'components/Avatar';
import ContactBox from 'components/ContactBox';
import Loading from 'components/Loading';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { BookingConfirmationStoryblok } from 'types/components-sb';

interface BookingConfirmationProps {
  blok: BookingConfirmationStoryblok;
}

const BookingConfirmation = ({ blok }: BookingConfirmationProps) => {
  const booking = localStorage.getItem('bookingConfirmation') ? JSON.parse(localStorage.getItem('bookingConfirmation') ?? '') : undefined;

  useEffect(() => {
    if (!booking) {
      window.location.href = '/';
    } else {
      localStorage.removeItem('category');
      localStorage.removeItem('categoryId');
      localStorage.removeItem('postcode');
      localStorage.removeItem('productId');
      localStorage.removeItem('productName');
      localStorage.removeItem('partner');
      localStorage.removeItem('availabilitySlotStartDateTime');
      localStorage.removeItem('availabilitySlotEndDateTime');
      localStorage.removeItem('questions');
    }
  }, [booking]);

  if (!booking) {
    return <Loading />;
  }

  return (
    <div className="mt-7 md:mt-14 px-4 md:px-[5%] lg:px-[10%] xl:px-[15%]">
      <div className="flex flex-col items-center justify-center">
        <div className="border border-green rounded-full w-[88px] h-[88px] p-0.5">
          <div className="bg-green w-full h-full rounded-full flex items-center justify-center">
            <CheckIcon className="w-12 h-12 text-white" />
          </div>
        </div>
      </div>
      <div className="text-p28 lg:text-h2 font-bold text-darkBlue mt-4 text-center" data-cy="booking-confirmation-title">
        {blok.title}
      </div>
      <div dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }} className="text-darkBlue text-p16 md:text-p18 lg:text-p20 text-center" />
      <div className="bg-bgYellow15 p-4 lg:p-12 rounded-xl mt-12">
        <div className="bg-primary text-white rounded-xl p-4 lg:p-7">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="w-[134px] h-[134px] lg:w-[158px] lg:h-[158px] rounded-full p-0.5 border-4 border-secondary shrink-0">
              <Avatar image={booking?.partnerAvatar ?? ''} />
            </div>
            <div className="ml-5 w-full mt-6 lg:mt-0">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center w-full">
                <div className="text-p20 lg:text-p28 text-center lg:text-left" data-cy="booking-confirmation-partner-name">
                  <span className="font-bold">{booking?.partnerFirstName}</span> from {booking?.partnerBusinessName}
                </div>
                <div className="text-p18 text-center hidden lg:block" data-cy="booking-confirmation-price">
                  Cost of service: <span className="font-bold text-p28">£{booking?.price}</span>
                </div>
              </div>
              <div className="flex flex-row items-center mt-4 justify-center lg:justify-start">
                <CalendarDaysIcon className="w-6 h-6 text-white" />
                <div className="text-p16 lg:text-p18 ml-2" data-cy="booking-confirmation-date">
                  {format(booking.startTime ?? 0, 'do MMMM yyyy')}
                </div>
              </div>
              <div className="flex flex-row items-center mt-2 lg:mt-3 justify-center lg:justify-start">
                <ClockIcon className="w-6 h-6 text-white" />
                <div className="text-p16 lg:text-p18 ml-2" data-cy="booking-confirmation-time">
                  {format(booking.startTime ?? 0, 'h:mm aaa')}
                </div>
              </div>
              <div className="text-p16 lg:text-p18 text-center lg:hidden mt-4">
                Cost of service: <span className="font-bold text-p28">£{booking?.price}</span>
              </div>
            </div>
          </div>
          <div className="h-px w-full bg-[#81C8FA] my-6" />
          <div className="mt-2.5 flex flex-col md:flex-row items-center">
            <InformationCircleIcon className="w-12 h-12 text-white shrink-0" />
            <div className="text-p16 lg:text-p18 mt-2 md:mt-0 md:ml-3 text-center md:text-left">{blok.informationText}</div>
          </div>
        </div>
      </div>
      <div className="w-full h-px bg-darkBlue mt-24 hidden lg:block" />
      <ContactBox />
    </div>
  );
};

export default BookingConfirmation;
