import { useCookies } from 'react-cookie';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['localConsent']);

  const acceptCookie = () => {
    setCookie('localConsent', 'true', {});
  };

  if (cookies.localConsent) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 flex flex-col md:flex-row items-center justify-center md:justify-between items-center px-4 md:px-16 py-4 md:py-8 bg-darkBlue z-50">
      <span className="text-white text-base mb-2 md:mb-0 md:mr-16 text-center md:text-left text-p14 md:text-p16 lg:text-p18 xl:text-p20">
        This website uses cookies to improve user experience. In using our website, you consent to all cookies in accordance with to our cookie policy.
      </span>
      <button className="bg-white py-2 px-8 rounded-xl text-darkBlue font-bold z-10 text-p14 md:text-p16 lg:text-p18" onClick={() => acceptCookie()}>
        Accept
      </button>
    </div>
  );
};

export default CookieConsent;
