import { LocalButtonBlokStoryblok } from 'types/components-sb';

interface LocalButtonBlokProps {
  blok: LocalButtonBlokStoryblok;
}

const LocalButtonBlok = ({ blok }: LocalButtonBlokProps) => {
  return (
    <div className="bg-bgBlue p-7 rounded-xl ">
      <div className="text-darkBlue text-h3 font-bold text-center md:text-left">{blok.title}</div>
      <div className="text-darkBlue text-p20 font-bold mt-5 text-center md:text-left">{blok.description}</div>
      <div className="flex flex-col-reverse md:flex-row justify-between items-center md:items-end">
        <a
          href={blok.buttonLink?.cached_url}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-secondary text-darkBlue font-bold text-p22 flex justify-center items-center px-4 py-3.5 rounded-xl w-full md:w-fit"
        >
          {blok.buttonText}
        </a>
        <img src={blok.icon?.filename} alt={blok.icon?.alt ?? ''} className="w-fit" />
      </div>
    </div>
  );
};

export default LocalButtonBlok;
