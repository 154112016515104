import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import React from 'react';
import { GridStoryblok } from 'types/components-sb';
import { getGridColsSize, getGridGap } from 'utils/utils';

interface GridProps {
  blok: GridStoryblok;
  selectedTopics?: string[];
}

const Grid = ({ blok, selectedTopics }: GridProps) => {
  return (
    <div
      className={`grid ${getGridColsSize(parseInt(blok.size ?? '3', 10))} ${getGridGap(parseInt(blok.gap ?? '5', 10))} ${
        blok.noVerticalMarginMobile ? 'my-0' : 'my-5'
      } lg:my-10 ${blok.itemsStart ? 'items-start' : 'items-center'} ${blok.shadow ? 'shadow-dropShadow' : ''}`}
      {...storyblokEditable(blok)}
    >
      {blok.content?.map((nestedBlok, index) => {
        return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} selectedTopics={selectedTopics} />;
      })}
    </div>
  );
};

export default Grid;
