import { gql, useMutation } from '@apollo/client';
import { EmailInput } from '__generated__/graphql';

export const SEND_EMAIL = gql`
  mutation SendEmail($input: EmailInput) {
    SendEmail(input: $input) {
      template
      subject
      to
    }
  }
`;

const useSendEmail = (query: EmailInput) => {
  const [sendEmail, mutationSendEmail] = useMutation(SEND_EMAIL, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      query: { ...query },
    },
  });

  return { sendEmail, mutationSendEmail };
};

export { useSendEmail };
