import { ArrowLeftIcon, ArrowRightIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Avatar from './Avatar';

interface SearchHeaderProps {
  text: string;
  boldText?: string;
  avatar?: string;
  partnersNumber?: number;
  index?: number;
  onIndexChange?: (index: number) => void;
}

const SearchHeader = ({ text, boldText, avatar, partnersNumber, index, onIndexChange }: SearchHeaderProps) => {
  return (
    <div
      className={
        partnersNumber !== undefined
          ? 'bg-bgYellow p-4 md:px-[5%] lg:px-[10%] xl:px-[15%] sm:py-6 flex items-center justify-center md:justify-between'
          : 'bg-bgYellow p-4 md:px-[5%] lg:px-[10%] xl:px-[15%] sm:py-6 flex items-center justify-between'
      }
    >
      <div className={partnersNumber !== undefined ? 'hidden md:flex items-center' : 'flex items-center'}>
        {avatar ? (
          <div className="w-[64px] h-[64px] rounded-full p-0.5 border-2 border-primary">
            <Avatar image={avatar} />
          </div>
        ) : (
          <div className="bg-primary rounded-full w-12 h-12 sm:w-14 sm:h-14 flex items-center justify-center">
            <MagnifyingGlassIcon className="text-white h-6 w-6 sm:h-7 sm:w-7" />
          </div>
        )}

        <div className="text-darkBlue text-p20 sm:text-p24 lg:text-p28 ml-4" data-testid="category-search-title">
          {text} {boldText && <span className="font-bold">{boldText}</span>}
        </div>
        <div className="bg-darkBlue h-6 w-px mx-10 hidden sm:block" />
        <MagnifyingGlassIcon className="text-darkBlue h-5 w-5 hidden sm:block" />
        <a href="/" className="text-darkBlue text-p20 ml-2 font-bold underline underline-offset-2 hidden sm:block">
          New search
        </a>
      </div>
      {partnersNumber !== undefined && index !== undefined && onIndexChange !== undefined && (
        <div className="flex items-center">
          <button
            className="bg-darkBlue w-16 h-16 rounded-xl flex items-center justify-center disabled:opacity-40"
            disabled={index === 0}
            onClick={() => onIndexChange(index - 1)}
          >
            <ArrowLeftIcon className="text-white h-8 w-8" />
          </button>
          <div className="mx-2 text-p18 text-darkBlue">
            {index + 1} of {partnersNumber}
          </div>
          <button
            className="bg-darkBlue w-16 h-16 rounded-xl flex items-center justify-center disabled:opacity-40"
            disabled={index + 1 === partnersNumber}
            onClick={() => onIndexChange(index + 1)}
          >
            <ArrowRightIcon className="text-white h-8 w-8" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchHeader;
