import React, { ReactNode } from 'react';
import { ButtonVariantState } from '../types/enum';

interface ButtonProps {
  variant: ButtonVariantState;
  onClick: () => void;
  children: ReactNode;
}

const Button = ({ variant, onClick, children }: ButtonProps) => {
  return (
    <button onClick={onClick} className={variant === ButtonVariantState.Contained ? 'bg-darkBlue px-3 py-2 rounded-xl' : 'text-textBlue'}>
      {children}
    </button>
  );
};

export default Button;
