import { gql, useMutation } from '@apollo/client';
import { GET_CUSTOMER } from './useGetCustomer';

export const EDIT_SUPPORTED_PERSON = gql`
  mutation EditSupportedPerson($input: EditSupportedPersonInput) {
    EditSupportedPerson(input: $input) {
      id
      message
      date
    }
  }
`;

const useEditSupportedPerson = (id: string) => {
  const jwt = localStorage.getItem('token');

  const [editSupportedPerson, mutationEditSupportedPerson] = useMutation(EDIT_SUPPORTED_PERSON, {
    context: {
      headers: {
        Authorization: jwt || null,
      },
    },
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        context: {
          headers: {
            Authorization: jwt,
          },
        },
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return { editSupportedPerson, mutationEditSupportedPerson };
};

export { useEditSupportedPerson };
