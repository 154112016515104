export enum ButtonVariantState {
  Outlined = 'Outlined',
  Contained = 'Contained',
  Text = 'Text',
}

export enum ContactQuestionType {
  EMAIL = 'Email',
  TEXT = 'Text',
  RADIO = 'Radio',
}

export enum Category {
  NailTechnicians = 'Nail Technicians',
  Hairdressers = 'Hairdressers',
  Chiropodists = 'Chiropodists',
  Housekeepers = 'Housekeepers',
  Physiotherapists = 'Physiotherapists',
}

export enum ToastStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}
