import { LoadScriptProps, useLoadScript, Autocomplete } from '@react-google-maps/api';
import Loading from 'components/Loading';
import React, { useState } from 'react';

interface AddressFormProps {
  addressLine1: string;
  addressLine2: string;
  postcode: string;
  city: string;
  region: string;
  addressNotes: string;
  setAddressLine1: (value: string) => void;
  setAddressLine2: (value: string) => void;
  setPostcode: (value: string) => void;
  setCity: (value: string) => void;
  setRegion: (value: string) => void;
  setAddressNotes: (value: string) => void;
  isPostcodeDisabled?: boolean;
}

const AddressForm = ({
  addressLine1,
  addressLine2,
  postcode,
  city,
  region,
  addressNotes,
  setAddressLine1,
  setAddressLine2,
  setPostcode,
  setRegion,
  setCity,
  setAddressNotes,
  isPostcodeDisabled = false,
}: AddressFormProps) => {
  const [libraries] = useState<LoadScriptProps['libraries']>(['places']);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  });
  const [searchResults, setSearchResult] = useState<google.maps.places.Autocomplete>();

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setSearchResult(autocomplete);
  };

  const addressTypeAssignment = (addressComponents: google.maps.GeocoderAddressComponent[] | undefined) => {
    addressComponents?.forEach((component) => {
      const componentType = component.types[0];
      if (componentType === 'street_number' || componentType === 'premise') {
        setAddressLine1(component.long_name);
      } else if (componentType === 'route') {
        setAddressLine2(component.long_name);
      } else if (componentType === 'postal_code' && !isPostcodeDisabled) {
        setPostcode(component.short_name);
      } else if (componentType === 'postal_town') {
        setCity(component.long_name);
      } else if (componentType === 'administrative_area_level_2') {
        setRegion(component.long_name);
      }
    });
  };

  const onPlaceChanged = () => {
    if (searchResults != null) {
      const addressObject = searchResults.getPlace();
      const googleAddress = addressObject.address_components;
      addressTypeAssignment(googleAddress);
    }
  };

  if (!isLoaded) return <Loading isComponent />;

  return (
    <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad} types={['street_address', 'premise']} restrictions={{ country: 'gb' }}>
      <>
        <div className="flex flex-col mt-5">
          <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Search an address</div>
          <input
            type="text"
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
            data-cy="account-search-address"
          />
        </div>
        <div className="flex flex-col mt-5">
          <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Address Line 1 *</div>
          <input
            type="text"
            value={addressLine1}
            onChange={(event) => setAddressLine1(event.target.value)}
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
            data-cy="account-update-address-line-1"
          />
        </div>
        <div className="flex flex-col mt-5">
          <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Address Line 2</div>
          <input
            type="text"
            value={addressLine2}
            onChange={(event) => setAddressLine2(event.target.value)}
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
            data-cy="account-update-address-line-2"
          />
        </div>
        <div className="flex flex-col mt-5">
          <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">City *</div>
          <input
            type="text"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
            data-cy="account-update-address-city"
          />
        </div>
        <div className="flex flex-col mt-5">
          <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Region *</div>
          <input
            type="text"
            value={region}
            onChange={(event) => setRegion(event.target.value)}
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
            data-cy="account-update-address-region"
          />
        </div>
        <div className="flex flex-col mt-5">
          <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Postcode *</div>
          {isPostcodeDisabled && <div className="text-p16 text-warning font-bold">Please start a new search if you want to update the postcode.</div>}
          <input
            type="text"
            value={postcode}
            onChange={(event) => setPostcode(event.target.value)}
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
            data-cy="account-update-address-postcode"
            disabled={isPostcodeDisabled}
          />
        </div>
        <div className="flex flex-col mt-5">
          <div className="text-p18 md:text-p20 text-darkBlue font-bold w-full">Address Notes</div>
          <input
            type="text"
            value={addressNotes}
            onChange={(event) => setAddressNotes(event.target.value)}
            className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary mt-2 h-fit"
            data-cy="account-update-address-notes"
          />
        </div>
      </>
    </Autocomplete>
  );
};

export default AddressForm;
