import { storyblokEditable } from '@storyblok/react';
import { WhyThriveStoryblok } from 'types/components-sb';
import { isEven } from 'utils/utils';

interface WhyThriveProps {
  blok: WhyThriveStoryblok;
  index: number;
}

const WhyThrive = ({ blok, index }: WhyThriveProps) => {
  return (
    <div {...storyblokEditable(blok)} className={`${isEven(index) ? 'bg-bgYellow' : 'bg-bgBlue'} rounded-2xl p-6 sm:p-8 xl:px-10 xl:py-12`}>
      <div className="flex flex-row xl:flex-col xl:justify-center items-start xl:items-center">
        <div
          className={`${
            isEven(index) ? 'bg-secondary' : 'bg-primary'
          } rounded-full p-2 w-[34px] h-[34px] sm:w-[42px] sm:h-[42px] xl:w-[64px] xl:h-[64px] flex items-center justify-center text-p20 xl:text-h4 font-bold text-white mt-2 xl:mt-0 shrink-0`}
        >
          {index + 1}
        </div>
        <div className="ml-4">
          <div className="xl:ml-0 text-left xl:text-center xl:mt-4 text-p20 sm:text-p24 lg:text-p28 xl:text-h3 text-darkBlue font-bold leading-7 xl:leading-10">
            {blok.title}
          </div>
          <div className="text-16 md:text-p18 xl:text-p20 text-left xl:text-center text-darkBlue mt-4">{blok.subtitle}</div>
        </div>
      </div>
    </div>
  );
};

export default WhyThrive;
