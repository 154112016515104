import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import React from 'react';
import { FaqThemeStoryblok } from 'types/components-sb';

interface FaqThemeProps {
  blok: FaqThemeStoryblok;
  isBlue: boolean;
}

const FaqTheme = ({ blok, isBlue }: FaqThemeProps) => {
  return (
    <div id={blok._uid}>
      <div className="text-p28 md:text-h4 lg:text-h3 xl:text-h2 text-darkBlue mb-5">{blok.title}</div>
      <div
        className={
          isBlue
            ? 'bg-bgBlue p-5 md:p-10 w-full flex justify-center items-center rounded-xl'
            : 'bg-bgYellow p-5 md:p-10 w-full flex justify-center items-center rounded-xl'
        }
      >
        <div className="grid grid-cols-1 gap-4 w-full">
          {blok.questions?.map((nestedBlok: SbBlokData) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isBlue={!isBlue} />;
          })}
          {blok?.link && blok.link[0] && (
            <div className="flex justify-center mt-5 lg:mt-10">
              <a href={blok.link[0].link?.cached_url} className="text-p20 sm:text-p24 lg:text-p28  text-darkBlue font-semibold underline text-center w-fit">
                {blok.link[0].text}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqTheme;
