import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Partner } from '__generated__/graphql';
import Avatar from 'components/Avatar';
import Rating from 'components/Rating';

interface PartnerInformationsProps {
  partner: Partner;
  reviewsRating: number;
  reviewsNumber: number;
  isFirstBooking: boolean;
}

const PartnerInformations = ({ partner, reviewsRating, reviewsNumber, isFirstBooking }: PartnerInformationsProps) => {
  const hasNoDecimals = Number.isInteger(reviewsRating);

  return (
    <div className="px-4 md:px-[5%] lg:px-[10%] xl:px-[15%] mt-10 md:mt-20 flex flex-col md:flex-row items-center justify-between">
      <div className="w-full">
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-[104px] h-[104px] rounded-full p-0.5 border-2 border-primary">
            <Avatar image={partner.image?.url ?? ''} />
          </div>
          <div className="text-darkBlue md:ml-4">
            <div className="text-p28 md:text-h4 md:text-h3 xl:text-h2 font-bold text-center md:text-left" data-testid="partner-name">
              {partner.firstName}
            </div>
            <div className="text-p22 text-center md:text-left" data-testid="partner-business">
              {partner.businessName}
            </div>
          </div>
        </div>
        <div className="bg-primary rounded-xl p-8 text-white text-p18 md:text-p24 xl:text-p28 mt-7">
          Cost for this service:{' '}
          <span className="font-bold" data-testid="partner-price">
            £
            {partner?.prices &&
              (isFirstBooking
                ? partner?.prices[0]?.firstBookingPrice?.toFixed(2).replace(/\.00$/, '')
                : partner?.prices[0]?.memberPrice?.toFixed(2).replace(/\.00$/, ''))}
          </span>
        </div>
        <div className="text-p18 text-darkBlue leading-5 mt-7" data-testid="partner-description">
          {partner.description}
        </div>
      </div>
      <div className="h-px w-full bg-darkBlue my-8 md:hidden" />
      <div className="w-full md:w-initial md:ml-[80px] lg:ml-[100px] xl:ml-[130px] 2xl:ml-[160px] md:w-fit">
        <div className="flex flex-col justify-end items-center md:items-end">
          <div className="text-darkBlue text-p18 font-bold w-max mb-4 text-center md:text-left">Loved by your community</div>
          <Rating reviewsRating={reviewsRating} hasNoDecimals={hasNoDecimals} />
          <a href="#partner-reviews" className="text-darkBlue flex flex-row items-center">
            <div className="text-p20 font-bold underline text-center md:text-left">{reviewsNumber} reviews</div>
            <ChevronRightIcon className="w-4 h-4 mt-1 ml-3" />
          </a>
        </div>
        <div className="h-px w-full bg-darkBlue my-8" />
        <div className="flex flex-row justify-center gap-4 md:justify-between items-center">
          {partner.dbsChecked && <img src="/assets/dbs-checked.png" alt="dbs checked logo" />}
          <img src="/assets/superpartner.png" alt="super partner logo" />
        </div>
      </div>
    </div>
  );
};

export default PartnerInformations;
