import { gql, useMutation } from '@apollo/client';

export const CREATE_BOOKING = gql`
  mutation CreateBooking($input: CreateBookingInput) {
    CreateBooking(input: $input) {
      message
      date
    }
  }
`;

const useCreateBooking = () => {
  const [createBooking, mutationCreateBooking] = useMutation(CREATE_BOOKING, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  });

  return { createBooking, mutationCreateBooking };
};

export { useCreateBooking };
