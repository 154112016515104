import { useState } from 'react';
import { GET_POSTCODE_LOOKUP } from 'api';
import { Category, Customer } from '__generated__/graphql';
import { ToastStatus } from 'types/enum';
import { displayToast } from 'utils/utils';
import { useGetCategories } from 'api/hooks/useGetCategories';
import ReactSelect from 'components/Select';
import { useGetCustomer } from 'api/hooks/useGetCustomer';

const PartnerSearch = () => {
  const { categories, loadingCategories } = useGetCategories();
  const [postCode, setPostCode] = useState<{ value: string; label: string }>({ value: '', label: '' });
  const [service, setService] = useState<{ value: string; label: string } | null>(null);
  const [newPostcode, setNewPostcode] = useState(false);
  const { customer }: { customer: Customer } = useGetCustomer(localStorage.getItem('userId') ?? '', !localStorage.getItem('userId'));
  const customerAddressOptions =
    (customer &&
      customer.address?.map((a) => ({
        value: a?.postcode ?? '',
        label: a?.postcode ?? '',
      }))) ??
    [];
  const supportedPeopleOptions =
    (customer &&
      customer.supportedPeople?.map((supportedPerson) => ({
        value: supportedPerson.address?.postcode ?? '',
        label: `${supportedPerson.address?.postcode} (${supportedPerson.firstName} ${supportedPerson.surname})`,
      }))) ??
    [];
  const options = categories?.GetCategories?.map((category: Category) => {
    return { value: category.id ?? '', label: category.name ?? '' };
  });

  const checkServiceIsSelected = () => {
    if (service === null) {
      displayToast(ToastStatus.ERROR, 'You must select a service.');
      return false;
    }
    return true;
  };

  const onSearch = async () => {
    if (!checkServiceIsSelected()) {
      return;
    }
    if (postCode.value === '') {
      displayToast(ToastStatus.ERROR, 'You must enter a postcode.');
      return;
    }
    const res = await GET_POSTCODE_LOOKUP(postCode.value);
    if (res.status !== 200) {
      displayToast(ToastStatus.ERROR, res.error);
      return;
    }
    let address;
    if (postCode.label.includes('(')) {
      const supportedPerson = customer.supportedPeople?.find((sp) => sp.address?.postcode === postCode.value);
      localStorage.setItem('supportedPerson', JSON.stringify(supportedPerson));
      address = supportedPerson?.address;
    } else {
      address = customer.address?.find((a) => a.postcode === postCode.value) ?? {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postcode: postCode.value,
        region: '',
        addressNotes: '',
      };
      localStorage.removeItem('supportedPerson');
    }
    localStorage.setItem('address', JSON.stringify(address));
    localStorage.setItem('category', service?.label ?? '');
    localStorage.setItem('categoryId', service?.value ?? '');
    localStorage.setItem('postcode', res.result.postcode);
    window.location.href = `/search/${service?.label.toLowerCase().replace(' ', '-')}`;
  };

  return (
    <div className="bg-primary px-3 md:px-6 py-6 md:py-10 xl:py-20 rounded-xl relative w-full mt-10 flex flex-col justify-center items-center">
      <div className="absolute top-1/2 left-0 bottom-0 right-0 bg-darkBlue opacity-10 rounded-tl-[50%] rounded-tr-[50%] rounded-b-2xl" />
      <div className="text-p28 text-white font-bold text-center" data-testid="search-title">
        Search for vetted Wellbeing Services
      </div>
      <div className="flex flex-col md:flex-row md:items-center justify-center mt-5 gap-2 z-10 w-full md:w-fit">
        <div data-testid="category-select">
          <ReactSelect options={options} onChange={setService} isLoading={loadingCategories} placeholder="What service would you like?" />
        </div>
        <div data-testid="postcode-select" className="min-w-[50%]">
          {customer && !newPostcode ? (
            <ReactSelect
              options={[...customerAddressOptions, ...supportedPeopleOptions]}
              onChange={setPostCode}
              isLoading={loadingCategories}
              placeholder="Postcode"
              testId="search-postcode-react-select"
            />
          ) : (
            <input
              data-testid="postcode-input"
              type="text"
              value={postCode.value}
              onChange={(event) => setPostCode({ value: event.target.value, label: event.target.value })}
              className="bg-white px-5 py-3.5 rounded-lg text-textBlue placeholder:text-textBlue text-p20"
              placeholder="Postcode"
              data-cy="search-postcode-input"
            />
          )}
        </div>
      </div>
      {customer && (
        <div className="flex flex-row items-center gap-3 mt-2 z-10">
          <button
            aria-label="search for a new postcode"
            className={`${newPostcode ? 'bg-secondary' : 'bg-white'} border border-white rounded-md cursor-pointer w-5 h-5 shrink-0`}
            onClick={() => setNewPostcode(!newPostcode)}
          />
          <div className="text-white text-p18 2xl:text-p20">Search with a new postcode</div>
        </div>
      )}
      <button onClick={onSearch} className="bg-secondary px-5 md:px-6 py-3.5 rounded-xl md:w-fit cursor-pointer z-[1] mt-5 w-full">
        <div className="flex items-center justify-center text-textBlue text-p18 sm:text-p20 font-semibold">Search for Wellbeing service near me</div>
      </button>
    </div>
  );
};

export default PartnerSearch;
