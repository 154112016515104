import { useQuery, gql } from '@apollo/client';

export const GET_BOOKINGS = gql`
  query GetBookings($args: BookingListSearch) {
    GetBookings(args: $args) {
      id
      isFirstBooking
      amITheSupportedPerson
      supportedPersonId
      address {
        addressLine1
        addressLine2
        city
        region
        postcode
        addressNotes
      }
      customer {
        id
        firstName
        surname
        email
        phoneNumber
        address {
          addressLine1
          addressLine2
          city
          region
          postcode
          addressNotes
        }
        supportedPeople {
          id
          firstName
          surname
          address {
            addressLine1
            addressLine2
            city
            region
            postcode
            addressNotes
          }
        }
      }
      partner {
        id
        firstName
        businessName
        description
        qualifications
        email
        dbsChecked
        vetted
        referencesReceived
        qualificationsChecked
        isActive
        phoneNumber
        image {
          id
          url
        }
        prices {
          id
          partnerId
          basePrice
          firstBookingPrice
          memberPrice
          productId
          duration
          productName
          partnerName
        }
      }
      product {
        id
        categoryId
        categoryName
        name
        description
      }
      category {
        id
        name
        storyblokSlug
      }
      partnerPrice {
        id
        partnerId
        basePrice
        firstBookingPrice
        memberPrice
        productId
        duration
        productName
        partnerName
      }
      startDateTime
      endDateTime
      isRecurring
      partnerName
      preQualifyingQuestions {
        question
        answers
      }
      supportedPerson {
        id
        firstName
        surname
        address {
          addressLine1
          addressLine2
          city
          region
          postcode
          addressNotes
        }
      }
    }
  }
`;

const useGetBookings = (customerId: string, skip: boolean) => {
  const jwt = localStorage.getItem('token');
  const {
    loading: loadingBookings,
    data: bookings,
    error: errorBookings,
    refetch,
  } = useQuery(GET_BOOKINGS, {
    context: {
      headers: {
        Authorization: jwt || null,
      },
    },
    variables: {
      args: {
        customerId,
      },
    },
    skip,
  });

  return {
    loadingBookings,
    bookings,
    errorBookings,
    refetch,
  };
};

export { useGetBookings };
