import { PartnerReview } from '__generated__/graphql';
import Rating from 'components/Rating';

interface ReviewProps {
  review: PartnerReview;
  isReviewBgBlue: boolean;
}

const Review = ({ review, isReviewBgBlue }: ReviewProps) => {
  const hasNoDecimals = Number.isInteger(review.rating);
  return (
    <div className={isReviewBgBlue ? 'bg-bgBlue p-7 rounded-xl flex flex-col justify-between' : 'bg-bgYellow p-7 rounded-xl flex flex-col justify-between'}>
      <div>
        <div className="flex flex-row justify-between items-center">
          <Rating reviewsRating={review.rating} hasNoDecimals={hasNoDecimals} small />
          <div
            className={`${
              isReviewBgBlue ? 'bg-primary' : 'bg-secondary'
            } rounded-full p-2 w-[40px] h-[40px] md:w-[56px] md:h-[56px] flex items-center justify-center`}
          >
            <img src="/assets/quote.svg" alt="quote" />
          </div>
        </div>
        <div className="text-p16 md:text-p18 lg:text-p20 xl:text-p22 text-darkBlue mt-8">{review.description}</div>
      </div>
      <div className="flex flex-row items-center text-darkBlue mt-5">
        <div className="text-p16 md:text-p18 lg:text-p20 font-bold">{review.customerName}</div>
        <div className="w-2 h-2 rounded-full bg-darkBlue mt-1 mx-3" />
        <div className="text-p16 md:text-p18 lg:text-p20">{review.customerLocation}</div>
      </div>
    </div>
  );
};

export default Review;
