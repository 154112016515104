import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { CookiesProvider } from 'react-cookie';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import Home from 'pages/Home';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { ApolloProvider } from '@apollo/client';
import { client } from 'api';
import CategoryQuestions from 'pages/CategoryQuestions';
import CategorySearch from 'pages/CategorySearch';
import Partner from 'pages/Partner';
import PreQualifyingQuestion from 'pages/CategoryQuestions/components/PreQualifyingQuestion';
import Service from 'pages/Home/components/Service';
import WhyThrive from 'pages/Home/components/WhyThrive';
import Contact from 'pages/Contact';
import ContactQuestion from 'pages/Contact/components/ContactQuestion';
import Book from 'pages/Book';
import BookingConfirmation from 'pages/BookingConfirmation';
import { Amplify } from 'aws-amplify';
import config from 'config';
import Bookings from 'pages/Bookings';
import Category from 'pages/HomeServices/components/Category';
import ServicesPartner from 'pages/HomeServices/components/ServicesPartner';
import CommunityServices from 'pages/CommunityServices';
import CommunityService from 'pages/CommunityService';
import AboutBlok from 'components/AboutBlok';
import LocalService from 'pages/CommunityService/components/LocalService';
import LocalImageBlok from 'pages/CommunityService/components/LocalImageBlok';
import LocalButtonBlok from 'pages/CommunityService/components/LocalButtonBlok';
import Faq from 'pages/Faq';
import FaqTheme from 'pages/Faq/components/FaqTheme';
import FaqQuestion from 'pages/Faq/components/FaqQuestion';
import NoPartners from 'pages/NoPartners';
import Account from 'pages/Account';
import HowItWorks from 'pages/HowItWorks';
import HowItWorksStep from 'pages/HowItWorks/components/HowItWorksStep';
import Vetting from 'pages/Vetting';
import HowWeWork from 'pages/Vetting/components/HowWeWork';
import BecomingPartner from 'pages/BecomingPartner';
import ContactBox from 'components/ContactBox';
import Page from 'pages/Page';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Divider from 'components/Divider';
import Margin from 'components/Margin';
import Image from 'components/Image';
import ColoredBlok from 'components/ColoredBlok';
import Grid from 'components/Grid';
import Button from 'components/ButtonBlok';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HomeServices from './pages/HomeServices/index';

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_KEY,
  use: [apiPlugin],
  components: {
    home: Home,
    whyThrive: WhyThrive,
    service: Service,
    categorySearch: CategorySearch,
    categoryQuestions: CategoryQuestions,
    preQualifyingQuestion: PreQualifyingQuestion,
    noPartners: NoPartners,
    partner: Partner,
    contact: Contact,
    contactQuestion: ContactQuestion,
    book: Book,
    bookingConfirmation: BookingConfirmation,
    bookings: Bookings,
    homeServices: HomeServices,
    category: Category,
    servicesPartner: ServicesPartner,
    communityServices: CommunityServices,
    communityService: CommunityService,
    aboutBlok: AboutBlok,
    localService: LocalService,
    localImageBlok: LocalImageBlok,
    localButtonBlok: LocalButtonBlok,
    faq: Faq,
    faqTheme: FaqTheme,
    faqQuestion: FaqQuestion,
    account: Account,
    howItWorks: HowItWorks,
    howItWorksStep: HowItWorksStep,
    vetting: Vetting,
    howWeWork: HowWeWork,
    becomingPartner: BecomingPartner,
    contactBox: ContactBox,
    Page,
    flex: Flex,
    text: Text,
    divider: Divider,
    margin: Margin,
    image: Image,
    coloredBlok: ColoredBlok,
    grid: Grid,
    button: Button,
  },
});

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
