import { storyblokEditable } from '@storyblok/react';
import { useState } from 'react';
import { PreQualifyingQuestionStoryblok } from 'types/components-sb';
import RadioButton from 'components/RadioButton';
import CheckBoxButton from 'components/CheckBoxButton';

interface PreQualifyingQuestionProps {
  blok: PreQualifyingQuestionStoryblok;
  productIds?: string[];
  onChange: (id: string, question: string, value: string, isMultiple: boolean) => void;
}

const PreQualifyingQuestion = ({ blok, productIds, onChange }: PreQualifyingQuestionProps) => {
  const [changeState, setChangeState] = useState(false);

  const onAnswerChange = (id: string, value: string, isMultiple: boolean) => {
    onChange(id, blok.question ?? '', value, isMultiple);
    setChangeState(!changeState);
  };

  return (
    <div className="flex flex-col lg:flex-row mt-3" {...storyblokEditable(blok)}>
      <div className="text-darkBlue font-black lg:font-bold text-p16 md:text-p17 lg:text-p20 xl:text-p22 2xl:text-p24 lg:w-[40%]">{blok.question}</div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:ml-4 lg:w-[60%] mt-4 lg:mt-1">
        {blok.answers?.map((answer: string, index: number) => {
          const isChecked = (document.getElementById(`radio-${blok._uid}-${index}`) as HTMLInputElement)?.checked;
          if (blok.areMultiAnswersAccepted) {
            return (
              <CheckBoxButton
                id={blok._uid}
                text={answer}
                key={index}
                index={index}
                isChecked={isChecked}
                onChange={(value) => onAnswerChange(blok._uid, value, true)}
              />
            );
          } else {
            return (
              <RadioButton
                id={blok._uid}
                text={answer}
                description={blok.descriptions && blok.descriptions[index]}
                key={index}
                index={index}
                isChecked={isChecked}
                onChange={(value) => {
                  if (productIds !== undefined) {
                    onAnswerChange(productIds[index], value, false);
                  } else {
                    onAnswerChange(blok._uid, value, false);
                  }
                }}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default PreQualifyingQuestion;
