import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import ContactBox from 'components/ContactBox';
import DisplayReviews from 'components/DisplayReviews';
import PartnerSearch from 'components/PartnerSearch';
import { VettingStoryblok } from 'types/components-sb';

interface VettingProps {
  blok: VettingStoryblok;
}

const Vetting = ({ blok }: VettingProps) => {
  return (
    <div className="px-4 py-7 md:px-[5%] flex flex-col justify-center items-center">
      {blok.logo?.filename && (
        <div className="rounded-full bg-secondary w-16 lg:w-20 h-16 lg:h-20 flex justify-center items-center">
          <img src={blok.logo?.filename} alt="logo white" className="w-10 lg:w-16" />
        </div>
      )}
      <div className="text-darkBlue text-h4 md:text-h3 lg:text-h2 xl:text-h1 text-center mt-4 lg:mt-7">{blok.title}</div>
      <div className="text-darkBlue text-p24 md:text-h4 lg:text-h3 xl:text-h2 leading-display-lg text-center mt-1 lg:mt-3">{blok.subtitle}</div>
      <div className="p-6 rounded-lg shadow-descriptionBox text-darkBlue text-p16 md:text-p17 lg:text-p20 xl:text-p22 2xl:text-p24 leading-7 text-center mt-4 lg:mt-7">
        {blok.description}
      </div>
      <div className="bg-bgBlue rounded-3xl p-10 flex flex-col gap-10 w-full mt-14">
        {blok.howWeWork?.map((nestedBlok: SbBlokData, index: number) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} isReverse={index % 2 === 1} />;
        })}
      </div>
      <DisplayReviews title={blok.reviewsTitle ?? 'Our Partner Reviews'} />
      <PartnerSearch />
      <ContactBox />
    </div>
  );
};

export default Vetting;
