import { useGetAvailabilitySlots } from 'api/hooks/useGetAvailabilitySlots';
import { useEffect, useState } from 'react';
import { AvailabilitySlot } from '__generated__/graphql';
import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';
import Calendar from 'react-calendar';
import Loading from 'components/Loading';
import 'styles/Calendar.css';

interface PartnerAvailabilitiesProps {
  partnerId: string;
  productId: string;
  onAvailabilitySlotChange: (startTime: number | null, endTime: number) => void;
}

type ValuePiece = Date | null;

type CalendarDate = ValuePiece | [ValuePiece, ValuePiece];

const PartnerAvailabilities = ({ partnerId, productId, onAvailabilitySlotChange }: PartnerAvailabilitiesProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const dateToday = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
  const { availabilitySlots, loadingAvailabilitySlots } = useGetAvailabilitySlots(partnerId, productId, dateToday);
  const [availableDates, setAvailbleDates] = useState<number[]>([]);
  const [selectedTimeslotsIndex, setSelectedTimeslotsIndex] = useState<number>();

  useEffect(() => {
    if (availabilitySlots && availabilitySlots?.GetAvailabilitySlots) {
      const newAvailableDates: number[] = [];
      availabilitySlots?.GetAvailabilitySlots.forEach((slot: AvailabilitySlot) => {
        const slotDay = new Date(new Date(slot.startDateTime).setHours(0, 0, 0));
        if (!newAvailableDates.includes(slotDay.getTime()) && !slot.isBooked) {
          newAvailableDates.push(slotDay.getTime());
        }
      });
      setAvailbleDates(newAvailableDates);
    }
  }, [availabilitySlots, availabilitySlots?.GetAvailabilitySlots]);

  const onDateChange = (date: CalendarDate) => {
    setSelectedDate(date as Date);
  };

  const onTimeChange = (index: number, startTime: number) => {
    if (availabilitySlots && availabilitySlots?.GetAvailabilitySlots) {
      setSelectedTimeslotsIndex(index);
      onAvailabilitySlotChange(startTime, availabilitySlots?.GetAvailabilitySlots[index].endDateTime);
    }
  };

  if (loadingAvailabilitySlots) {
    return <Loading />;
  }

  return (
    <div className="mt-7 md:mt-20">
      <div className="flex flex-col md:flex-row items-center">
        <div className="text-darkBlue">
          <div className="text-p28 font-bold text-center md:text-left">Book appointment</div>
          <div className="text-p18 text-center md:text-left">Select your preferred date and time</div>
        </div>
        <div className="flex flex-row items-center mt-5 md:mt-0 md:ml-10">
          <div className="w-6 h-6 border border-darkBlue rounded-md" />
          <div className="text-darkBlue text-p16 ml-2">Available</div>
          <div className="w-6 h-6 bg-[#FED5D5] rounded-md ml-4" />
          <div className="text-darkBlue text-p16 ml-2">Unavailable</div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-7">
        <Calendar
          onChange={onDateChange}
          value={selectedDate}
          minDate={new Date()}
          minDetail="year"
          tileDisabled={({ date, view }) => {
            if (view === 'month' && !availableDates.includes(date.getTime())) {
              return true;
            }
            if (view === 'year' && !availableDates.find((item) => isSameMonth(item, date) && isSameYear(item, date))) {
              return true;
            }
            return false;
          }}
        />
        <div className="bg-bgBlue h-50 w-full rounded-r-lg p-3 sm:p-6 flex flex-col lg:flex-row">
          <div className="bg-bgSeparator h-px w-full lg:h-full lg:w-px" />
          {selectedDate ? (
            <div className="pt-8 lg:pt-0 lg:pl-6 grid grid-cols-3 gap-2 w-full h-fit">
              {availabilitySlots?.GetAvailabilitySlots?.map((slot: AvailabilitySlot, index: number) => {
                if (isSameDay(slot.startDateTime, selectedDate)) {
                  return (
                    <button
                      className={
                        selectedTimeslotsIndex === index
                          ? 'bg-darkBlue text-white rounded-lg text-p16 lg:text-p20 p-3 font-bold'
                          : 'bg-white rounded-lg text-p16 lg:text-p20 text-darkBlue p-3 font-bold disabled:bg-[#FED5D5] disabled:text-[#f72f2f4D]'
                      }
                      disabled={slot.isBooked}
                      onClick={() => onTimeChange(index, slot.startDateTime)}
                      key={index}
                      data-cy={`partner-availability-time-slot-${index}`}
                    >
                      {format(slot.startDateTime, 'hh:mm aaa')}
                    </button>
                  );
                }
                return null;
              })}
            </div>
          ) : (
            <div className="flex justify-center items-center text-darkBlue text-p24 text-center w-full font-bold mt-6 lg:mt-0">
              Please select an available day
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerAvailabilities;
