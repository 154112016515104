import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import { DividerStoryblok } from 'types/components-sb';

interface DividerProps {
  blok: DividerStoryblok;
}

const Divider = ({ blok }: DividerProps) => {
  return (
    <div
      className={`my-[18px] ${blok.isVertical ? 'w-[3px] h-full' : 'h-[3px] w-full'}`}
      style={{ backgroundColor: blok.color ?? '#45C4B0' }}
      {...storyblokEditable(blok)}
    />
  );
};

export default Divider;
