import { PartnerReview } from '__generated__/graphql';
import Rating from '../Rating';
import Review from './Review';

interface ReviewsProps {
  reviews: PartnerReview[];
  partnerName: string;
}

const Reviews = ({ reviews, partnerName }: ReviewsProps) => {
  const ratings: number[] = reviews.map((review) => review.rating);
  const average = ratings.reduce((a, b) => a + b, 0) / ratings.length;
  const hasNoDecimals = Number.isInteger(average);
  let isReviewPairBgBlue = false;

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div className="text-p24 md:text-p28 font-bold text-darkBlue">Reviews for {partnerName}</div>
        <div className="flex flex-col md:flex-row items-start mt-3 md:mt-0 md:items-center">
          <div className="text-p18 text-darkBlue mr-2 mt-1 mb-5 md:mb-0">
            <span className="font-bold" data-testid="partner-rating">
              {average} out of 5
            </span>{' '}
            based on{' '}
            <span className="font-bold" data-testid="partner-number-reviews">
              {ratings.length} reviews
            </span>
          </div>
          <Rating reviewsRating={average} hasNoDecimals={hasNoDecimals} />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-7">
        {reviews.map((review: PartnerReview, index: number) => {
          let isReviewBgBlue = false;
          if (index % 2 === 0) {
            isReviewPairBgBlue = !isReviewPairBgBlue;
          }
          if (isReviewPairBgBlue && index % 2 === 0) {
            isReviewBgBlue = true;
          } else if (!isReviewPairBgBlue && index % 2 === 1) {
            isReviewBgBlue = true;
          }
          return <Review review={review} isReviewBgBlue={isReviewBgBlue} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Reviews;
