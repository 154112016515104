import { useQuery, gql } from '@apollo/client';

export const GET_PARTNER = gql`
  query GetPartner($args: PartnerSearch) {
    GetPartner(args: $args) {
      id
      prices {
        basePrice
        duration
        firstBookingPrice
        id
        memberPrice
        partnerId
        productId
      }
      firstName
      businessName
      description
      qualifications
      dbsChecked
      vetted
      referencesReceived
      qualificationsChecked
      isActive
      image {
        id
        url
      }
    }
  }
`;

const useGetPartner = (id: string, productId: string, skip: boolean) => {
  const {
    loading: loadingPartner,
    data: partner,
    error: errorPartner,
    refetch,
  } = useQuery(GET_PARTNER, {
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      args: {
        id,
        productId,
      },
    },
    skip,
  });

  return {
    loadingPartner,
    partner,
    errorPartner,
    refetch,
  };
};

export { useGetPartner };
