import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import React from 'react';
import { ImageStoryblok } from 'types/components-sb';

interface ImageProps {
  blok: ImageStoryblok;
}

const Image = ({ blok }: ImageProps) => {
  return (
    <>
      <div
        className={`${blok.noMarginMobile ? '-mx-6 ' : ''} ${blok.noMarginDesktop ? 'lg:-mx-[72px]' : 'lg:mx-0'} ${
          blok.marginVertical && blok.noMarginMobile ? 'my-0 lg:my-5' : blok.marginVertical ? 'my-5' : ''
        } relative ${blok.sizeMobile} lg:w-auto`}
        {...storyblokEditable(blok)}
      >
        <img
          src={blok.image?.filename}
          alt={blok.image?.alt}
          className={`justify-self-center ${blok.center ? 'm-auto' : ''} ${blok.widthFull ? 'w-full' : ''}`}
        />
        {blok.contentInsideImage && (
          <div
            style={{
              left: typeof window !== 'undefined' && window.innerWidth > 1024 ? `${blok.leftPosition}px` : `${parseInt(blok.leftPosition ?? '', 10) / 2}px`,
              top: typeof window !== 'undefined' && window.innerWidth > 1024 ? `${blok.topPosition}px` : `${parseInt(blok.topPosition ?? '', 10) / 2}px`,
              right: typeof window !== 'undefined' && window.innerWidth > 1024 ? `${blok.rightPosition}px` : `${parseInt(blok.rightPosition ?? '', 10) / 2}px`,
              bottom:
                typeof window !== 'undefined' && window.innerWidth > 1024 ? `${blok.bottomPosition}px` : `${parseInt(blok.bottomPosition ?? '', 10) / 2}px`,
            }}
            className={`absolute ${blok.contentInsideImageMobile ? '' : 'hidden lg:block'}`}
          >
            {blok.content?.map((nestedBlok) => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}
          </div>
        )}
        {blok.caption && <div className="text-center text-gray text-xxs mt-3">{blok.caption}</div>}
      </div>
      {blok.contentInsideImage && (
        <div className="lg:hidden">{blok.content?.map((nestedBlok) => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}</div>
      )}
    </>
  );
};

export default Image;
