/* eslint-disable react/no-danger */
import { renderRichText } from '@storyblok/react';
import React from 'react';
import { LocalServiceStoryblok } from 'types/components-sb';

interface LocalServiceProps {
  blok: LocalServiceStoryblok;
  isBlue: boolean;
}

const LocalService = ({ blok, isBlue }: LocalServiceProps) => {
  return (
    <div className={isBlue ? 'bg-bgBlue p-7 rounded-xl' : 'bg-bgYellow15 p-7 rounded-xl'}>
      <div className="text-p24 text-darkBlue font-bold">{blok.name}</div>
      <div className="text-p22 text-darkBlue mt-6" dangerouslySetInnerHTML={{ __html: renderRichText(blok.description) }} />
      <div className="text-p20 text-darkBlue font-bold mt-6">Address: {blok.address}</div>
      {blok.email && blok.email !== '' && (
        <div className="mt-6">
          <a href={`mailto:${blok.email}`} className="text-p20 text-darkBlue font-bold underline">
            {blok.email}
          </a>
        </div>
      )}
      {blok.phone && blok.phone !== '' && (
        <div className="mt-6">
          <a href={`tel:${blok.phone}`} className="text-p20 text-darkBlue font-bold underline">
            {blok.phone}
          </a>
        </div>
      )}
      <div className="text-p20 text-darkBlue italic mt-6">Service Category: {blok.serviceCategory}</div>
      {blok.link && blok.link?.url !== '' && (
        <a
          href={blok.link.cached_url}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-darkBlue text-white font-bold text-p22 block sm:w-fit w-full flex justify-center items-center px-4 py-3.5 rounded-xl mt-10"
        >
          Find out more
        </a>
      )}
    </div>
  );
};

export default LocalService;
