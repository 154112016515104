import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { PageStoryblok } from 'types/components-sb';

interface PageProps {
  blok: PageStoryblok;
}

const Page = ({ blok }: PageProps) => {
  console.log(blok);
  return <div {...storyblokEditable(blok)}>{blok.content?.map((nestedBlok) => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}</div>;
};

export default Page;
