import { EyeSlashIcon, EyeIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { ToastStatus } from 'types/enum';
import { hasPasswordCapitalLetter, hasPasswordNumber, hasPasswordAtLeast8Chars, displayToast } from 'utils/utils';

interface ChangePasswordProps {
  changePasswordText: string;
}

const ChangePassword = ({ changePasswordText }: ChangePasswordProps) => {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

  const updatePassword = async () => {
    if (!/[A-Z]/.test(password) || !/\d/.test(password) || password.length < 8) {
      displayToast(ToastStatus.ERROR, 'The password must have a capital letter, a number and be at least 8 characters.');
      return;
    }
    if (password !== passwordConfirmation) {
      displayToast(ToastStatus.ERROR, 'The confirmation password is different than the password.');
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, password);
      displayToast(ToastStatus.SUCCESS, 'Your password has been changed successfully. Next time you sign in, use the new password.');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayToast(ToastStatus.ERROR, error.message);
    }
  };

  function toggleOldPasswordVisibility() {
    setIsOldPasswordVisible(!isOldPasswordVisible);
  }

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  function togglePasswordConfirmationVisibility() {
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
  }

  return (
    <div>
      <div className="text-p28 lg:text-h2 text-darkBlue font-bold">Password</div>
      <div className="text-p18 md:text-p20 text-darkBlue mt-3">{changePasswordText}</div>
      <div className="flex flex-col lg:flex-row mt-7">
        <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 md:mb-0">Your current password</div>
        <div className="relative w-full lg:w-[60%] lg:ml-10">
          <input
            type={isOldPasswordVisible ? 'text' : 'password'}
            value={oldPassword}
            onChange={(event) => setOldPassword(event.target.value)}
            className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border focus-visible:outline-primary h-fit w-full"
          />
          <button className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue" onClick={toggleOldPasswordVisibility}>
            {isOldPasswordVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
          </button>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-7">
        <div className="w-full lg:w-[40%] mb-4 md:mb-0">
          <div className="text-p20 md:text-p24 text-darkBlue font-bold">Your new password</div>
        </div>
        <div className="w-full lg:w-[60%] lg:ml-10">
          <div className="relative">
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border w-full focus-visible:outline-primary h-fit"
            />
            <button className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue" onClick={togglePasswordVisibility}>
              {isPasswordVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
            </button>
          </div>
          <div className="flex flex-col lg:flex-row items-start text-darkBlue text-p18 font-bold mt-6">
            <div className="font-normal lg:font-bold">Your password must contain the following:</div>
            <div className="mt-3 lg:mt-0">
              <div className="flex flex-row items-center lg:ml-2">
                <CheckCircleIcon className={hasPasswordCapitalLetter(password) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                <div className="ml-1 pt-0.5">At least one capital letter</div>
              </div>
              <div className="flex flex-row items-center lg:ml-2">
                <CheckCircleIcon className={hasPasswordNumber(password) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                <div className="ml-1 pt-0.5">At least one number</div>
              </div>
              <div className="flex flex-row items-center lg:ml-2">
                <CheckCircleIcon className={hasPasswordAtLeast8Chars(password) ? 'w-6 h-6 text-green' : 'w-6 h-6 text-darkGray opacity-30'} />
                <div className="ml-1 pt-0.5">At least 8 characters</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-7">
        <div className="text-p20 md:text-p24 text-darkBlue font-bold w-full lg:w-[40%] mb-4 md:mb-0">Confirm your new password</div>
        <div className="relative w-full lg:w-[60%] lg:ml-10">
          <input
            type={isPasswordConfirmationVisible ? 'text' : 'password'}
            value={passwordConfirmation}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
            className="bg-white px-5 py-3.5 pr-14 rounded-lg text-textBlue placeholder:text-textBlue text-p20 border-darkBlue border focus-visible:outline-primary h-fit w-full"
          />
          <button className="absolute inset-y-0 right-0 flex items-center px-4 text-darkBlue" onClick={togglePasswordConfirmationVisibility}>
            {isPasswordConfirmationVisible ? <EyeSlashIcon className="w-7 h-7" /> : <EyeIcon className="w-7 h-7" />}
          </button>
        </div>
      </div>
      <div className="flex justify-end mt-7 ">
        <button className="bg-darkBlue px-6 py-2.5 rounded-xl w-full sm:w-fit cursor-pointer text-white text-p20 font-bold" onClick={updatePassword}>
          Change password
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
