/* eslint-disable react/no-unstable-nested-components */
import { MARK_LINK, render } from 'storyblok-rich-text-react-renderer';
import { StoryblokComponent } from '@storyblok/react';
import { RichtextStoryblok } from 'types/components-sb';

interface RichTextProps {
  text: RichtextStoryblok;
}

const RichText = ({ text }: RichTextProps) => {
  return (
    <div>
      {render(text, {
        defaultBlokResolver: (name, props) => {
          const blok = { ...props, component: name };
          return <StoryblokComponent blok={blok} key={props._uid} />;
        },
        markResolvers: {
          [MARK_LINK]: (children, props) => {
            const { linktype, href } = props;
            if (linktype === 'email') {
              return (
                <a href={`mailto:${href}`} className="underline">
                  {children}
                </a>
              );
            }
            return (
              <a href={href} target="_blank" rel="noopener noreferrer" className="underline">
                {children}
              </a>
            );
          },
        },
      })}
    </div>
  );
};

export default RichText;
