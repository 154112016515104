import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import { TextStoryblok } from 'types/components-sb';
import RichText from '../RichText';

interface TextProps {
  blok: TextStoryblok;
  margin?: boolean;
}

const Text = ({ blok, margin = true }: TextProps) => {
  if (!blok.text) {
    return null;
  }
  return (
    <div
      {...storyblokEditable(blok)}
      className={`${margin && !blok.noMargin ? 'my-10' : ''} ${blok.center ? 'sm:text-center' : ''} ${blok.centerMobile ? 'text-center' : ''}`}
    >
      <RichText text={blok.text} />
    </div>
  );
};

export default Text;
