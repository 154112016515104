import { storyblokEditable } from '@storyblok/react';
import { ServiceStoryblok } from 'types/components-sb';
import { isEven } from 'utils/utils';

interface ServiceProps {
  blok: ServiceStoryblok;
  index: number;
}

const Service = ({ blok, index }: ServiceProps) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={`${
        window.innerWidth > 1280 ? (blok.isYellow ? 'bg-bgYellow' : 'bg-bgBlue') : isEven(index) ? 'bg-bgYellow' : 'bg-bgBlue'
      } rounded-2xl p-6 xl:px-10 xl:py-12 flex flex-col justify-between items-center xl:items-start`}
    >
      <div
        className={`${
          window.innerWidth > 1280 ? (blok.isYellow ? 'bg-secondary' : 'bg-primary') : isEven(index) ? 'bg-secondary' : 'bg-primary'
        } rounded-full p-2 w-[80px] h-[80px] flex items-center justify-center`}
      >
        <img src={blok.icon.filename} alt="yoga" className="" />
      </div>
      <div className="mt-4 text-p22 sm:text-p24 lg:text-p28 xl:text-h3 text-darkBlue font-bold leading-7 xl:leading-10 text-center xl:text-left">
        {blok.title}
      </div>
      <div className="text-16 md:text-p18 xl:text-p20 xl:text-left text-center text-darkBlue mt-4 mb-8">{blok.subtitle}</div>
      <a
        href={
          blok.button[0]?.link?.linktype === 'url'
            ? blok.button[0]?.link?.cached_url
            : `/${blok.button[0]?.link?.cached_url === 'home' ? '' : blok.button[0]?.link?.cached_url}`
        }
        className="bg-darkBlue px-3 py-2 rounded-xl text-white text-p20 text-center font-bold xl:font-semibold w-full"
      >
        {window.innerWidth > 1280 ? blok.button[0].text : 'Find out more'}
      </a>
    </div>
  );
};

export default Service;
