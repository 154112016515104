import { gql, useMutation } from '@apollo/client';
import { GET_CUSTOMER } from './useGetCustomer';

export const EDIT_CUSTOMER = gql`
  mutation EditCustomer($input: EditCustomerInput) {
    EditCustomer(input: $input) {
      message
      date
    }
  }
`;

const useEditCustomer = (id: string) => {
  const jwt = localStorage.getItem('token');
  const [editCustomer, mutationEditCustomer] = useMutation(EDIT_CUSTOMER, {
    context: {
      headers: {
        Authorization: jwt || null,
      },
    },
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        context: {
          headers: {
            Authorization: jwt,
          },
        },
        variables: {
          args: {
            id,
          },
        },
      },
    ],
  });

  return { editCustomer, mutationEditCustomer };
};

export { useEditCustomer };
