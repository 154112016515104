import { CheckIcon } from '@heroicons/react/24/outline';

interface CheckBoxButtonProps {
  id: string;
  text: string;
  description?: string;
  index: number;
  isChecked: boolean;
  onChange: (value: string) => void;
}

const CheckBoxButton = ({ id, text, description, index, isChecked, onChange }: CheckBoxButtonProps) => {
  return (
    <div>
      <input
        type="checkbox"
        data-testid={`radio-${id}-${index}`}
        id={`radio-${id}-${index}`}
        name={id}
        className="hidden peer"
        required
        onChange={() => onChange(text)}
      />
      <label
        htmlFor={`radio-${id}-${index}`}
        className="h-full inline-flex items-center w-full p-5 text-darkBlue bg-white border border-darkBlue rounded-lg cursor-pointer peer-checked:bg-darkBlue peer-checked:text-white"
      >
        <div className="w-8 h-8 rounded-md border-darkBlue border bg-white flex items-center justify-center">
          {isChecked && <CheckIcon className="text-darkBlue h-5 w-5" data-testid={`radio-${id}-${index}-checked`} />}
        </div>
        <div className="block ml-5">
          <div className="w-full text-p16 sm:text-p17 lg:text-p20 font-black lg:font-bold">{text}</div>
          <div className="w-full text-p16 sm:text-p17 lg:text-p18">{description}</div>
        </div>
      </label>
    </div>
  );
};

export default CheckBoxButton;
