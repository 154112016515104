import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import Phone from 'components/Phone';
import { HomeStoryblok } from 'types/components-sb';
import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import { useGetCustomer } from 'api/hooks/useGetCustomer';
import { Auth, Hub } from 'aws-amplify';
import HomeSearchThrive from './components/HomeSearchThrive';

interface HomeProps {
  blok: HomeStoryblok;
}

const Home = ({ blok }: HomeProps) => {
  const { customer, refetch } = useGetCustomer(localStorage.getItem('userId') ?? '', !localStorage.getItem('userId'));
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        if (user && user.username) {
          const session = await Auth.currentSession();
          const accessToken = session.getIdToken();
          const token = accessToken.getJwtToken();
          localStorage.setItem('token', token);
          refetch();
        }
      } catch {}
    };
    Hub.listen('auth', checkUserAuth);
    checkUserAuth();
    return () => Hub.remove('auth', checkUserAuth);
  }, [refetch]);

  return (
    <div {...storyblokEditable(blok)}>
      <div className={imageLoading ? 'block' : 'hidden'}>
        <Loading />
      </div>
      <div className={imageLoading ? 'hidden' : 'block'}>
        <div className="xl:flex items-center justify-between">
          <div className="flex justify-center xl:hidden z-0">
            <img src="/assets/yoga_mobile.png" alt="yoga" onLoad={() => setImageLoading(false)} />
          </div>
          <div className="mx-4 lg:mx-20 xl:mr-0 z-10">
            <h1 id="home-title" className="text-textBlue xl:w-[120%] 2xl:text-h1 lg:text-h2 md:text-h3 text-p28 text-center md:text-left">
              {blok.title}
            </h1>
            <HomeSearchThrive customer={customer} outsideServices={blok.outsideServices} />
            <div className="text-p20 text-textBlue 2xl:mt-5 xl:mt-1 mt-2 text-center md:text-left">{blok.subtitle1}</div>
            <div className="text-p20 text-textBlue font-bold text-center md:text-left">{blok.subtitle2}</div>
          </div>
          <div className="mb-auto hidden xl:block">
            <img src="/assets/yoga.png" alt="yoga" onLoad={() => setImageLoading(false)} />
          </div>
        </div>
        <div className="bg-secondary py-2 px-10 xl:px-20 flex flex-col xl:flex-row justify-between items-center mt-10 xl:mt-0">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="text-p18 sm:text-p20 md:text-p24 xl:text-p28 text-textBlue font-bold">{blok.contactBannerTitle}</div>
            <div className="text-p16 sm:text-p20 md:text-p24 xl:text-p28 text-textBlue ml-3">{blok.contactBannerSubtitle}</div>
          </div>
          <Phone />
        </div>
        <h2 className="text-h4 md:text-h3 xl:text-h2 text-darkBlue leading-7 xl:leading-10 mt-[120px] mx-4 lg:mx-20 text-center xl:text-left">
          {blok.whyThriveTitle}
        </h2>
        <div className="grid xl:grid-cols-3 grid-cols-1 gap-8 mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20  mt-12 mb-10">
          {blok?.whyThriveList?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
          })}
        </div>
        <h2 className="text-h4 md:text-h3 xl:text-h2 text-darkBlue leading-7 xl:leading-10 mt-20 mx-4 lg:mx-20 text-center xl:text-left">
          {blok.ourServicesTitle}
        </h2>
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-8 mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20  mt-12 mb-10">
          {blok?.ourServicesList?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
          })}
        </div>
        <div className="grid grid-cols-1 gap-8">
          {blok?.about?.map((nestedBlok: SbBlokData, index: number) => {
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
