import { Booking } from '__generated__/graphql';
import { useGetBookings } from 'api/hooks/useGetBookings';
import { Auth, Hub } from 'aws-amplify';
import Loading from 'components/Loading';
import Login from 'components/SignIn';
import { useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { BookingsStoryblok } from 'types/components-sb';
import BookingsList from './components/BookingsList';

interface BookingsProps {
  blok: BookingsStoryblok;
}

const Bookings = ({ blok }: BookingsProps) => {
  const { bookings: bookingsData, loadingBookings, refetch } = useGetBookings(localStorage.getItem('userId') ?? '', !localStorage.getItem('userId'));
  const [pastBookings, setPastBookings] = useState<Booking[]>([]);
  const [upcomingBookings, setUpcomingBookings] = useState<Booking[]>([]);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const session = await Auth.currentSession();
        const accessToken = session.getIdToken();
        const jwt = accessToken.getJwtToken();
        localStorage.setItem('token', jwt);
        if (user && user.username) {
          refetch(user.username);
          setIsLoggedIn(true);
        }
      } catch {
        setIsLoggedIn(false);
        setShowLogin(true);
      }
    };
    Hub.listen('auth', checkUserAuth);
    checkUserAuth();
    return () => Hub.remove('auth', checkUserAuth);
  }, [refetch]);

  useEffect(() => {
    if (bookingsData && bookingsData.GetBookings) {
      const newPastBookings: Booking[] = [];
      const newUpcomingBookings: Booking[] = [];
      bookingsData.GetBookings.forEach((booking: Booking) => {
        if (isBefore(booking.startDateTime, Date.now())) {
          newPastBookings.push(booking);
        } else {
          newUpcomingBookings.push(booking);
        }
      });
      newPastBookings.sort((a, b) => b.startDateTime - a.startDateTime);
      newUpcomingBookings.sort((a, b) => b.startDateTime - a.startDateTime);
      setPastBookings(newPastBookings);
      setUpcomingBookings(newUpcomingBookings);
    }
  }, [bookingsData]);

  if (loadingBookings) {
    return <Loading />;
  }

  return (
    <div>
      {isLoggedIn ? (
        <BookingsList pastBookings={pastBookings} upcomingBookings={upcomingBookings} blok={blok} />
      ) : (
        <div className="flex justify-center items-center h-[100vh] w-full text-darkBlue font-bold text-p28 text-center">Please login to access this page</div>
      )}
      {showLogin && <Login onClose={() => setShowLogin(false)} />}
    </div>
  );
};

export default Bookings;
