import { useEffect, useState } from 'react';
import SearchHeader from 'components/SearchHeader';
import { useGetPartnersByProximity } from 'api/hooks/useGetPartnersByProximity';
import Loading from 'components/Loading';
import { useGetPartner } from 'api/hooks/useGetPartner';
import { useGetPartnerReviews } from 'api/hooks/useGetPartnerReviews';
import Button from 'components/Button';
import { ButtonVariantState } from 'types/enum';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import Reviews from 'components/Reviews';
import ContactBox from 'components/ContactBox';
import { PartnerReview } from '__generated__/graphql';
import { Auth, Hub } from 'aws-amplify';
import { useGetBookings } from 'api/hooks/useGetBookings';
import PartnerInformations from './components/PartnerInformations';
import PartnerAvailabilities from './components/PartnerAvailabilities';

const Partner = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const categoryId = localStorage.getItem('categoryId');
  const postcode = localStorage.getItem('postcode') ?? '';
  const category = localStorage.getItem('category') ?? '';
  const productId = urlParams.get('product') ?? '';
  const partnerId = urlParams.get('id') ?? '';
  const skipPartner = !urlParams.get('id');
  const [reviewsRating, setReviewsRating] = useState(0);
  const [selectedAvailabilitySlotStartTime, setSelectedAvailabilitySlotStartTime] = useState<number | null>(null);
  const [selectedAvailabilitySlotEndTime, setSelectedAvailabilitySlotEndTime] = useState<number | null>(null);
  const { bookings, loadingBookings, refetch } = useGetBookings(localStorage.getItem('userId') ?? '', !localStorage.getItem('userId'));
  const [isFirstBooking, setIsFirstBooking] = useState(true);
  const { partnersByProximity, loadingPartnersByProximity } = useGetPartnersByProximity(postcode, productId);
  const { partner, loadingPartner } = useGetPartner(partnerId, productId, skipPartner);
  const { partnerReviews, loadingPartnerReviews } = useGetPartnerReviews(partnerId, skipPartner);

  const searchIndex = parseInt(localStorage.getItem('searchIndex') ?? '0', 10);

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const session = await Auth.currentSession();
        const accessToken = session.getIdToken();
        const jwt = accessToken.getJwtToken();
        localStorage.setItem('token', jwt);
        if (authUser && authUser.username) {
          refetch(authUser.username);
        }
      } catch {
        setIsFirstBooking(true);
      }
    };
    Hub.listen('auth', checkUserAuth);
    checkUserAuth();
    return () => Hub.remove('auth', checkUserAuth);
  }, [refetch]);

  useEffect(() => {
    if (bookings && bookings.GetBookings && bookings.GetBookings.length > 0) {
      setIsFirstBooking(false);
    }
  }, [bookings]);

  useEffect(() => {
    if (!categoryId || !category || productId === '' || !postcode) {
      window.location.href = '/';
    }
  }, [category, categoryId, postcode, productId]);

  useEffect(() => {
    if (
      partnersByProximity &&
      partnersByProximity.GetPartnersByProximity &&
      skipPartner &&
      productId &&
      partnersByProximity.GetPartnersByProximity.length > 0
    ) {
      localStorage.setItem('searchIndex', '0');
      window.location.href = `/partner?product=${productId}&id=${partnersByProximity.GetPartnersByProximity[0]}`;
    }
  }, [partnersByProximity, productId, skipPartner]);

  useEffect(() => {
    if (partnerReviews && partnerReviews.GetPartnerReviews && !skipPartner) {
      const ratings: number[] = partnerReviews.GetPartnerReviews.map((review: PartnerReview) => review.rating);
      const average = ratings.reduce((a, b) => a + b, 0) / ratings.length;
      setReviewsRating(average);
    }
  }, [partnerReviews, skipPartner]);

  useEffect(() => {
    if (partnersByProximity && partnersByProximity.GetPartnersByProximity && partnersByProximity.GetPartnersByProximity.length === 0) {
      window.location.href = '/no-partners';
    }
  }, [partnersByProximity]);

  const onPartnerChange = (index: number) => {
    localStorage.setItem('searchIndex', index.toString());
    window.location.href = `/partner?product=${productId}&id=${partnersByProximity.GetPartnersByProximity[index]}`;
  };

  const onBookClick = () => {
    localStorage.setItem('partner', JSON.stringify(partner.GetPartner));
    localStorage.setItem('availabilitySlotStartDateTime', selectedAvailabilitySlotStartTime?.toString() ?? '0');
    localStorage.setItem('availabilitySlotEndDateTime', selectedAvailabilitySlotEndTime?.toString() ?? '0');
    localStorage.removeItem('searchIndex');
    window.location.href = `/questions/${category.toLowerCase().replace(' ', '-')}`;
  };

  if (loadingPartnersByProximity || loadingPartner || loadingPartnerReviews || loadingBookings) {
    return <Loading />;
  }

  return (
    <div>
      <SearchHeader
        text="Your partner match"
        partnersNumber={partnersByProximity?.GetPartnersByProximity?.length ?? 0}
        index={searchIndex}
        onIndexChange={onPartnerChange}
      />
      {partner && partner.GetPartner && (
        <div data-cy="partner-result-container">
          <PartnerInformations
            partner={partner.GetPartner}
            reviewsRating={reviewsRating}
            reviewsNumber={partnerReviews.GetPartnerReviews.length}
            isFirstBooking={isFirstBooking}
          />
          <div className="px-4 md:px-[5%] lg:px-[10%] xl:px-[15%]">
            <PartnerAvailabilities
              partnerId={partner.GetPartner.id}
              productId={productId}
              onAvailabilitySlotChange={(startTime, endTime) => {
                setSelectedAvailabilitySlotStartTime(startTime);
                setSelectedAvailabilitySlotEndTime(endTime);
              }}
            />
            <div className="flex justify-center mt-10">
              <button
                onClick={onBookClick}
                className="bg-secondary px-6 py-3.5 rounded-xl w-full sm:w-fit disabled:opacity-40"
                disabled={selectedAvailabilitySlotStartTime === null}
                data-cy="partner-book-appointement-button"
              >
                <div className="text-p20 md:text-p24 font-bold text-darkBlue">Book appointment</div>
              </button>
            </div>
            {selectedAvailabilitySlotStartTime === null && <div className="text-error text-p18 font-bold text-center mt-6">Please select a time slot</div>}

            <div className="h-px w-full bg-darkBlue my-12" />
            <div className="flex flex-col justify-center items-center">
              <div className="text-p20 md:text-p24 lg:text-p28 xl:text-h4 font-bold text-darkBlue mb-8 text-center">
                Not seeing an appointment slot you need?{' '}
              </div>
              <Button
                variant={ButtonVariantState.Contained}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                <div className="flex items-center text-white font-bold text-p20 md:text-p22 lg:text-p24">
                  <MagnifyingGlassIcon className="h-5 w-5 mt-0.5" />
                  <div className="ml-2">Find a different supplier</div>
                </div>
              </Button>
              <div className="text-darkBlue text-p20 font-bold mt-6">or</div>
              <a
                href="/contact"
                className="text-darkBlue text-p20 md:text-p22 lg:text-p24 font-bold underline underline-offset-2 mt-6 text-center"
                id="partner-reviews"
              >
                Contact us directly to book an appointment
              </a>
            </div>
            <div className="h-px w-full bg-darkBlue my-12" />
            {partnerReviews && partnerReviews.GetPartnerReviews && partnerReviews.GetPartnerReviews.length > 0 && (
              <Reviews reviews={partnerReviews.GetPartnerReviews} partnerName={partner.GetPartner.firstName} />
            )}
            <ContactBox />
          </div>
        </div>
      )}
    </div>
  );
};

export default Partner;
