import { UserCircleIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';

interface AvatarProps {
  image: string;
}

const Avatar = ({ image }: AvatarProps) => {
  const [imgSrc, setImgSrc] = useState(image);
  return (
    <div>
      {image && image !== '' ? (
        <img src={imgSrc} alt="partner avatar" className="rounded-full w-full" onError={() => setImgSrc('/assets/defaultProfile.jpg')} />
      ) : (
        <UserCircleIcon className="rounded-full w-full text-darkBlue" />
      )}
    </div>
  );
};

export default Avatar;
