import { Star } from './Star';

interface RatingProps {
  reviewsRating: number;
  hasNoDecimals: boolean;
  small?: boolean;
}

const Rating = ({ reviewsRating, hasNoDecimals, small = false }: RatingProps) => {
  return (
    <div className="flex flex-row items-end">
      {[...Array(5)].map((_, index: number) => {
        let variant: 'filled' | 'empty' | 'custom' = 'empty';
        let percentFilled = 0;

        if (index < reviewsRating && !hasNoDecimals) {
          variant = 'custom';
          percentFilled = reviewsRating % 1;
        }

        if (index + 1 <= reviewsRating) {
          variant = 'filled';
        }

        return (
          <div className="text-green" key={index}>
            <Star variant={variant} percentFilled={Math.round(percentFilled * 100)} small={small} />
          </div>
        );
      })}
    </div>
  );
};

export default Rating;
