import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { FlexStoryblok } from 'types/components-sb';
import { getFlexSize, getGridGap } from 'utils/utils';

interface FlexProps {
  blok: FlexStoryblok;
}

const Flex = ({ blok }: FlexProps) => {
  return (
    <div
      className={`flex ${blok.rowInMobile ? 'flex-row' : 'flex-col'} ${blok.justifyCenter ? 'justify-center' : ''} ${getGridGap(
        parseInt(blok.gap ?? '5', 10),
      )} ${blok.noVerticalMarginMobile ? 'my-0' : 'my-5'} ${blok.noVerticalMarginDesktop ? 'lg:my-0' : 'lg:my-10'}  ${
        blok.itemsStart ? 'items-start' : 'items-center'
      } ${blok.reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} ${blok.wrap ? 'flex-wrap' : ''}`}
      {...storyblokEditable(blok)}
    >
      {blok.content?.map((nestedBlok, index) => {
        if (blok.size) {
          return (
            <div className={`${blok.size && getFlexSize(parseInt(blok.size ?? '3', 10))}`} key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />
            </div>
          );
        } else {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />;
        }
      })}
    </div>
  );
};

export default Flex;
