import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import React from 'react';
import { MarginStoryblok } from 'types/components-sb';

interface MarginProps {
  blok: MarginStoryblok;
}

const Margin = ({ blok }: MarginProps) => {
  return (
    <div className={`${blok.x} ${blok.y} ${blok.center ? 'flex justify-center mx-auto' : ''}`} {...storyblokEditable(blok)}>
      {blok.content?.map((nestedBlok, index) => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} index={index} />)}
    </div>
  );
};

export default Margin;
