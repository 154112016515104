import { useQuery, gql } from '@apollo/client';

export const GET_CATEGORIES_BY_POSTCODE = gql`
  query GetCategoriesByPostcode($args: CategoryByPostcodeSearch) {
    GetCategoriesByPostcode(args: $args) {
      id
      name
      storyblokSlug
    }
  }
`;

const useGetCategoriesByPostcode = (postcode?: string) => {
  const {
    loading: loadingCategories,
    data: categories,
    error: errorCategories,
    refetch,
  } = useQuery(GET_CATEGORIES_BY_POSTCODE, {
    skip: !postcode,
    context: {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
    variables: {
      args: {
        postcode,
      },
    },
  });

  return {
    loadingCategories,
    categories: categories?.GetCategoriesByPostcode || [],
    errorCategories,
    refetch,
  };
};

export { useGetCategoriesByPostcode };
