import { useId } from 'react';

interface StarProps {
  variant: 'filled' | 'empty' | 'custom';
  percentFilled: number;
  small: boolean;
}

export const Star = ({ variant, percentFilled, small }: StarProps) => {
  const id = useId();
  let c1, c2;

  if (variant === 'filled') {
    c1 = '#2DAF17';
    c2 = '#2DAF17';
  } else if (variant === 'empty') {
    c1 = '#FFF';
    c2 = '#FFF';
  } else if (variant === 'custom') {
    c1 = '#2DAF17';
    c2 = '#FFF';
  }

  return (
    <svg className={small ? 'w-6 h-6' : 'w-10 h-10'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor">
      <defs>
        <linearGradient id={id}>
          <stop offset={`${percentFilled}%`} stopColor={c1} />
          <stop offset={`${percentFilled}%`} stopColor={c2} />
        </linearGradient>
      </defs>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
